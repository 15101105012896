import { Button_Types } from '../../Configuration/Enums/Button_Types';

// Styles
import './Buttons.css'

function GeneralButton({ onClick, showIcon, image, showText, text, showSmall, disabled, Type,className }) {
    return (
        <button
            className={`${showSmall ? `get-started-btn-small ${className}` : `get-started-btn ${className}`} ${disabled ? 'Disabled_Button' : ''} ${Type === Button_Types.Destructive_Action ? 'Dangerous_Button' : ''} ${Type === Button_Types.Approved ? 'Approved' : ''} ${Type === Button_Types.Removed ? 'Removed' : ''}`}
            onClick={onClick}
            disabled={disabled}

        >
            {showText && text}
            {showIcon && <img src={image} className='gen-image' />}
        </button>
    )
}

export default GeneralButton;