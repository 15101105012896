import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logout_User from "../../Utilities/Logout";
import { Casino_APIs } from "../../API/Casino_APIs";
import { decryptData } from "../../Utilities/DecryptData";

// Context
import { User_Context } from "../../Contexts/User";

// Components
import Loader from "../../components/Loader/Loader";
import QTechGameCard from "../../components/QTechGameCard/QTechGameCard";
import SearchBox from "../../components/SearchBox/SearchBox";

// Styles
import "./IndianGames.css";
import GameIndianCard from "../../components/GameIndianCard/GameIndianCard";
import GamesHeaderMarker from "../../components/GamesHeaderMarker/GamesHeaderMarker";

function IndianGames() {
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchGames, setSearchGames] = useState("");
    const [gameList, setGameList] = useState([]);

    const getGames = async () => {
        setLoading(true);
        try {
            const response = await Casino_APIs.Qtech_Get_Indian_Games({}, User.token);
            const { data, success } = response.data ? response.data : {};
            if (success) {
                const finalDataList = decryptData(data);
                setGameList(finalDataList.payload);
            }
        } catch (error) {
            setLoading(false);
            setGameList([]);
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getGames();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className="indian-games-section">
                <GamesHeaderMarker markerText={"Indian Games"} showBtn={false} />
                <div className="casino-container">
                    <div className="indian-casino-inner-container">
                        <div>
                            {!loading && gameList.length ? (
                                <GameIndianCard gameList={gameList} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndianGames;
