import React, { useContext, useState } from 'react';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from '../../Contexts/Language';
import { User_Context } from '../../Contexts/User';
import OtpInput from "otp-input-react";
import "./Modals.css"

// Components
import GeneralButton from '../Buttons/GeneralButton';
import GeneralTextfield from '../Textfields/GeneralTextfield';

function KYCForm({ name, value, onChange, otpVerification, onClick, remainingMinutes, remainingSeconds, closeButton, hideForm, click, upiId, errorId, helperText }) {
    const { User, Set_User, Previous_User } = useContext(User_Context);
    const { Language, Set_Language } = useContext(Language_Context)
    return (
        <div className="popup d-flex align-items-center justify-content-center">
            <div className="popup-content1">
                <div className="labelotp1">
                    <span className="enterotp">{Translation_Table.EnterYourOTP[Language]}</span>
                    {closeButton && <span onClick={hideForm} className="closemodal">X</span>}
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="username-input kycinput mt-1 d-flex justify-content-center">
                            <p>{name}</p>
                        </div>

                    </div>
                </div>

                <div className="container">
                    <OtpInput
                        onChange={onChange}
                        value={value}
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        autoFocus
                        className="opt-container otpinput "
                    ></OtpInput>
                    <div className='upi-id'>
                        <GeneralTextfield type={"text"} label={"Enter UPI Id"} value={upiId}
                            onChange={click} error={errorId} helperText={helperText}
                        />
                    </div>
                    {otpVerification ? (
                        <div></div>
                    ) : (
                        <div className="valid">
                            <span className="spnvalid">
                                Enter a valid OTP
                            </span>
                        </div>
                    )}
                    <div className="otpverify">
                        <GeneralButton
                            showText={true}
                            text={'Verify OTP'}
                            onClick={onClick}
                        />
                    </div>
                    <div className="expires">
                        <span className="spnexp">
                            OTP expires in{" "}
                            <span className="clrspan">
                                {`${remainingMinutes}:${remainingSeconds}`}{" "}
                            </span>
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default KYCForm;