import React, { useContext } from 'react';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from "../../Contexts/Language";

// Components
import GeneralButton from '../Buttons/GeneralButton';
import DateField from '../Textfields/DateField';

// Styles
import './Modals.css'
import '../Profile/Account-Statement/account-statement.css'

function AccountStatementFilterModal({ transactionStatus, startDate, onChangeStartDate, endDate, onChangeEndDate, onChangeSelect, onClickApply, onClickClose }) {
    const { Language } = useContext(Language_Context)
    return (
        <div className="popup3 d-flex align-items-center justify-content-center">
            <div className="popup-content3" style={{ height: "12.5rem" }}>
                <div className="row bet-r">
                    <div class="col-sm-3 col-md-5 col-5">
                        <DateField
                            id={"fromDate"}
                            name={"fromDate"}
                            value={startDate}
                            placeholder={""}
                            onChange={(e) => onChangeStartDate(e)}
                            title={Translation_Table.start[Language]}
                        />
                    </div>
                    <div class="col-sm-3 col-md-5 col-5">
                        <DateField
                            id={"fromDate"}
                            name={"fromDate"}
                            value={endDate}
                            placeholder={""}
                            onChange={(e) => onChangeEndDate(e)}
                            title={Translation_Table.end[Language]}
                        />
                    </div>

                    <div class="col-sm-3 col-md-5 col-5">
                        <label className="label-bet">
                            {Translation_Table.status[Language]}
                        </label>
                        <select
                            className="form-select inpt-bet"
                            value={transactionStatus}
                            onChange={(e) => onChangeSelect(e)}
                        >
                            <option value={"Select Status"}>
                                Select Status
                            </option>
                            <option value={"Pending"}>
                                Pending
                            </option>
                            <option value={"Approved"}>
                                Approved
                            </option>
                            <option value={"Rejected"}>
                                Rejected
                            </option>
                            <option value={"Failed"}>
                                Failed
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-3 col-md-5 col-5"></div>

                    <div className='bet-history-filter-modal-pop-up-button-container'>
                        <GeneralButton
                            showText={true}
                            text={Translation_Table.apply[Language]}
                            onClick={onClickApply}
                        />

                        <GeneralButton
                            showText={true}
                            text={Translation_Table.close[Language]}
                            onClick={onClickClose}
                        />
                    </div>


                </div>
            </div>
        </div>
    )
}

export default AccountStatementFilterModal;