import React, { useContext, useState, useEffect, useRef } from 'react';
import { Casino_APIs } from '../../API/Casino_APIs';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../../Utilities/DecryptData';
import Logout_User from '../../Utilities/Logout';

// Context
import { User_Context } from '../../Contexts/User';

// Components
import Loader from '../../components/Loader/Loader';

// Styles
import './QTechCasino.css'

function QTechCasino() {
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [lobbyURL, setLobbyURL] = useState('');
    const iframeRef = useRef(null);

    const fetchURL = async () => {
        setLoading(true)
        try {
            let payload = {
                userId: User.data._id
            }
            const response = await Casino_APIs.Qtech_Lobby_URL(payload, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const decryptedData = decryptData(data)
                setLobbyURL(decryptedData.payload.url)
            } else {

            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate('/')
            }
        }
    }

    useEffect(() => {
        fetchURL();
    }, []);

    return (
        <>
            {loading && <Loader />}

            {!loading &&
                <>
                    <iframe
                        className='Game_Page'
                        src={lobbyURL}
                        height={'800px'}
                        width='100%'
                        frameBorder='0'
                        ref={iframeRef}
                    ></iframe>
                    <div id='egamings_container'></div>
                </>
            }
        </>
    )
}

export default QTechCasino;