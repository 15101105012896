import React, { useContext, useRef } from 'react';
import { User_Context } from '../../Contexts/User';

// Styles
import './LiveChat.css'

function LiveChat() {
    const iframeRef = useRef(null);
    const { User, Set_User } = useContext(User_Context);

    return (
        <div>
            <iframe
                className='iframe-live-chat'
                src={`https://chatapp.space/chat?from_id=${User.data._id}&from_user=${User.data.name.replace(/\s/g, '')}&to_id=${User.data.supportExecutiveDp_Id}&url_type=mobile_user&msg=true`}
                height={'640px'}
                width='100%'
                frameBorder='0'
                allow="microphone"
                ref={iframeRef}
            ></iframe>
        </div>
    )
}

export default LiveChat;