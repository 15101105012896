import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import SignUp from "./SignUp";
import Eyeslash from "../../images/eye-slash.svg";
import Eyefill from "../../images/eye-fill.svg";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";
import ForgotPassword from "./Forgot-password";
import Loader from "../Loader/Loader";
import { User_Context } from "../../Contexts/User";
import { toast } from "react-toastify";
import { User_APIs } from "../../API/User_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";
import { decryptData } from "../../Utilities/DecryptData";
import { encryptData } from "../../Utilities/EncryptData";
import { getRandomElement } from "../../Utilities/getRandomElement";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseConfig } from '../../Configuration/Firebase'

// Components
import GeneralButton from "../Buttons/GeneralButton";
import GeneralTextfield from "../Textfields/GeneralTextfield";

// Styles
import '../../css/login.css'

function Login(props) {
  const intialValues = { username: "", email: "", password: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [modalForgetPassword, setModalForgetPassword] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const [inputCaptchaCode, setInputCaptchaCode] = useState("");
  const [userNameVerify, setUserNameVerify] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState("");
  const [verifyPassword, setVerifyPassword] = useState(true);
  const [data, setData] = useState({});
  const cookies = new Cookies();
  const { User, Set_User, redirectPath, setRedirectPath } = useContext(User_Context);
  const { Language, Set_Language } = useContext(Language_Context)
  const [mobile, setMobile] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [supportDPID, setSupportDPID] = useState('');
  const [fcmToken, setFcmToken] = useState('');
  const vapidKey = process.env.VAPID_KEY;
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };
  const validateEmail = (userName) => {
    const emailRegex =
      /^(([^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+(\.[^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(userName);
  };
  const validatMobile = (mobile) => {
    const emailRegex = /[6-9]{1}[0-9]{9}/;
    return emailRegex.test(mobile);
  };

  // const app = initializeApp(firebaseConfig);
  // const messaging = getMessaging(app);

  // const requestBrowserPermissions = () => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === 'granted') {
  //       getToken(messaging, { vapidKey: 'BD1BsGW2yFGnn0YkGmIPXInT6hG_JpUvAHmg4qFotdMG0gr7bFNxvTviE5iMd4_NgDoAW5FEX11-A6gx64bbg9s' }).then((currentToken) => {
  //         if (currentToken) {
  //           // Send the token to your server and update the UI if necessary
  //           // ...
  //           setFcmToken(currentToken)
  //         } else {
  //           // Show permission request UI
  //           console.log('No registration token available. Request permission to generate one.');
  //           // ...
  //         }
  //       }).catch((err) => {
  //         console.log('An error occurred while retrieving token. ', err);
  //         // ...
  //       });
  //     }
  //   }
  //   )
  // }

  // const getChatSupport = async () => {
  //   setIsLoading(true)
  //   try {
  //     const response = await User_APIs.Get_Chat_Support({})
  //     const responseData = response.data ? response.data : {}
  //     const { success, data } = responseData;
  //     if (success) {
  //       const chatSupportData = decryptData(data);
  //       const dp_id = getRandomElement(chatSupportData.payload);
  //       setSupportDPID(dp_id)
  //     }
  //   } catch (error) {
  //     // console.log(error, 'error')
  //   }
  //   setIsLoading(false)
  // }


  const Login = async (e) => {
    e.preventDefault()
    if (password.length < 6) {
      setPasswordError(true)
    }
    const check = !validateEmail(userName);
    setVerifyPassword(validatePassword(password));

    // setUserNameVerify(validateEmail(userName) || validatMobile(userName));
    // if (
    //   validatePassword(password) &&
    //   (validateEmail(userName) || validatMobile(userName))
    // ) {
      setIsLoading(true)
      let data1 = {
        name:userName,
        password:password
      }
      // let emailPayload = {
      //   email: userName,
      //   password
      // }
      // let mobilePayload = {
      //   mobile: userName,
      //   password
      // }

      // if (fcmToken) {
      //   emailPayload.fireBaseToken = fcmToken;
      //   mobilePayload.fireBaseToken = fcmToken;
      // }
      // if (supportDPID !== "") {
      //   emailPayload.supportExecutiveDp_Id = supportDPID;
      //   mobilePayload.supportExecutiveDp_Id = supportDPID;
      // }
      // User_APIs.Log_In(validateEmail(userName) ? emailPayload : mobilePayload)
      User_APIs.Log_In(data1)
        .then((response) => {
          if (response && response.data && response.data.success) {
            setIsLoading(false)
            response.data.data = decryptData(response.data.data);
            cookies.set("userData", encryptData(response.data.data.payload));
            cookies.set("jwt-authorization", encryptData(response.data.token));
            Set_User({ token: response.data.token, data: response.data.data.payload });
            handleClose();
            navigate(redirectPath)
          }
          setData(data);
        })
        .catch((error) => {
          setIsLoading(false)
          toast.error(error.response.data.message)
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
          }
          if (error.response.status === 406) {
            toast.error(error.response.data.message);
          }
          if (error.response.status === 403) {
            toast.error(error.response.data.message);
          }
        });
    // }
  }
  const validatePassword = (password) => {
    const emailRegex = /(?=.{6,})/;
    return emailRegex.test(password);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, []);

  useEffect(() => { }, [data]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.username) {
      errors.username = "Username is required!";
    }

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    return errors;
  };

  const [modalShow3, setModalShow3] = useState(false);

  const handleClose = () => {
    setFormValues({ username: "", email: "", password: "" });
    setModalShow3(false);
    props.onHide();
    setInputCaptchaCode("");
    setPassword("");
    setCaptchaCode("");
    setFormValues("");
    setUserNameVerify(true);
    setVerifyPassword(true);
    setFormErrors("");
    setError("")
    navigate("/")
  };

  // const decryptData = (encryptedData) => {
  //   // Assuming encryptedData is "33??????????????"

  //   // Trim leading and trailing whitespace
  //   const trimmedData = encryptedData.trim(); // "33??????????????"

  //   // Remove unwanted characters using regular expression
  //   const cleanedData = trimmedData.replace(/[^0-9a-zA-Z]/g, ""); // "33"

  //   return cleanedData;
  // };

  function modalClose(e) {
    setModalShow3(true);
  }

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  // useEffect(() => {
  //   requestBrowserPermissions();
  //   getChatSupport();
  // }, []);

  return (
    <>
      {!modalForgetPassword ? (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}
          id="login-modal"
        >
          <Modal.Header className="hdr">

            {Translation_Table.login[Language]}
            <Crosssmall
              onClick={() => {
                handleClose()
              }}
              className="cross-kyc"
            />
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="login-second">
                <section className="secLogin">
                  <div className="login-form">
                    {isLoading && <Loader />}
                    <div
                      className="login-main-bx registerForm"
                      name="registerForm"
                      noValidate
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="username-input mt-3">
                            {error !== "email not exist" && userNameVerify && error === "" || error == "wrong password" ? (
                              <GeneralTextfield
                                label={Translation_Table.username[Language] + " * "}
                                type={"text"}
                                onChange={(e) => {
                                  setUserName(e.target.value);
                                }}
                              />
                            ) : (
                              <GeneralTextfield
                                error={true}
                                label={!mobileError ? Translation_Table.username[Language] : Translation_Table.mobiledonotexists[Language]}
                                onChange={() => {
                                  if (userName.length >= 0) {
                                    setError("");
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="clrBoth" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="username-input mt-3">

                            <GeneralTextfield
                              autoComplete={"current-password"}
                              error={
                                error === "wrong password" || !validatePassword(password) ? error : null
                              }
                              label={
                                error === "wrong password"
                                  ? Translation_Table.wrongpassword[Language]
                                  : password.length > 1 && password.length < 6
                                    ? Translation_Table.passwordLength[Language]
                                    :
                                    `${Translation_Table.password[Language] + " * "} `
                              }
                              value={
                                error === Translation_Table.wrongpassword[Language] ? "" : password
                              }
                              type={passwordVisible ? "text" : "password"}
                              onChange={(e) => {
                                setPasswordError(false)
                                setPassword(e.target.value);
                                setError("");
                              }}
                            />

                            <div className="eye1" onClick={handleToggle}>
                              {passwordVisible ? (
                                <img src={Eyefill} />
                              ) : (
                                <img src={Eyeslash} />
                              )}
                            </div>
                          </div>
                          <div className="clrBoth" />
                        </div>
                      </div>

                      <div className="clrBoth" />
                      <div className="newsignup mt-2">

                        <GeneralButton
                          showText={true}
                          onClick={(e) => {
                            Login(e);
                          }}
                          showSmall={false}
                          text={Translation_Table.login[Language]}
                        />

                        {/* <GeneralButton
                          showText={true}
                          onClick={modalClose}
                          showSmall={false}
                          text={Translation_Table.register[Language]}
                        /> */}
                        <SignUp
                          closeSignUp={() => setModalShow3(false)}
                          show={modalShow3}
                          onHide={handleClose}
                        />
                      </div>

                      <div className="newsignup mt-3">
                        <span
                          className="under"
                          onClick={() => {
                            setModalForgetPassword(true);
                          }}
                        >
                          {Translation_Table.forgetPassword[Language]}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <ForgotPassword
          show={modalForgetPassword}
          onHide={() => {
            setModalForgetPassword(false);
          }}
        />
      )}
    </>
  );
}

export default Login;