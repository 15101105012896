import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ExchangeSelect-styles.css";
import { Game_Context } from "../../Contexts/Games";
import { User_Context } from "../../Contexts/User";
import { API_Endpoint } from "../../Configuration/Settings";
import { Various_APIs } from "../../API/Various_APIs";
import { decryptData } from "../../Utilities/DecryptData";
import Loader from "../../components/Loader/Loader";

export const ExchangeSelect = () => {
    const navigate = useNavigate();
    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const { bannerArray: gameArray = [] } = gameState;
    const { User } = useContext(User_Context);
    const [exchangeBannerArray, setExchangeBannerArray] = useState([]);
    const [loading, setLoading] = useState();

    useEffect(() => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: API_Endpoint,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
        };
        Various_APIs.Get_Landing_Page_Data()
            .then((response) => {
                const data = decryptData(response.data.data);
                const bannerArrs = data.payload.bannerGames;
                setExchangeBannerArray(bannerArrs);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const bannerArray =
        exchangeBannerArray.filter(
            (item) =>
                (item?.iframeUrl === "exchange" || item?.iframeUrl === "falcon_exchange") &&
                item?.type === "exchangeBanner" &&
                item?.status === true
        ) || [];

    const ExchangeTypeCards = () => (
        <>
            {bannerArray.map((item) => {
                const url = item?.iframeUrl ?? "";
                const onPress = () => navigate(`/${url}`);
                return (
                    <div className="casino-select-image-container" onClick={onPress}>
                        <img src={item?.imagePath ?? ""} className="mainBannerImage" />
                    </div>
                );
            })}
        </>
    );

    return (
        <>
            {loading && <Loader />}
            <div className="casino-select-container">
                <div className="casino-select-text-container">
                    <p className="casino-select-text" style={{ marginBottom: 0 }}>
                        Select a Exchange type
                    </p>
                </div>
                <ExchangeTypeCards />
            </div>
        </>
    );
};
