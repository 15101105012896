import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { User_Context } from "../../Contexts/User";
import { toast } from "react-toastify";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import { Payment_APIs } from "../../API/Payment_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";

// Components
import GeneralButton from "../Buttons/GeneralButton";
import GeneralTextfield from "../Textfields/GeneralTextfield";
import WithdrawalWarning from "../WithdrawalWarning/WithdrawalWarning";
import Loader from "../Loader/Loader";

// Assets
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";

// Styles
import "../../css/withdrawmodal.css";


const Withdraw = (props) => {
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const [accountHolderName, setAccountHolderName] = useState("");
	const [bankName, setBankName] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [ifscCode, setIfscCode] = useState("");
	const [userData, setUserdata] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const [withdrawError, setWithdrawError] = useState(false);
	const [accountNameError, setAccountNameError] = useState(false);
	const [userContextAvailable, setUserContextAvailable] = useState(false);
	const [bankNameError, setBankNameError] = useState(false);
	const [accountNumberError, setAccountNumberError] = useState(false);
	const [ifscCodeError, setIfscCodeError] = useState(false);

	const [withdrawHelpertext, setWithdrawHelperText] = useState("");
	const [accountNameHelpertext, setAccountNameHelpertext] = useState("");
	const [bankNameHelpertext, setBankNameHelpertext] = useState("");
	const [accountNumberHelpertext, setAccountNumberHelperText] = useState("");
	const [ifscCodeHelpertext, setIfscCodeHelpertext] = useState("");

	const navigate = useNavigate()

	const { User, Set_User } = useContext(User_Context);
	const { Language, Set_Language } = useContext(Language_Context)


	const withdrawal = async () => {
		setLoading(true)
		// if (!withdrawAmount) {
		// 	setWithdrawError(true);
		// 	setWithdrawHelperText("Please Enter Withdrawal Amount");
		// }
		if (withdrawAmount > (User?.data?.balance ?? 0)) {
			setLoading(false)
			toast.error('Insufficient Balance to proceed with this withdrawal')
			return;
		}
		else {
			if (withdrawAmount < 1000) {
				// setWithdrawError(true);
				// setWithdrawHelperText("Minimum Withdrawal limit is 1000 and Maximum Withdrawal limit is 100000 , you can place multiple withdrawal of 100000 if withdrawal amount is greater than or equal to 100000");
				// setLoading(false)
				setLoading(false)
				toast.error("Minimum Withdrawal limit is 1000")
			} else if (withdrawAmount > 100000) {
				setLoading(false)
				toast.error("Maximum Withdrawal limit is 100000 you can place multiple withdrawal of 100000 if withdrawal amount is greater than or equal to 100000")
			} else {
				let data = {
					dp_id: User.data._id,
					amount: withdrawAmount,
					accountNo: User.data.accountNumber ? User.data.accountNumber : "",
					mobile: User.data.mobile,
					ifscCode: User.data.ifsc ? User.data.ifsc : "",
					bankName: User.data.bankName ? User.data.bankName : "",
					accountHolderName: User.data.name,
				};
				Payment_APIs.Withdraw(data, User.token)
					.then((response) => {
						// const data = Decode_JWT (response.data.data);
						handleModalClose();
						if (response.data.message === 'you do not have enough balance') {
							toast.error(response.data.message);
						} else {
							toast.success(response.data.message);
						}
						setLoading(false)
					})
					.catch((error) => {
						setLoading(false)
						toast.error(error.response.data.message);
						if (error.response.status === 401) {
							Logout_User(Set_User)
							navigate("/")
						}
					});
			}
		}
	};

	const handleModalClose = () => {
		props.onHide();
		setWithdrawAmount("");
		setWithdrawError(false);
		setWithdrawHelperText("");
		setAccountHolderName("");
		setAccountNameError(false);
		setAccountNameHelpertext("");
		setBankName("");
		setBankNameError(false);
		setBankNameHelpertext("");
		setAccountNumber("");
		setAccountNumberError(false);
		setAccountNumberHelperText("");
		setIfscCode("");
		setIfscCodeError(false);
		setIfscCodeHelpertext("");
	};
	return (
		<>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="clsbtn">
					<span className="withd">
						{Translation_Table.withdrawal[Language]}
					</span>
					<Crosssmall onClick={handleModalClose} className="cross-kyc" />
				</Modal.Header>
				<Modal.Body className="modal-withdraw">
					<div>
						{loading && <Loader />}
						<div class="modal-body payMain ouerMainDv" style={{ padding: "0" }}>
							<div className="tab-pane " id="withdraw" role="tabpanel">
								<div className="tab-content-tsp tab-table-data tabpne-with tpmar">
									<div className="withdraw_div">
										<div name="withdrawal_form_fill" className="withdrawal-popup-modal">
											<div className="col-12">
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															<GeneralTextfield
																onChange={(e) => {
																	setWithdrawAmount(e.target.value);
																	setWithdrawError(false);
																	setWithdrawHelperText("");
																}}
																error={withdrawError}
																helperText={withdrawHelpertext}
																value={withdrawAmount}
																label={Translation_Table.WithdrawalAmount[Language]}
															/>
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															<GeneralTextfield
																value={User.data.name}
																disabled={true}
															/>
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															<GeneralTextfield
																value={User.data.bankName ? User.data.bankName : ""}
																disabled={true}
															/>

														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															<GeneralTextfield
																value={User.data.accountNumber ? User.data.accountNumber : ""}
																disabled={true}
															/>
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															<GeneralTextfield
																value={User.data.ifsc ? User.data.ifsc : ""}
																disabled={true}
															/>
														</div>

													</div>
												</div>
											</div>
											<div className="mt-3">
												<GeneralButton
													onClick={withdrawal}
													showText={true}
													text={Translation_Table.submit[Language]}
												/>
											</div>
										</div>

										<WithdrawalWarning />
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Withdraw;