import { Casino_APIs } from "../../API/Casino_APIs";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Currency } from "../../Configuration/Constants";
import { Exchange_Enums } from "../../Configuration/Enums/Exchange";
import "./Game.css";
import Game_iframe from "../../components/Game_iframe/Game_iframe";
import Header from "../../components/AppHeader/Header";
import { Jetfair_Agent_Username, Satta_Matka_Endpoint } from "../../Configuration/Settings";
import { Providers_Enums } from "../../Configuration/Enums/Providers";
import { useContext, useEffect, useRef, useState } from "react";
import { User_Context } from "../../Contexts/User";
import { useParams } from "react-router-dom";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import PageNotFound from "../../components/NotFoundPage/PageNotFound";
import { decryptData } from "../../Utilities/DecryptData";

const Game = ({ Provider }) => {
    const [Success, Set_Success] = useState(true);
    const { Merchant_ID, Page } = useParams();
    const [Game_Code, Set_Game_Code] = useState({});
    const [Provider_URL, Set_Provider_URL] = useState("");
    const { User, Set_User } = useContext(User_Context);
    const [showData, setShowData] = useState(false);
    const navigate = useNavigate();
    const iframe_Reference = useRef(null);
    const [
        Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically,
        Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically,
    ] = useState(true);
    const Location = useLocation();
    const { bazarId = "", result = "" } = Location?.state || {};

    useEffect(() => {
        window.scrollTo(0, 0);
        User_APIs.Validate_JWT({}, User.token)
            .then((response) => {
                setShowData(true);
            })
            .catch((error) => {
                Logout_User(Set_User);
                navigate("/");
            });
    }, []);

    const Launch_Game = async () => {
        if (Provider === Providers_Enums.SoftGaming) {
            const Response = await Casino_APIs.Get_Lobby(
                { page: Page, merchantID: Merchant_ID, _id: User.data._id },
                User.token
            );
            if (Response.data && Response.data.success) {
                Response.data.data = decryptData(Response.data.data);
                try {
                    const Parsed_Response = JSON.parse(Response.data.data.payload);
                    if (
                        Parsed_Response.gameScript &&
                        typeof Parsed_Response.gameScript === "string" &&
                        Parsed_Response.gameScript.includes("location.replace")
                    ) {
                        const Game_JavaScript = document.createElement("script");
                        Game_JavaScript.innerHTML = Parsed_Response.gameScript;
                        iframe_Reference.current.contentDocument.head.appendChild(Game_JavaScript);
                        const Game_HTML = document.createElement("div");
                        Game_HTML.id = "egamings_container";
                        iframe_Reference.current.contentDocument.body.appendChild(Game_HTML);
                    } else {
                        Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically(
                            false
                        );
                        Set_Game_Code(Parsed_Response);
                    }
                } catch {
                    Set_Success(false);
                    toast.info("Sorry, this game is not available :(");
                    if (Location.pathname.includes("game")) {
                        setTimeout(() => {
                            navigate("/casino");
                        }, 7500);
                    }
                }
            } else {
                Set_Success(false);
            }
        } else if (Provider === Providers_Enums.Jetfair) {
            const Response = await Casino_APIs.Log_In_to_Jetfair(
                {
                    agentUsername: Jetfair_Agent_Username,
                    clientUsername: User.data._id,
                    point: 1,
                    isDemoUser: process.env.REACT_APP_Environment === "Production" ? 0 : 1,
                    isAllowBet: 1,
                    eventId: null,
                    sportId: null,
                    websiteURL: window.location.origin,
                    isIframe: 1,
                    type: Exchange_Enums.Empty,
                    currency: Currency,
                },
                User.token
            );
            if (Response && Response.data && Response.data.success) {
                Response.data.data = decryptData(Response.data.data);
                if (Response.data.data.payload.lobbyURL) {
                    Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically(false);
                    Set_Provider_URL(Response.data.data.payload.lobbyURL);
                } else {
                    Set_Success(false);
                }
            } else {
                Set_Success(false);
            }
        } else if (Provider === Providers_Enums.Nandi) {
            const bazaUrl = `${Satta_Matka_Endpoint}/9a27a7e97c16a7b3ac6382d21205357f/${bazarId}/${result}/?token=${process.env.REACT_APP_Satta_Matka_Key}&id=${User.data._id}&app=FB`;
            const gameLobbyUrl = `${Satta_Matka_Endpoint}/?token=${process.env.REACT_APP_Satta_Matka_Key}&id=${User.data._id}&app=FB`;
            const finalRedirectionUrl = bazarId && result ? bazaUrl : gameLobbyUrl;
            Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically(false);
            Set_Provider_URL(finalRedirectionUrl);
        } else if (Provider === Providers_Enums.Falcon) {
            let falocn_login_request = {
                // "partnerId":process.env.FALCON_PARTNER_ID,
                // "PartnerId":"fairbetsstaging",
                Username: User.data.mobile,
                isDemo: false,
                isBetAllow: true,
                isActive: true,
                Point: 0,
            };

            const Response = await Casino_APIs.Log_In_to_Falcon(falocn_login_request, User.token);

            if (Response.status && Response.status == 200) {
                if (Response.data && Response.data.data) {
                    Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically(false);
                    Set_Provider_URL(Response.data.data.url);
                } else {
                    Set_Success(false);
                    toast.error("error while loading falcon exchange");
                    setTimeout(() => {
                        navigate("/");
                    }, 7500);
                }
            } else {
                Set_Success(false);
                toast.error("error while loading falcon exchange");
                setTimeout(() => {
                    navigate("/");
                }, 7500);
            }
        } else if (Provider === Providers_Enums.Wacs) {
            if (Location.state.Game_URI && typeof Location.state.Game_URI === "string") {
                Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically(false);
                Set_Provider_URL(Location.state.Game_URI);
            }
        } else if (Provider === Providers_Enums.QTech) {
            try {
                let payload = {
                    _id: User.data._id,
                    currency: "INR",
                    lang: "en_US",
                    mode: "real",
                    device: "mobile",
                    walletSessionId: User.data._id,
                    gameId: Location.state.gameId,
                };
                // if (Location.state.tableId) {
                // 	payload.tableId = Location.state.tableId;
                // }
                const response = await Casino_APIs.Qtech_Game_Launch(payload, User.token);
                const { data, success } = response ? response.data : {};
                const finalDataQTech = decryptData(data);
                if (success && finalDataQTech.payload.url) {
                    Set_Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically(false);
                    Set_Success(true);
                    Set_Provider_URL(finalDataQTech.payload.url);
                }
            } catch (error) {
                console.log("=============>error", error);
                Set_Success(false);
                toast.error("QTech Game launch error");
                setTimeout(() => {
                    navigate("/");
                }, 7500);
            }
        }
    };

    useEffect(() => {
        Launch_Game();
        window.scrollTo(0, 0);
    }, [Provider]);

    return (
        <>
            {!Success && <Loader />}
            {Success ? (
                Provider !== Providers_Enums.SoftGaming ||
                (Provider === Providers_Enums.SoftGaming &&
                    Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically) ? (
                    <>
                        <iframe
                            className="Game_Page"
                            src={
                                Provider_who_Does_Not_Know_That_You_Can_Create_iframes_Dynamically
                                    ? `${window.origin}/non-existing-page`
                                    : Provider_URL
                            }
                            height={Provider === Providers_Enums.Nandi ? "800px" : "640px"}
                            width="100%"
                            frameBorder="0"
                            ref={iframe_Reference}
                        ></iframe>
                        <div id="egamings_container"></div>
                    </>
                ) : (
                    <Game_iframe
                        HTML={Game_Code.gameHtml}
                        JavaScript={Game_Code.gameScript}
                    ></Game_iframe>
                )
            ) : (
                <PageNotFound />
            )}
        </>
    );
};

export default Game;
