import React, { useState, useEffect } from "react";
import Payment from "./components/Payment";
import { Routes, Route } from "react-router-dom";
import Language_Provider from "./Contexts/Language";
import User_Provider from "./Contexts/User";
import Games_Provider from "./Contexts/Games";
import { Providers_Enums } from "./Configuration/Enums/Providers";
import { Legal_Information_Enums } from "./Configuration/Enums/Legal_Information";
import Private_Route from "./Routes/Private_Route";
import Web_Socket_Provider from "./Contexts/Web_Socket";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Various_APIs } from "./API/Various_APIs";
import { decryptData } from "./Utilities/DecryptData";
import { Clear_Cache } from "./Utilities/Clear_Cache";
import ExchangeCard from "./components/Exchange/ExchangeCard";
// Pages
import Home from "./Pages/Home/Home";
import Game from "./Pages/Game/Game";
// import Casino from "./Pages/Casino/Casino";
import Casino from "./Pages/QTechCasino/QTechCasinoOld";
import QTechCasino from "./Pages/QTechCasino/QTechCasino";
import SelectedCasino from "./Pages/SelectedCasino/SelectedCasino";
import Profile from "./Pages/Profile/Profile";
import Feedback from "./Pages/Feedback/Feedback";
import LoadingPage from "./Pages/LoadingPage/LoadingPage";
import Legal from "./Pages/Legal/Legal";
import Deposit from "./Pages/Deposit/Deposit";
import AccountStatement from "./components/Profile/Account-Statement/AccountStatement";
import BetHistory from "./components/Profile/Bet-History/BetHistory";
import CasinoSelect from "./Pages/CasinoSelect/CasinoSelect";
import Not_Found from "./Pages/Not_Found/Not_Found";
import Exchange from "./components/Exchange/Exchange";
import LiveChat from "./Pages/LiveChat/LiveChat";
import ScannerQr from "./components/Scanner/Scanner";
import CasinoCategories from "./Pages/Casino/CasinoCategories";
import BonusWalletHistory from "./Pages/BonusWalletHistory/BonusWalletHistory";
import TopGames from "./Pages/TopGames/TopGames";
import IndianGames from "./Pages/IndianGames/IndianGames";
import BonusWalletTerms1 from "./Pages/BonusWalletTerms/BonusWalletTerms1";
import BonusWalletTerms2 from "./Pages/BonusWalletTerms/BonusWalletTerms2";
import BonusWalletTerms3 from "./Pages/BonusWalletTerms/BonusWalletTerms3";
import Register from "./components/Register/Register";
import RegisterOtp from "./components/Register/RegisterOtp";
import PendingBetHistory from "./components/Profile/Bet-History/PendingBetHistory";
import MasterAccountStatement from "./components/Profile/Account-Statement/MasterAccountStatement";
import TransferStatement from "./components/Profile/Account-Statement/TransferStatement"

// Components
import AppFooter from "./components/AppFooter/AppFooter";

// Styles
import "./Style/Utility_Classes.css";
import Bonus from "./components/BonusWallet/Bonus";
import SattaMarket from "./Pages/SattaMarket/SattaMarket";
import WithdrawalRequest from "./components/WithdrawalRequests/WithdrawalRequest";
import { ExchangeSelect } from "./Pages/ExchangeSelect/ExchangeSelect";
import LiveSession from "./components/Exchange/ExchangeSections/ExchangeDataSection/LiveSession/LiveSession";

// context
import ExchangeMarketContext from "./Contexts/ExchangeMarketContext";
import ExchangeContext from "./Contexts/ExchangeContext";
import NewHeader from "./components/AppHeader/NewHeader";
import LiveMatchStateComponent from "./components/Exchange/ExchangeSections/ExchangeDataSection/LiveMatchStates/LiveMatchStateComponent";
import WatchLive from "./components/Exchange/ExchangeSections/ExchangeDataSection/LiveSession/WatchLiveMatch";
const App = () => {
    const location = useLocation();
    const { pathname } = location ? location : {};
    const [lat, setLat] = useState("");
    const [lan, setLan] = useState("");
    const isTabOrMobile = window.matchMedia("(max-width: 1025px)").matches;
    const [showBetFilter, setShowBetFilter] = useState(false);
    const [showAccountStatementFiler, setShowAccountStatementFilter] = useState(false);

    const changeBetFilter = () => {
        setShowBetFilter(!showBetFilter);
    };

    const changeAccountFilter = () => {
        setShowAccountStatementFilter(!showAccountStatementFiler);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (Position) => {
                setLat(`${Position.coords.latitude}`);
                setLan(`${Position.coords.longitude}`);
            },
            (error) => {
                if (error?.code === 1) {
                    toast.error("Please allow location");
                }
            }
        );
        Various_APIs.Get_Version()
            .then((API_Response) => {
                const Decrypted_Data = decryptData(API_Response.data.data);
                if (localStorage.getItem("Kuberbets_Version") !== Decrypted_Data.payload.Version) {
                    localStorage.setItem("Kuberbets_Version", Decrypted_Data.payload.Version);
                    Clear_Cache(true);
                }
            })
            .catch((Failure) => {
                console.log("=============Failure", Failure);
            });
    }, []);

    return (
        <Language_Provider>
            <User_Provider>
                <Games_Provider>
                    <ExchangeContext>
                        <ExchangeMarketContext>
                            <Web_Socket_Provider>
                                <NewHeader
                                    lat={lat}
                                    lan={lan}
                                    isProfile={pathname === "/profile" ? true : false}
                                    isBetHistory={pathname === "/bet-history" ? true : false}
                                    isBonusWallet={
                                        pathname === "/bonus-wallet-history" ? true : false
                                    }
                                    isBonusScreen={
                                        pathname === "/bonus" ||
                                            pathname === "/bonus-welcome-tnc" ||
                                            pathname === "/bonus-refill-tnc" ||
                                            pathname === "/bonus-referral-tnc"
                                            ? true
                                            : false
                                    }
                                    isBonusMobileScreen={
                                        pathname === "/bonus-welcome-tnc-mobile" ||
                                            pathname === "/bonus-refill-tnc-mobile" ||
                                            pathname === "/bonus-referral-tnc-mobile"
                                            ? true
                                            : false
                                    }
                                    showBetHistoryFilter={
                                        pathname === "/bet-history" ? true : false
                                    }
                                    onBetHistoryClick={() => changeBetFilter()}
                                    onAccountStatementClick={() => changeAccountFilter()}
                                    isDeposit={pathname === "/deposit" ? true : false}
                                    isDepositMobile={pathname === "/deposit-mobile" ? true : false}
                                    isFeedback={pathname === "/feedback" ? true : false}
                                    isGame={
                                        pathname === "/game" ||
                                            pathname === "/casino-game" ||
                                            pathname === "/qtech-casino-game" ||
                                            pathname === "/casino-qtech" ||
                                            pathname === "/satta" ||
                                            pathname === "/falcon_exchange" ||
                                            pathname === "/exchange" ||
                                            pathname === "/casino-category"
                                            ? true
                                            : false
                                    }
                                    isAccountStatement={
                                        pathname === "/account-statement" ? true : false
                                    }
                                    showAccountStatementFilter={
                                        pathname === "/account-statement" ? true : false
                                    }
                                    isWithdrawalRequest={
                                        pathname === "/withdrawal-Request" ? true : false
                                    }
                                    isCasinoCategoryMobile={
                                        pathname === "/casino-category-mobile" ? true : false
                                    }
                                    isMobileScanner={!!pathname.includes("/mobile-scanner")}
                                    isQtechCasinoGameMobile={
                                        !!pathname.includes("/qtech-casino-game-mobile")
                                    }
                                    isCasinoGameMobile={!!pathname.includes("/casino-game-mobile")}
                                />
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick={false}
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable={false}
                                    pauseOnHover
                                    theme="colored"
                                />
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route
                                        path="/bet-history"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <BetHistory
                                                    showBetFilter={showBetFilter}
                                                    setShowBetFilter={setShowBetFilter}
                                                />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/bonus-wallet-history"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <BonusWalletHistory />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/scanner"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <ScannerQr />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/pending-bet"
                                        element={<Private_Route Redirection_Path='/'><PendingBetHistory /></Private_Route>}
                                    />
                                    <Route path="/mobile-scanner" element={<ScannerQr />} />
                                    <Route
                                        path="/user-register"
                                        element={<Register lat={lat} lan={lan} />}
                                    />
                                    <Route path="/user-register-otp" element={<RegisterOtp />} />
                                    <Route
                                        path="/casino"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Casino />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/casino-qtech"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <QTechCasino />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/casino-select"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <CasinoSelect />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/exchange-select"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <ExchangeSelect />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/exchange-data/:code"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <ExchangeCard />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/live-streaming/:eventid"
                                        element={
                                            isTabOrMobile ? (
                                                <WatchLive isIframe={true} />
                                            ) : (
                                                <Private_Route Redirection_Path="/">
                                                    <WatchLive isIframe={true} />
                                                </Private_Route>
                                            )
                                        }
                                    />
                                    <Route
                                        path="/live-score/:eventid"
                                        element={
                                            isTabOrMobile ? (
                                                <LiveMatchStateComponent isIframe={true} />
                                            ) : (
                                                <Private_Route Redirection_Path="/">
                                                    <LiveMatchStateComponent isIframe={true} />
                                                </Private_Route>
                                            )
                                        }
                                    />

                                    <Route
                                        path="/selected-casino"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <SelectedCasino />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/top-games"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <TopGames />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/indian-games"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <IndianGames />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/exchangetype"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Exchange />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/master-account-statement"
                                        element={<Private_Route Redirection_Path='/'><MasterAccountStatement /></Private_Route>}
                                    />

                                    <Route
                                        path="/transfer-statement"
                                        element={<Private_Route Redirection_Path='/'><TransferStatement /></Private_Route>}
                                    />

                                    <Route
                                        path="/casino-category"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <CasinoCategories />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/casino-category-mobile"
                                        element={<CasinoCategories />}
                                    />

                                    <Route
                                        path="/exchange"
                                        element={<Private_Route Redirection_Path='/'><Game Provider={Providers_Enums.Jetfair} /></Private_Route>}
                                    />
                                    <Route
                                        path="/casino-game"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Game Provider={Providers_Enums.Wacs} />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/casino-game-mobile"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Game Provider={Providers_Enums.Wacs} />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/qtech-casino-game"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Game Provider={Providers_Enums.QTech} />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/qtech-casino-game-mobile"
                                        element={<Game Provider={Providers_Enums.QTech} />}
                                    />
                                    <Route
                                        path="/profile"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Profile />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/satta-market"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <SattaMarket />
                                            </Private_Route>
                                        }
                                    />

                                    <Route
                                        path="/payment"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Payment />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/live-chat"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <LiveChat />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/livechat"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <LiveChat />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/satta"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Game Provider={Providers_Enums.Nandi} />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/feedback"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Feedback />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/account-statement"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <AccountStatement
                                                    showAccountStatementFiler={
                                                        showAccountStatementFiler
                                                    }
                                                    setShowAccountStatementFilter={
                                                        setShowAccountStatementFilter
                                                    }
                                                />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/deposit"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Deposit lat={lat} lan={lan} />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/deposit-mobile"
                                        element={<Deposit lat={lat} lan={lan} />}
                                    />
                                    <Route
                                        path="/falcon_exchange"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Game Provider={Providers_Enums.Falcon} />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/bonus"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <Bonus />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/withdrawal-Request"
                                        element={
                                            <Private_Route Redirection_Path="/">
                                                <WithdrawalRequest />
                                            </Private_Route>
                                        }
                                    />
                                    <Route
                                        path="/bonus-welcome-tnc"
                                        element={<BonusWalletTerms1 />}
                                    />

                                    <Route
                                        path="/bonus-welcome-tnc-mobile"
                                        element={<BonusWalletTerms1 />}
                                    />

                                    <Route
                                        path="/bonus-refill-tnc"
                                        element={<BonusWalletTerms2 />}
                                    />

                                    <Route
                                        path="/bonus-refill-tnc-mobile"
                                        element={<BonusWalletTerms2 />}
                                    />

                                    <Route
                                        path="/bonus-referral-tnc"
                                        element={<BonusWalletTerms3 />}
                                    />

                                    <Route
                                        path="/bonus-referral-tnc-mobile"
                                        element={<BonusWalletTerms3 />}
                                    />

                                    <Route
                                        path="/aml-policy"
                                        element={
                                            <Legal Document={Legal_Information_Enums.AML_Policy} />
                                        }
                                    />
                                    <Route
                                        path="/privacy-policy"
                                        element={
                                            <Legal
                                                Document={Legal_Information_Enums.Privacy_Policy}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/responsible-gaming"
                                        element={
                                            <Legal
                                                Document={
                                                    Legal_Information_Enums.Responsible_Gaming
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/terms-and-conditions"
                                        element={
                                            <Legal
                                                Document={
                                                    Legal_Information_Enums.Terms_and_Conditions
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path="/terms-and-condition-netent"
                                        element={
                                            <Legal
                                                Document={
                                                    Legal_Information_Enums.Terms_and_Conditions_NetEnt
                                                }
                                            />
                                        }
                                    />
                                    <Route path="/non-existing-page" element={<LoadingPage />} />
                                    <Route path="*" element={<Not_Found></Not_Found>} />
                                </Routes>
                                {pathname !== "/profile" &&
                                    pathname !== "/bet-history" &&
                                    pathname !== "/account-statement" &&
                                    pathname !== "/deposit" &&
                                    // pathname !== "/selected-casino" &&
                                    pathname !== "/deposit-mobile" &&
                                    pathname !== "/bonus-referral-tnc" &&
                                    pathname !== "/casino-category-mobile" &&
                                    pathname !== "/bonus-welcome-tnc-mobile" &&
                                    pathname !== "/bonus-referral-tnc-mobile" &&
                                    pathname !== "/bonus-refill-tnc-mobile" &&
                                    !pathname.includes("/mobile-scanner") &&
                                    pathname !== "/qtech-casino-game-mobile" &&
                                    pathname !== "/casino-game-mobile" && (
                                        // pathname !== "/casino-qtech" &&
                                        <AppFooter />
                                    )}
                            </Web_Socket_Provider>
                        </ExchangeMarketContext>
                    </ExchangeContext>
                </Games_Provider>
            </User_Provider>
        </Language_Provider>
    );
};

export default App;
