import React, { useState, useContext, useEffect, useRef, useCallback } from "react";

import "./TopSattaMarket.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactComponent as TvIcon } from "../../Assets/topSattaMarket/tvIcon.svg";
import sattaMarket1 from "../../Assets/topSattaMarket/sattaMarket1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";
import GamesHeaderMarker from "../GamesHeaderMarker/GamesHeaderMarker";

// Other imports

import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Grid } from "@mui/material";
import { Image } from "react-bootstrap";
import { User_Context } from "../../Contexts/User";

import Logout_User from "../../Utilities/Logout";
import { Satta_Market_API } from "../../API/Satta_Market_API";
import { decryptData } from "../../Utilities/DecryptData";

//assert
import Clock from "../../Assets/Images/home-page-satta-market/clock.png";
import clockIcon from "../../Assets/topSattaMarket/clockIcon.png";
const TopSattaMarket = () => {
    const [sattaMarketData, setSattaMarketData] = useState([]);
    const { User, Set_User } = useContext(User_Context);
    const [loading, setLoading] = useState(false);
    const [marketList, setMarketList] = useState([]);

    const formatTime = (rawTime) => {
        const [hours, minutes] = rawTime.split(":");
        const formattedTime = new Date(0, 0, 0, hours, minutes);
        return formattedTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };
    const inferTimer = (day, fixedDateStr) => {
        try {
            const fixedDateTime = new Date(`${day} ${fixedDateStr}`);
            const nowDate = new Date();

            let duration = fixedDateTime.getTime() - nowDate.getTime();

            if (duration < 0) {
                const nextDay = new Date(fixedDateTime);
                nextDay.setDate(nextDay.getDate() + 1);
                duration = nextDay.getTime() - nowDate.getTime();
            }

            return duration;
        } catch (error) {
            console.error(error);
            return 0;
        }
    };
    function useInterval(callback, delay) {
        const callbackRef = useRef(callback);

        useEffect(() => {
            callbackRef.current = callback;
        }, [callback]);

        useEffect(() => {
            function tick() {
                if (callbackRef.current) {
                    callbackRef.current();
                }
            }
            if (delay !== null) {
                const id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
    function calculateRemainingTime(remainingSeconds) {
        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        const seconds = remainingSeconds % 60;
        return `${hours}:${minutes}:${seconds}s`;
    }

    const getGames = async () => {
        setLoading(true);
        try {
            const response = await Satta_Market_API.Get_Satta_Maket({}, User.token);
            const { data, success } = response.data ? response.data : {};
            if (success) {
                // const finalDataList = decryptData(data)
                setMarketList(data.slice(0, 5));
            }
        } catch (error) {
            setLoading(false);
            setMarketList([]);
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getGames();
    }, []);

    const updateInterval = 1000;

    useInterval(() => {
        if (marketList.length > 0) {
            const updatedMarketList = marketList.map((data) => {
                const openDay = data.oD[0];
                const fixedOpenTime = data.open_time;
                const closeDay = data.cD[0];
                const fixedCloseTime = data.close_time;

                const openRemainingTime = inferTimer(openDay, fixedOpenTime);
                const closeRemainingTime = inferTimer(closeDay, fixedCloseTime);

                // Convert milliseconds to hours, minutes, and seconds
                const convertToTime = (remainingTime) => {
                    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
                    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                    return { hours, minutes, seconds };
                };

                return {
                    ...data,
                    openRemainingTime: convertToTime(openRemainingTime),
                    closeRemainingTime: convertToTime(closeRemainingTime),
                };
            });

            setMarketList(updatedMarketList);
        }
    }, updateInterval);

    const navigate = useNavigate();
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;

    useEffect(() => {
        setSattaMarketData([
            { bazarName: "Morning Madhuri", bazarRate: "122-199-200", bazarOpenTime: "9:10" },
            { bazarName: "Morning Madhuri", bazarRate: "122-199-200", bazarOpenTime: "9:10" },
            { bazarName: "Morning Madhuri", bazarRate: "122-199-200", bazarOpenTime: "9:10" },
        ]);
    }, []);
    return (
        <>
            <div className="top-satta-market-section">
                <GamesHeaderMarker showBtn={true} markerText={"Top Satta Market"} />

                <div className="top-satta-market-swiper-container">
                    <Swiper
                        id="provider-swiper"
                        slidesPerView={isTabOrMobile ? 2 : 3}
                        slidesPerColumn={1}
                        className="myGameSwiper"
                        freeMode={true}
                        loop={false}
                        autoplay={{ delay: 2500 }}
                        slidesPerGroup={isTabOrMobile ? 2 : 3}
                        navigation={false}
                        pagination={{ clickable: true }}
                        modules={[FreeMode, Navigation, Pagination, Autoplay]}
                    >
                        {marketList.map((item, index) => {
                            const { bazar_id, result } = item;
                            const handleClick = () =>
                                navigate("/satta", { state: { bazarId: bazar_id, result } });
                            return (
                                <>
                                    <SwiperSlide
                                        onClick={handleClick}
                                        key={index}
                                        index={index}
                                        style={{
                                            width: isTabOrMobile ? "200px" : "240px !important",
                                            marginRight: "10px",
                                            backgroundColor: "transparent",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {/* Top SattaMarket Card ------------------------- */}
                                        <div className={`satta-market-card `}>
                                            <LazyLoadImage
                                                className="satta-market-card-img"
                                                src={item?.bazar_image}
                                            />
                                            <div className="bazar-content-container">
                                                <div className="bazar-details">
                                                    <div className="bazar-name">
                                                        {" "}
                                                        {item?.bazar_name}
                                                    </div>
                                                    <div className="bazar-rate">{item?.result}</div>
                                                    <div className="runnig-mssg">
                                                        Running For Tomorrow
                                                    </div>
                                                </div>

                                                <div className="bazar-live-details">
                                                    <div className="tv-icon">
                                                        <TvIcon />
                                                    </div>
                                                    <div className="live-tv">LIVE TV</div>
                                                    <div className="result">RESULT</div>
                                                </div>
                                            </div>
                                            <div className="bazar-open-close-box">
                                                <div className="bazar-open-close-wrapper">
                                                    <div className="bazar-open">
                                                        <div className="bazar-time">
                                                            {formatTime(item?.open_time)}
                                                        </div>
                                                        <div className="bazar-status">OPEN</div>
                                                        <div className="runnig-time">
                                                            {item?.openRemainingTime?.hours || 0}:
                                                            {item?.openRemainingTime?.minutes || 0}:
                                                            {item?.openRemainingTime?.seconds || 0}s
                                                        </div>
                                                    </div>
                                                    <div className="clock-icon">
                                                        <LazyLoadImage
                                                            className="clock-img"
                                                            src={clockIcon}
                                                        />
                                                    </div>
                                                    <div className="bazar-close">
                                                        <div className="bazar-time">
                                                            {" "}
                                                            {formatTime(item?.close_time)}
                                                        </div>
                                                        <div className="bazar-status">Close</div>
                                                        <div className="runnig-time">
                                                            {" "}
                                                            {item?.closeRemainingTime?.hours || 0}:
                                                            {item.closeRemainingTime?.minutes || 0}:
                                                            {item.closeRemainingTime?.seconds || 0}s
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* end of top satta market card ------------------------ */}
                                    </SwiperSlide>
                                </>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default TopSattaMarket;
