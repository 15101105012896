import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../Modals/Login";
import RegImg from "../../Assets/Images/bonus.jpg";
import GeneralTextfield from "../Textfields/GeneralTextfield";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as Add } from "../../Assets/Images/add.svg";
import { ReactComponent as Plus } from "../../Assets/Images/green-victor.svg";
import Loader from "../Loader/Loader";
import { User_Context } from "../../Contexts/User";
import { Game_Context } from "../../Contexts/Games";
import { API_Endpoint } from "../../Configuration/Settings";
import { encryptData } from "../../Utilities/EncryptData";
import { Payment_APIs } from "../../API/Payment_APIs";
import { User_APIs } from "../../API/User_APIs";
import { decryptData } from "../../Utilities/DecryptData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Register.css";

function Register({ lat: latitude, lan: longitude }) {
    let lat = latitude,
        lan = longitude;
    const [userName, setUserName] = useState("");
    const [userNameText, setUserNameText] = useState("");
    const [userNameError, setUserNameError] = useState(false);

    const [userMob, setUserMob] = useState();
    const [userMobText, setUserMobText] = useState("");
    const [userMobError, setUserMobError] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordText, setPasswordText] = useState("");
    const [passwordError, setPasswordError] = useState(false);

    const [promocode, setPromocode] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");

    const [showPromocode, setShowPromocode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [click, setClick] = useState(false);
    const [modalShow4, setModalShow4] = useState(false);
    const { User } = useContext(User_Context);
    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const { subAdminId } = gameState;

    const navigate = useNavigate();

    const navigateToLogin = () => {
        setModalShow4(true);
    };

    const closeLoginModal = () => {
        setModalShow4(false);
    };

    const handleUserName = (e) => {
        const username = e.target.value;
        // const regex = /^[a-zA-Z0-9\s]*$/;
        const regex = /^[a-zA-Z\s]*$/;
        if (regex.test(username) && username.length <= 25) {
            setUserName(username);
        }
        if (userNameError) {
            setUserNameError(false);
            setUserNameText("");
        }
    };

    const handleMobileChange = (e) => {
        const mob = e.target.value;
        if (mob.length <= 10) {
            setUserMob(mob);
        }
        if (userMobError) {
            setUserMobError(false);
            setUserMobText("");
        }
    };

    const handlePassword = (e) => {
        const password = e.target.value;
        if (password.length < 9) {
            setPasswordError(true);
            setPasswordText("Password must be at least 9 characters long");
            setPassword(password);
        } else {
            setPassword(password);
            setPasswordError(false);
            setPasswordText("");
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const requestLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
            (Position) => {
                lat = `${Position.coords.latitude}`;
                lan = `${Position.coords.longitude}`;
            },
            (error) => {
                if (error?.code === 1) {
                    toast.error("Please allow location");
                }
            }
        );
    };
    const handleContinue = () => {
        if (!lan || !lat) {
            toast.error("Please allow location");
            requestLocationPermission();
            return;
        }

        if (!state || !city) {
            getUserAddress();
            return;
        }
        setLoading(true);
        if (!userName) {
            setUserNameError(true);
            setUserNameText("Please enter user name");
            setLoading(false);
        } else if (!userMob) {
            setUserMobError(true);
            setUserMobText("Please enter mobile no");
            setLoading(false);
        } else if (!password) {
            setPasswordError(true);
            setPasswordText("Please enter password");
            setLoading(false);
        } else if (!click) {
            toast.info("Please accept the Terms & Conditions");
            setLoading(false);
        } else {
            let data = {
                city: city,
                subAdminId: subAdminId,
                deviceType: "web",
                mobile: userMob,
                name: userName,
                password: password,
                state: state,
            };
            if (promocode.length > 0) {
                data.referredCode = promocode.toUpperCase();
            }
            User_APIs.Check_User_Existence(data)
                .then((response) => {
                    setLoading(false);
                    toast.success("Otp sent successfully");
                    navigate("/user-register-otp", { state: data });
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error?.response?.data?.message);
                });
        }
    };

    const getUserAddress = () => {
        setLoading(true);
        Payment_APIs.Get_Address(
            {
                lat: lat,
                lng: lan,
            },
            User.token
        )
            .then((response) => {
                const data = decryptData(response.data.data);
                const {
                    state = "",
                    city = "",
                    state_district,
                    addressLine2 = "",
                    city_district = "",
                } = data?.payload || {};
                setState(state || state_district || addressLine2 || city_district);
                setCity(city || city_district || state_district || addressLine2);
                setLoading(false);
            })
            .catch((error) => {
                console.log("================>error", error);
                setLoading(false);
                toast.error(error?.response?.data?.message ?? "");
                if (error?.response?.status === 401) {
                    navigate("/");
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        if (lat !== 0 && lan !== 0 && lat !== "" && lan !== "") {
            getUserAddress();
        }
    }, [lat, lan, User]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="register-user">
                        <span className="register-user-span">SIGN UP</span>
                    </div>

                    <div className="register-img">
                        <img className="reg-user-bg" src={RegImg}></img>
                    </div>

                    <div className="register-textfield-outer">
                        <div className="register-textfield-inner">
                            <div>
                                <GeneralTextfield
                                    fullwidth
                                    size={"large"}
                                    label={"Username"}
                                    type={"text"}
                                    value={userName}
                                    error={userNameError}
                                    helperText={userNameText}
                                    onChange={handleUserName}
                                />
                            </div>
                            <div className="mt-3">
                                <GeneralTextfield
                                    fullwidth
                                    size={"large"}
                                    label={"Mobile No"}
                                    type={"number"}
                                    value={userMob}
                                    error={userMobError}
                                    helperText={userMobText}
                                    onChange={handleMobileChange}
                                />
                            </div>
                            <div className="mt-3">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    type={showPassword ? "text" : "password"}
                                    label="Password"
                                    value={password}
                                    error={passwordError}
                                    helperText={passwordText}
                                    onChange={handlePassword}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="register-outer-svg">
                        <div className="register-inner-svg">
                            <Plus className="register-svg" />
                            <span className="register-svg-span">I have a Referral Code</span>
                            <Add onClick={() => setShowPromocode(true)} className="register-svg" />
                        </div>
                    </div>

                    {showPromocode && (
                        <div className="register-textfield-outer">
                            <div>
                                <div>
                                    <GeneralTextfield
                                        fullwidth
                                        size={"small"}
                                        label={"Promocode"}
                                        type={"text"}
                                        value={promocode}
                                        onChange={(e) => setPromocode(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="register-checkbox">
                        <div>
                            <input
                                type="checkbox"
                                className="register-input-checkbox"
                                checked={click}
                                onClick={() => {
                                    if (click) {
                                        setClick(false);
                                    } else {
                                        setClick(true);
                                    }
                                }}
                            />
                        </div>
                        <div className="register-w">
                            <span>
                                By signing up, I hereby confirm that I am over 18, I read and
                                accepted{" "}
                                <span style={{ color: "green" }}>
                                    the offer agreements for the chosen currency with the applicable
                                    terms and conditions
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="register-outer-btn">
                        <div>
                            <button onClick={handleContinue} className="register-main-btn">
                                Continue
                            </button>
                        </div>
                    </div>

                    <div className="register-account cursor-pointer" onClick={navigateToLogin}>
                        <span>
                            Have an account ? <span style={{ color: "#31469C" }}>Log In</span>
                        </span>
                    </div>

                    <Login show={modalShow4} onHide={closeLoginModal} />
                </>
            )}
        </>
    );
}

export default Register;
