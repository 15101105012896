import React, { useEffect } from "react";
import { Legal_Information_Enums } from "../../Configuration/Enums/Legal_Information";

// Components
import Header from "../../components/AppHeader/NewHeader";

// Styles
import "./Legal.css";

function Legal({ Document }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            {Document === Legal_Information_Enums.AML_Policy && (
                <div className="mb-static-content">
                    <div className="mb-static-content__rightside">
                        <h1>AML Policy</h1>
                        <div className="mb-static-content__content">
                            <div className="content">
                                <p>
                                    <strong>Introduction</strong>
                                </p>
                                <p>
                                    Three Aces Curaçao N.V., is licensed and regulated by the
                                    Governor of Curaçao, to offer remote (online) games over the
                                    internet, under the Remote Gaming Regulations. Under the license
                                    conditions issued by Curacao&nbsp; Authorities, Kuberbets is
                                    required to have in place adequate measures to prevent its
                                    systems from being used for the purposesof money laundering,
                                    terrorist financing or any other criminal activity. Kuberbets is
                                    therefore obliged to follow the provisions contained in the
                                    legal framework namely Proceeds of Crime Act 2002 (Part 7), and
                                    The Money Laundering, Terrorist Financing and Transfer of Funds
                                    (information on the payer) Regulation no. 692 of 26 ofJune of
                                    2017 and any guidance notes issued by the relevant Authorities.
                                </p>
                                <p>
                                    <strong>Objective of the Policy</strong>
                                </p>
                                <p>
                                    Kuberbets.com is fully committed to be constantly vigilant to
                                    prevent money laundering and combat the financing of terrorism
                                    in order to minimize and manage riskssuch as the risks to its
                                    reputational risk, legal risk and regulatory risk. It is also
                                    committed to its social duty to prevent serious crime and not to
                                    allow its systems to be abused in furtherance of these crimes.
                                    The company will endeavor to keep itself updatedwith
                                    developments both at national and international level on any
                                    initiatives to prevent money laundering and the financing of
                                    terrorism. It commits itself to protect, at all times, the
                                    organization and its operations and safeguards its&nbsp;
                                    reputation and all from the threat of money laundering, the
                                    funding of terrorist and other criminal activities.
                                </p>
                                <p>
                                    <strong>Obligations</strong>
                                </p>
                                <ul>
                                    <li>
                                        Appoint one of its senior officers as the designated Money
                                        Laundering Reporting Officer (MLRO) whose responsibilities
                                        will include the duties required by the laws regulations and
                                        guidance notes.
                                    </li>
                                    <li>
                                        All instances of suspected Money Laundering attempts must be
                                        reported to the MLRO, but that suspicion must not be
                                        conveyed to the customer and further actions must await
                                        consents. The MLRO is then&nbsp; responsible for liaising
                                        with, submitting Suspicious Activity Reports and seeking
                                        consents from the responsible legal authority in the
                                        jurisdiction where the transaction was done, when
                                        appropriate. He will also ensure that appropriate registers
                                        are kept for all related reporting.
                                    </li>
                                    <li>
                                        Take reasonable steps to establish the identity of any
                                        person for whom it is proposed to provide its service.
                                    </li>
                                    <li>
                                        Kuberbets will implement a FULL ‘Know Your Customer’ policy.
                                        This will assist in tackling not just Money Laundering
                                        threats but other fraud threats generally. Customers will be
                                        advised at registration and&nbsp; generally on the
                                        supporting non-transactional website that Company will
                                        utilize online verification tools and that in instances
                                        where we are not able to satisfy criteria, ID and address
                                        verification documents will be&nbsp; requested.
                                    </li>
                                    <li>
                                        Keep at all times a secure online list of all registered
                                        Players.
                                    </li>
                                    <li>
                                        Retain identification and transactional documentation as
                                        defined in the laws regulations and guidance notes.
                                    </li>
                                    <li>
                                        The business will also not have any cash element to it,
                                        mitigating risks associated with counterfeit monies.
                                    </li>
                                    <li>
                                        Systems will be operating to record all transactions for an
                                        account. These account histories will be subject to a
                                        program of regular and continual checks against fraud and
                                        social responsibility indicators. Where&nbsp; unusual bet
                                        patterns and winning patterns are detected, these will also
                                        be cross- referenced to call history to ensure that staff
                                        are not colluding with customers or acting illicitly.
                                    </li>
                                    <li>
                                        Kuberbets will also keep a register of those customers that
                                        it identifiesas high risk or politically exposed. Those
                                        accounts will be subject to greater scrutiny and frequency
                                        of checks.
                                    </li>
                                    <li>
                                        In general, where illegal or inappropriate behavior by staff
                                        is identified, investigatory and disciplinary procedures
                                        will follow those outlined in staff contracts as required by
                                        employment law directives and the need to&nbsp; use in
                                        greater propensity, measures such as suspension when for
                                        example transactional fraud is suspected and those
                                        activities form a standard part of an employee’s job
                                        description.
                                    </li>
                                    <li>
                                        All transactions carried out on our site may be controlled
                                        to avoid money laundering or terrorist financing activities.
                                        Suspicious transactions will be reported to the relevant
                                        authority, depending on the jurisdiction&nbsp; governing the
                                        transaction.
                                    </li>
                                    <li>
                                        If our risk and fraud department suspects fraud, it may
                                        require the player to provide appropriate documentation. If
                                        there is enough evidence of fraudulent behavior (eg bank
                                        repudiation, account operated by third&nbsp; parties, use of
                                        forged documents, fraud of means of payment, etc.), the
                                        account will be suspended until the situation is cleared.
                                        The suspension shall be communicated to the relevant
                                        authority together with the&nbsp; respective grounds
                                        (evidence or evidence collected) within 24 hours and to the
                                        authorities responsible for criminal proceedings. Parallel
                                        to the suspension, Kuberbets may proceed to the
                                        immobilization, as a&nbsp; precautionary measure, of the
                                        balance of the player account, until the decision of the
                                        competent authorities.
                                    </li>
                                    <li>
                                        Provide initial and ongoing training to all relevant staff,
                                        including, but not limited to:
                                    </li>
                                    <li>
                                        Staff personal responsibilities;
                                        <ol>
                                            <li>Procedures in respect of identifying Players;</li>
                                            <li>Monitoring Player activity;</li>
                                            <li>Record-keeping;</li>
                                            <li>Reporting any unusual/suspicious transactions;</li>
                                            <li>Unusual Betting Patterns;</li>
                                            <li>
                                                Suspicious deposit and withdrawal patterns (size and
                                                frequency);
                                            </li>
                                            <li>Reliability of Card Data;</li>
                                            <li>
                                                Customer verification issues and Identity Theft;
                                            </li>
                                            <li>Account linkage/multiple accounting;</li>
                                            <li>Account for High Risk Jurisdictions;</li>
                                            <li>
                                                Client due diligence (CDD), including enhanced
                                                requirements for high risk clients, which includes
                                                PEPs;
                                                <ul>
                                                    <li>
                                                        Ensure that this policy is developed and
                                                        maintained in line with evolving statutory
                                                        and regulatory obligation and advice from
                                                        the relevant authorities.
                                                    </li>
                                                    <li>
                                                        Examine with special attention, and to the
                                                        extent possible, the background and purpose
                                                        of any complex or large transactions andany
                                                        transactions which are particularly likely,
                                                        by their nature, to be related to money
                                                        laundering or the funding of terrorism.
                                                    </li>
                                                    <li>
                                                        Kuberbets may terminate Accounts with
                                                        immediate effect and retain all account
                                                        funds in the event of a credible suspicion
                                                        of fraudulent activity including, but not
                                                        limited to, thesupply of fraudulent, forged,
                                                        altered or stolen personal data and,still,
                                                        in the case of multiple account
                                                        registration.
                                                    </li>
                                                    <li>
                                                        Report any suspicion or knowledge of money
                                                        laundering of terrorist financing to the
                                                        Financial Intelligence Analysis Unit (FIAU)
                                                        set up by law as the Government Agency
                                                        responsible for the collection,&nbsp;
                                                        collation, processing, analysis and
                                                        dissemination of information with a view to
                                                        prevent money laundering and combat the
                                                        funding of terrorism.
                                                    </li>
                                                    <li>
                                                        Suspicious Activity in this case is being
                                                        referred to as suspicious transactions,
                                                        extreme player profiles, when deposits are
                                                        not matching up amongst other elements.
                                                        Other concrete examples of how we identify
                                                        players who require our team to undertake a
                                                        risk monitoring approach of our customers
                                                        and when to specifically carry out enhanced
                                                        due diligence checks on the Player Profiles
                                                        can be further required:
                                                        <ol>
                                                            <li>Passport or ID card.</li>
                                                            <li>Utility bill.</li>
                                                            <li>Bank statement.</li>
                                                            <li>Other proof of identity.</li>
                                                        </ol>
                                                    </li>
                                                    <li>
                                                        The Enhanced Due Diligence Checks are
                                                        subject to players’ profile and the amount
                                                        of Risk they pose to us. Only when we
                                                        determine some of the above points or a
                                                        combination of a few will we flag the&nbsp;
                                                        customer/customers in question and conduct
                                                        risk monitoring. This will include checks of
                                                        where the customer works, value of house,
                                                        where the customer lives and checks to see
                                                        whether the value of the house is logical
                                                        compared to the customers spending.
                                                    </li>
                                                    <li>
                                                        Within that framework, Suspicious Activity
                                                        Reports (SARs) are an imposed requirement.
                                                        Kuberbets ensures that any employee reports
                                                        to the Risk Team where they have groundsfor
                                                        knowledge or&nbsp; suspicion that a person
                                                        or customer is engagedin ML or terrorist
                                                        financing. Any employee failing to so do is
                                                        open to criminal prosecution.
                                                    </li>
                                                    <li>
                                                        Escalations of SARs should be done in a
                                                        confidential, discreet manner, in a
                                                        handwritten form and not via email so as to
                                                        ensure maximum anonymity.
                                                    </li>
                                                    <li>
                                                        An employee must not, under any
                                                        circumstances, disclose or discuss any AML
                                                        concern with the person or persons subject
                                                        to an investigation, or any other person for
                                                        that matter. Disclosure (also known as
                                                        "tipping off") is strictly prohibited and
                                                        carries with it very serious legal
                                                        penalties.
                                                    </li>
                                                    <li>
                                                        Furthermore, and in order to keep ourselves
                                                        protected as much aspossible, no remark
                                                        should ever be left on an account that would
                                                        give any indication that ML is suspected, a
                                                        player being entitled, at&nbsp; any point in
                                                        time, to request the full notes/remarks on
                                                        their account.
                                                    </li>
                                                    <li>
                                                        Cooperate with all relevant administrative,
                                                        enforcement and judicial authorities in
                                                        their endeavor to prevent and detect
                                                        criminal activity. Moreover, the company
                                                        shall:
                                                    </li>
                                                    <li>
                                                        Not accept to open anonymous Accounts or
                                                        Accounts in fictitious names such that the
                                                        true beneficial owner is not known.
                                                    </li>
                                                    <li>Not accept cash from Players.</li>
                                                    <li>
                                                        Funds may be received from Players only by
                                                        any of the following methods: credit cards,
                                                        debit cards, electronic transfer, wire
                                                        transfer cheques and any other method
                                                        approved by the Regulator.
                                                    </li>
                                                    <li>
                                                        Not register a Player who is under eighteen
                                                        (18) years of age.
                                                    </li>
                                                    <li>
                                                        Only register a single account in the name
                                                        of a particular person: multi-account
                                                        practices are strictly prohibited.
                                                    </li>
                                                    <li>
                                                        Transfer payments of winnings or refunds
                                                        back to the same route from where the funds
                                                        originated, where possible.
                                                    </li>
                                                    <li>
                                                        Not accept a wager unless a User Account has
                                                        been established in the name of the Player
                                                        and there are adequate funds in the Account
                                                        to cover the amount of the wager.
                                                    </li>
                                                    <li>
                                                        Not accept a wager unless the funds
                                                        necessary to cover the amount of the wager
                                                        are provided in an approved way.
                                                    </li>
                                                    <li>
                                                        Not accept Players residing or playing from
                                                        non-reputable jurisdictions.
                                                    </li>
                                                    <li>
                                                        Not make a payment in excess of three
                                                        hundred EUR (€ 300) or cumulative out of a
                                                        User Account to a Player until the Player’s
                                                        identity, age, and place of residence have
                                                        been verified.
                                                    </li>
                                                    <li>
                                                        If no transaction has been recorded on a
                                                        User Account for thirty months, close the
                                                        User Account and remit the balance in that
                                                        account to the Player, or if the Player
                                                        cannot be satisfactorily located, to the
                                                        regulator.
                                                    </li>
                                                    <li>
                                                        Where it deems necessary, verify
                                                        creditworthiness of the Player with third
                                                        parties who previously provided any
                                                        information on the Player.
                                                    </li>
                                                    <li>
                                                        If it becomes aware that a person has
                                                        provided false information when providing
                                                        due diligence documents, not register such
                                                        person. Where that person has already been
                                                        registered, the company shall&nbsp;
                                                        immediately cancel that person’s
                                                        registration as a Player with the company.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                                <p>
                                    The customer declares and expressly guarantees that all the
                                    amounts deposited and used to participate in Kuberbets remote
                                    online games come from legitimate sources and are not connected
                                    with any fraudulent&nbsp; activity, money laundering or
                                    activities that are considered to be illicit by law such as
                                    money laundering or funding of terrorism. Pursuant to the
                                    current law, and regulations which may be issued at any time by
                                    the regulator about the prevention of money laundering and the
                                    funding of terrorism, Kuberbets reserves the right to take any
                                    steps is considers necessary to comply with these requirements.
                                    The users cannot use&nbsp; loans to participate in Kuberbets
                                    website. Kuberbets will not grant the user any kind of loan or
                                    mechanism that allows them to take out loans or transfer funds
                                    between players, at any time. Kuberbets reserves the right to
                                    supervise any transfer of funds that are deposited or withdrawn
                                    by any user and to report any user information to the regulatory
                                    or criminal authorities and/or payment service providers.&nbsp;
                                    Kuberbets keep the players' money in an account which is legally
                                    separate from Kuberbets own funds. And also reserves the right
                                    to retain all a customer’s funds at its own criteria if it
                                    considers that they&nbsp; derive directly or are related with
                                    illicit or fraudulent activities. After confirmation of the
                                    deposit, the customer can use the deposited funds for bets. The
                                    deposits are credited to the player’s account as soon as the
                                    bank&nbsp; transfer is successfully validated. Deposits and
                                    withdrawals are only made in Brazilian Reais (R$), US Dollars
                                    (US$), and Euro (€). The minimum deposit amount is BRL 60, USD
                                    10, or EUR 10. It is the customer’s entire responsibility to
                                    check whether their bank imposes any fees on these transfers.
                                    The client declares that is the holder of the payment account
                                    provided to Kuberbets and the payment methods used are&nbsp;
                                    associated with that same account, or if not possible, associate
                                    it to an account owned by the client. The totalbalance of your
                                    player account includes Casino Balance, Real Sportsbook Balance,
                                    Casino Bonus Balance and Sportsbook Bonus Balance. The existence
                                    of two wallets (casino and sports betting) does not change the
                                    behavior of your player account when making an withdrawal
                                    request. Consequently, making a request of&nbsp; withdraw,
                                    regardless the balance to be withdrawn is transferred from your
                                    casino wallet, it may result in the loss or cancellation of
                                    sports betting bonuses. The withdrawals must be made by the same
                                    method as that&nbsp; used for the deposits whenever the method
                                    used allows it. If the deposit method does not allow
                                    withdrawals, these must be made by bank transfer to the account
                                    given by the user when they registered or to the&nbsp; payment
                                    account they indicatedand hold and that is chosen for this
                                    purpose. Withdrawals may only be processed from your Sports Cash
                                    Account. Any withdrawals from your Casino Cash Account can only
                                    be made by&nbsp; first transferring your withdrawal amount to
                                    your Sports Cash Account and then requesting the withdrawal be
                                    processed from the Sports Cash Account. Please note however that
                                    any transfer of funds from the Casino&nbsp; Cash Account to the
                                    Sports Cash Account will be considered a Withdrawal from the
                                    Casino Cash Account and may consequently result in the
                                    forfeiture or voidance of Casino product bonuses and
                                    promotions.&nbsp; Kuberbets reviews players spend and game play
                                    to check for suspicious activity. Before any withdrawal is
                                    processed the following procedures are carried out:
                                </p>
                                <ol>
                                    <li>
                                        The customers deposit history is reviewed to confirm that no
                                        suspicious payments have been made to the customer’s
                                        account. The frequency of deposits and the sum of deposits
                                        are reviewed to ensure they are&nbsp; within normal range
                                        for the customer based on his depositing history and the
                                        general depositing range throughout our network.
                                    </li>
                                    <li>
                                        The customer’s turnover is reviewed to ensure that they have
                                        played in the casino and are not using Kuberbets as a method
                                        to move money.
                                    </li>
                                    <li>
                                        When possible, funds must always be refunded back to the
                                        original payment method used by the player to make a
                                        deposit. In order to fulfil the legal requirements your
                                        identity must be verified by
                                        <br />
                                        Kuberbets. For this purpose, at the first time of the
                                        withdrawal request you should send us an email to
                                        support@Kuberbets.co with at least the followingdocuments:
                                    </li>
                                    <li>Document of your identity with photo and date of birth;</li>
                                    <li>Proof of address;</li>
                                    <li>Proof of ownership of the account;</li>
                                    <li>
                                        Whenever you pretend to change your payment’s account you
                                        must send a new proof ofthe ownership of the new account.
                                        Without that your payment won’t be processed. Your Player
                                        Account shall not be used&nbsp; or treated as a bank
                                        account. In case of deposits or withdrawals without gaming
                                        activity, or activity using reduced proportions of initial
                                        deposits, Kuberbets reserves the right to request
                                        explanations before&nbsp; transferring the withdrawal
                                        request in question. Your withdrawal request will be
                                        cancelled within 72 hours from the date of your order if you
                                        don’t give to Kuberbets the necessary information to
                                        allow&nbsp; verification of your identity. Any withdrawal is
                                        free.
                                    </li>
                                </ol>
                                <p>
                                    <strong>Politically exposed person</strong>
                                </p>
                                <p>
                                    European guidelines regarding “The Prevention of Laundering the
                                    Proceeds from Criminal Activity (Money Laundering) and Terrorism
                                    Financing” entered into effect, changing the definition of
                                    politically exposed person. In&nbsp; order to minimize
                                    corruption and money laundering risks, Kuberbets is obliged by
                                    law to collect data and identify which of the clients / clients’
                                    beneficiaries should be considered as politically exposed
                                    persons, their&nbsp; family members or close associates.
                                    According to the law “On the Prevention of Laundering the
                                    Proceeds from Criminal Activity (Money Laundering)and Terrorism
                                    Financing” the following person shall be deemed a&nbsp;
                                    politically exposed person (hereinafter referred to as PEP): a
                                    person who holds or has held a prominent public position in a
                                    country of the European Union or European Economic Area or a
                                    third country, including senior- level state official, head of a
                                    state administrative unit (municipality), head of the
                                    government, minister (deputy minister or assistant deputy
                                    minister if there is such position in a relevant country),
                                    secretary of state or&nbsp; another highlevel official in the
                                    government or state administrative unit (municipality), member
                                    of parliament or similar legislative body, member of the
                                    management body (board) of a political party, judge of&nbsp;
                                    constitutional court, supreme court, or another level court
                                    (member of judicial body), member of the council or board of a
                                    supreme audit institution, member of the council or the board of
                                    a central bank, ambassador,&nbsp; charge d’affaires, armed
                                    forces higher officer, member of the council or board of a state
                                    capital company, head (director, deputy director) of
                                    international organization and member of the board or a person
                                    who holds an&nbsp; equal position at such organization. The
                                    following person shall be deemed a family member of a
                                    politically exposed person:
                                </p>
                                <ol>
                                    <li>
                                        a spouse or person considered to be the equivalent to a
                                        spouse. A person may be deemed the equivalent to a spouse
                                        only provided that he / she has such status under the laws
                                        of the respective country;
                                    </li>
                                    <li>
                                        a child or a child of a politically exposed person’s spouse
                                        or person considered to be the equivalent to a spouse, his /
                                        her spouse or person considered to be the equivalent to a
                                        spouse;
                                    </li>
                                    <li>a parent, grandparent or grandchild;</li>
                                    <li>a brother or sister.</li>
                                </ol>
                                <p>
                                    A close associate of a politically exposed person is an
                                    individual publicly known to maintain business or other close
                                    relationship with a politically exposed person, or to be a
                                    shareholder in one and the same commercial&nbsp; company with a
                                    PEP, and also an individual who is the sole owner of such legal
                                    entity which is known to be actually established for the benefit
                                    of a PEP.
                                </p>
                                <p>
                                    <strong>Forbidden Countries</strong>
                                </p>
                                <p>
                                    The following countries are restricted from participation with
                                    Kuberbets:
                                </p>
                                <ol>
                                    <li>
                                        United States of America and its territories, Aruba,
                                        Bonaire, Curacao, France, The Netherlands, Saba, Statia, St.
                                        Maarten, Singapore.
                                    </li>
                                    <li>
                                        Iran, North Korea, Albania, Barbados, Botswana, Cambodia,
                                        Jamaica, Haiti, Mauritius, Myanmar (Burma), Nicaragua,
                                        Pakistan, Panama, South Sudan, Syria, Yemen, and Zimbabwe.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {Document === Legal_Information_Enums.Privacy_Policy && (
                <div className="mb-static-content">
                    <div className="mb-static-content__rightside">
                        <h1>KYC and Privacy Policy for Kuberbets.co</h1>
                        <div className="mb-static-content__content">
                            <div className="content">
                                <p>
                                    The Company adheres to and complies with “Know your customer”
                                    principles, which aim to prevent financial crime and money
                                    laundering through client identification and due diligence.
                                </p>
                                <p>
                                    The Company reserves the right, at any time, to ask for any KYC
                                    documentation it deems necessary to determine the identity and
                                    location of a user in Kuberbets.co. We reserve the right to
                                    restrict the service, payment, or withdrawal until identity is
                                    sufficiently determined, or for any other reason in our sole
                                    discretion based on the legal framework.
                                </p>
                                <p>
                                    We take a risk-based approach and perform strict due diligence
                                    checks and ongoing monitoring of all clients, customers, and
                                    transactions. As per the money laundering regulations, we
                                    utilize three stages of due diligence checks, depending on the
                                    risk, transaction, and customer type.
                                </p>
                                <p>
                                    <strong>SDD —</strong> simplified due diligence is used in
                                    instances of extremely low-risk transactions that do not meet
                                    the required thresholds
                                </p>
                                <p>
                                    <strong>CDD —</strong> customer due diligence is the standard
                                    for due diligence checks, used in most cases for verification
                                    and identification
                                </p>
                                <p>
                                    <strong>EDD —</strong> Enhanced Due Diligence is used for
                                    high-risk customers, large transactions or special cases.
                                </p>
                                <p>
                                    {" "}
                                    Separately and in addition to the above ,when a user makes an
                                    aggregate lifetime total of deposits exceeding EUR 5,000 or
                                    requests a withdrawal of any amount inside the Kuberbets.co, or
                                    attempts to or completes a transaction that is deemed
                                    suspicious, then it is compulsory for them to complete the full
                                    KYC process.
                                </p>
                                <p>
                                    During this process, the user will have to input some basic
                                    details about themselves and then upload
                                </p>

                                <ol>
                                    <li>
                                        A copy of Government Issued Photo ID (in some cases front
                                        and back depending on the ID document)
                                    </li>
                                    <li>A selfie of themselves holding the ID doc</li>
                                    <li>A bank statement/Utility Bill</li>
                                </ol>

                                <h2>
                                    <strong>Guideline for the “KYC Process”</strong>
                                </h2>
                                <ol>
                                    <li>
                                        Proof of ID
                                        <ol type="a">
                                            <li>Signature Is there</li>
                                            <li>
                                                Country is not one of the following Restricted
                                                Countries: Austria <br />
                                                France and it’s territories
                                                <br />
                                                Germany
                                                <br />
                                                Netherlands and it’s territories
                                                <br />
                                                Spain
                                                <br />
                                                Union of Comoros United Kingdom
                                                <br />
                                                USA and it’s territories
                                                <br />
                                                All FATF Blacklisted countries,
                                                <br />
                                                any other jurisdictions deemed prohibited by Anjouan
                                                Offshore Financial Authority.
                                            </li>
                                            <li>Full Name matches client’s name </li>
                                            <li>Document does not expire in the next 3 months</li>
                                            <li>Owner is over 18 years of age</li>
                                        </ol>
                                    </li>
                                    <li>
                                        Proof of Residence
                                        <ol type="a">
                                            <li>Bank Statement or Utility Bill</li>
                                            <li>
                                                Country is not one of the following Restricted
                                                Countries: Austria <br />
                                                France and it’s territories
                                                <br />
                                                Germany
                                                <br />
                                                Netherlands and it’s territories
                                                <br />
                                                Spain
                                                <br />
                                                Union of Comoros United Kingdom
                                                <br />
                                                USA and it’s territories
                                                <br />
                                                All FATF Blacklisted countries,
                                                <br />
                                                any other jurisdictions deemed prohibited by Anjouan
                                                Offshore Financial Authority.
                                            </li>
                                            <li>
                                                Full Name matches client’s name and is same as in
                                                proof of ID.
                                            </li>
                                            <li>Date of Issue: In the last 3 months</li>
                                        </ol>
                                    </li>
                                    <li>
                                        Selfie with ID
                                        <ol type="a">
                                            <li>Holder is the same as in the ID document above</li>
                                            <li>
                                                ID document is the same as in “1”. Make sure
                                                photo/ID number is the same
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <h2>
                                    <strong>Notes on the “KYC Process”</strong>
                                </h2>
                                <ol>
                                    <li>
                                        When the KYC process is unsuccessful then the reason is
                                        documented and a support ticket is created in the system.
                                        The ticket number along with an explanation is communicated
                                        back to the user.
                                    </li>
                                    <li>
                                        Once all proper documents are in our possession then the
                                        account gets approved.
                                    </li>
                                </ol>
                                {/* <p>
									If you contact Kuberbets.co directly, we may receive additional
									information about you such as your name, email address, phone
									number, the contents of the message and/or attachments you may
									send us, and any other information you may choose to provide.
								</p>
								<p>
									When you register for an Account, we may ask for your contact
									information, including items such as name, company name,
									address, email address, and telephone number.
								</p>
								<p>
									<strong>How we use your information</strong>
								</p>
								<p>
									We use the information we collect in various ways, including to:
								</p>
								<ul>
									<li>Provide, operate, and maintain our website.</li>
									<li>Improve, personalize, and expand our website.</li>
									<li>Understand and analyze how you use our website.</li>
									<li>
										Develop new products, services, features, and functionality.
									</li>
									<li>
										Communicate with you, either directly or through one of our
										partners, including for customer service, to provide you with
										updates and other information relating to the website, and for
										marketing and promotional purposes.
									</li>
									<li>Send you emails.</li>
									<li>Find and prevent fraud.</li>
								</ul>
								<p>
									<strong>Site Cookies and Web Beacons</strong>
								</p>
								<p>
									Like any other website, Kuberbets.co website use 'cookies'. These
									cookies are used to store information including visitors'
									preferences, and the pages on the website that the visitor
									accessed or visited. The information is used to optimize the
									users' experience by customizing our web page content based on
									visitors' browser type and/or other information.
								</p>
								<p>
									<strong>Third Party Privacy Policies</strong>
								</p>
								<p>
									Kuberbets.co's Privacy Policy does not apply to other advertisers
									or websites. Thus, we are advising you to consult the respective
									Privacy Policies of these third-party ad servers for more
									detailed information. It may include their practices and
									instructions about how to opt-out of certain options.
								</p>
								<p>
									You can choose to disable cookies through your individual
									browser options. To know more detailed information about cookie
									management with specific web browsers, it can be found at the
									browsers' respective websites.
								</p>
								<p>
									<strong>GDPR Data Protection Rights</strong>
								</p>
								<p>
									We would like to make sure you are fully aware of all of your
									data protection rights. Every user is entitled to the following:
								</p>
								<ul>
									<li>
										The right to access – You have the right to request copies of
										your personal data. We may charge you a small fee for this
										service.
									</li>
									<li>
										The right to rectification – You have the right to request
										that we correct any information you believe is inaccurate. You
										also have the right to request that we complete the
										information you believe is incomplete.
									</li>
									<li>
										The right to erasure – You have the right to request that we
										erase your personal data, under certain conditions.
									</li>
									<li>
										The right to restrict processing – You have the right to
										request that we restrict the processing of your personal data,
										under certain conditions.
									</li>
									<li>
										The right to object to processing – You have the right to
										object to our processing of your personal data, under certain
										conditions.
									</li>
									<li>
										The right to data portability – You have the right to request
										that we transfer the data that we have collected to another
										organization, or directly to you, under certain conditions.If
										you make a request, we have one month to respond to you. If
										you would like to exercise any of these rights, please contact
										us.
									</li>
								</ul>
								<p>
									<strong>Children's Information</strong>
								</p>
								<p>
									Another part of Kuberbets.co priority is adding protection for
									children while using the internet. We encourage parents and
									guardians to observe, participate in, and/or monitor and guide
									their online activity.
								</p>
								<p>
									Kuberbets.co does not knowingly collect any Personal Identifiable
									Information from children under the age of 18. If you think that
									your child provided this kind of information on our website, we
									strongly encourage you to contact us immediately and we will do
									our best efforts to promptly remove such information from our
									records.
								</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {Document === Legal_Information_Enums.Responsible_Gaming && (
                <div className="mb-static-content">
                    <div className="mb-static-content__rightside">
                        <h1>Responsible Gaming</h1>
                        <div className="mb-static-content__content">
                            <div className="content">
                                {/* <ol>
									<li>
										<strong>Responsibility to Our Players</strong>
									</li>
								</ol>
								<p>
									Kuberbets.co values its players and is proud to be chosen as
									sports book and casino provider.
								</p>
								<p>
									The company’s responsible gaming program focuses on the social
									impact of gambling upholding the following three conventions to:
								</p>
								<ul>
									<li>
										<ul>
											<li>Prevent problem gambling</li>
											<li>Prevent underage gambling</li>
											<li>Ensure gambling is conducted in a fair and open way</li>
										</ul>
									</li>
								</ul> */}
                                <p>
                                    Kuberbets.co is here to provide an excellent and enjoyable
                                    gaming experience and recognize our responsibility in preventing
                                    problematic activity. We advise all players to take into account
                                    the following, and not game irresponsibly:
                                    <ul>
                                        <li>Play for entertainment, not to make money.</li>
                                        <li>Avoid chasing losses.</li>
                                        <li>Establish limits for yourself.</li>
                                        <li>
                                            Do not let gambling interfere with your daily
                                            responsibilities.
                                        </li>
                                        <li>Never gamble unless you can cover losses.</li>
                                        <li>Take breaks.</li>
                                    </ul>
                                </p>

                                {/* <p>
									<strong>
										1.1. Warning signs of problem gambling can include:
									</strong>
								</p> */}
                                {/* <ul>
									<li>
										<ul>
											<li>
												Losing time from work, school, or family due to betting
											</li>
											<li>
												Repeatedly failed in attempts to stop or to control
												betting
											</li>
											<li>Borrowing money to gamble or pay betting debts</li>
											<li>Betting to escape worry or trouble</li>
											<li>
												Neglecting the care of one’s self or family in order to
												bet
											</li>
											<li>
												Lying about the amount of time or money spent on betting
											</li>
											<li>
												Selling or pawning personal possessions to get money to
												bet
											</li>
											<li>
												Having feelings of hopelessness, depression, or suicide as
												a result of betting
											</li>
										</ul>
									</li>
								</ul> */}
                                <p>
                                    See the below questions. If your answer to the majority of them
                                    is “YES”, we advise you take action to prevent gambling from
                                    negatively impacting your life:
                                    <ul>
                                        <li>Does gambling affect your work?</li>
                                        <li>Has gambling caused arguments with family/friends?</li>
                                        <li>Do you always return to win back your losses?</li>
                                        <li>Have you borrowed money to gamble?</li>
                                        <li>Do you see gambling as a source of income?</li>
                                        <li>Do you find it difficult to limit your gambling?</li>
                                    </ul>
                                </p>
                                {/* <p>
									&nbsp;&nbsp;&nbsp; <strong>2. Limit Setting</strong>
								</p> */}
                                {/* <p>
									Kuberbets.co empowers its players by offering several
									jurisdictionally based limit options to enjoy their gaming
									experience responsibly
								</p>
								<p>
									Each of the following responsible gaming options are available
									for players. Players can use as many of the available options as
									they choose.
								</p>
								<p>
									<strong>2.1. Deposit Limit</strong> – Ability to set a maximum
									deposit limit for a selected time frame: daily, weekly, or
									monthly.
								</p>
								<p>
									<strong>2.2. Spending Limit</strong> – Set a maximum bet spend
									for a selected time frame: daily, weekly, or monthly.
								</p>
								<p>
									<strong>2.3.Daily Time Limit</strong> – Set the maximum time
									(measured in hours) that can be spent on an internet gaming
									system.
								</p>
								<p>
									<strong>2.4.Cool Off Time Limit</strong> – Select when to take a
									break from a personal account from 3-30 days.
								</p> */}
                                <p>
                                    <strong>What to do?</strong>
                                </p>
                                <p>
                                    Listed below are reputed organizations committed to helping
                                    those who struggle with gambling problems, and can be contacted
                                    at any time:
                                    <ul>
                                        <li>
                                            <a href="https://www.gamblersanonymous.org/ga/">
                                                Gamblers Anonymous
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.gamblingtherapy.org/">
                                                Gambling Therapy
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.gamcare.org.uk/">GamCare</a>
                                        </li>
                                    </ul>
                                </p>
                                {/* <p>
									Kuberbets.co will verify the player’s identity using a Know Your
									Customer (KYC) process. If data is validated and the customer is
									confirmed to not be on a self-exclusion list and is over the
									legal age to gamble in that jurisdiction, the account set up is
									approved. If the KYC process fails to validate the customer, an
									alert message is sent to Kuberbets.co’s customer service team who
									will contact the requesting customer to provide the necessary
									information to validate identity, etcetera.
								</p> */}
                                <p>
                                    {/* <strong>1. </strong> */}
                                    <strong>How we can help</strong>
                                </p>
                                <p>
                                    We advise all players who are concerned about their gambling
                                    behavior to take a break by excluding themselves from their
                                    gaming account. Self-exclusion will lock your account for a
                                    minimum of 6 months and no promotional material will be sent.
                                    Contact our experienced Customer Support team at any time to
                                    request this and they will kindly assist you. A 7 day cooling
                                    off period is also available. We recommend that you contact all
                                    other gambling sites where you have an account and request
                                    self-exclusion there also.
                                </p>
                                <p>
                                    {/* <strong>2. </strong> */}
                                    <strong>Underage gambling</strong>
                                </p>
                                <p>
                                    Players must be of legal gambling age in their jurisdiction (at
                                    least 18+) in order to play at Kuberbets.co. It is their
                                    responsibility to be aware of the age restriction where they
                                    reside and play, and to confirm their legitimacy when creating
                                    an account at Kuberbets.co. We also advise parents to do the
                                    following:
                                    <ul>
                                        <li>Password protect computer, mobile, and/or tablet</li>
                                        <li>
                                            Do not leave device unattended when logged into your
                                            account.
                                        </li>
                                        <li>
                                            Make sure all account details and credit cards are
                                            inaccessible to children.
                                        </li>
                                        <li>
                                            Do not save passwords on your computer, write them down
                                            and keep somewhere out of reach.
                                        </li>
                                        <li>
                                            Download filtering software (e.g.
                                            <a href="https://www.netnanny.com/">Net Nanny</a>) to
                                            prevent minors from accessing inappropriate sites.
                                        </li>
                                    </ul>
                                </p>
                                {/* <p>
									<strong>3. </strong>
									<strong>
										Kuberbets.co suggests considering downloading computer
										safeguards:
									</strong>
								</p>
								<p>
									While content control tools may have their limitations, software
									is available that may block access to online gambling sites.
								</p>
								<p>
									<strong>4. Warning signs that kids may be gambling:</strong>
								</p>
								<p>
									Concerned adults must always be aware of the children under
									their responsibility. It’s important to pay attention to the
									following signs:
								</p>
								<ul>
									<li>
										<ul>
											<li>Kids who have friends who gamble regularly.</li>
											<li>
												Kids obsessed with the results of professional sports.
											</li>
											<li>
												Kids that lie or are secretive about gambling activities.
											</li>
											<li>
												Kids that borrow or take money from others to gamble.
											</li>
											<li>Kids that take money or possessions from the house.</li>
											<li>
												Kids that have lost interest in social activities and
												present general dissatisfaction.
											</li>
											<li>
												Kids that show unjustified euphoria alternating with
												moments of sadness and loneliness.
											</li>
											<li>Drop in kids’ school performance.</li>
											<li>
												Kids that display signs of apathy or nervousness when they
												cannot be in front of a computer.
											</li>
										</ul>
									</li>
								</ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {Document === Legal_Information_Enums.Terms_and_Conditions && (
                <div className="mb-static-content">
                    <div className="mb-static-content__rightside">
                        <h1>Terms And Conditions</h1>
                        <div className="mb-static-content__content">
                            <div className="content">
                                <p className="MsoNormal" align="left">
                                    <strong>
                                        <span>
                                            Description: Initial Terms and Conditions replacing
                                            general rules{" "}
                                        </span>
                                    </strong>
                                </p>
                                <p className="MsoNormal" align="left">
                                    <strong>
                                        <span>1.Introduction </span>
                                    </strong>
                                </p>
                                <p className="MsoNormal">
                                    These terms and conditions and the documents referred to below
                                    (the "Terms") apply to the use of the current website (the
                                    "Website") and its related or connected services (collectively,
                                    the "Service").
                                </p>
                                <p className="MsoNormal">
                                    You should carefully review these Terms as they contain
                                    important information concerning your rights and obligations
                                    concerning the use of the Website and form a binding legal
                                    agreement between you - our customer (the "Customer"), and us.
                                    By using this Website and/or accessing the Service, you, whether
                                    you are a guest or a registered user with an account
                                    (“Account”), agree to be bound by these Terms, together with any
                                    amendments, which may be published from time to time. If you do
                                    not accept these Terms, you should refrain from accessing the
                                    Service and using the Website.
                                </p>
                                <p className="MsoNormal">
                                    The Service is owned by Magicsystem Ltd, a limited liability
                                    company with registration number 152581, with registered address
                                    9 Barrack Road, Belize City, Belize., licensed in the State of
                                    Anjouan under the Computer Gaming Licensing Act 007 of 2005..
                                </p>
                                {/* <p className="MsoNormal">
									The Service is supplied by Three Aces Curaçao N.V. a limited
									liability company registered in Curacao with company
									registration number 155000, with registered address at
									Heelsumstraat 51 Curaçao.
								</p>
								<p className="MsoNormal">
									Three Aces Curaçao N.V. is licensed in Curaçao under the License
									8048-JAZ2020-078 for the provision of sports betting and casino.
									Three Aces Curaçao N.V. will only communicate with Customers by
									email to their registered email address (the "Registered Email
									Address") as provided when opening your Player’s account:
									Communication from Kuberbets.co will be issued through the
									following:
								</p>
								<p className="MsoNormal">Mail only: info@Kuberbets.co</p> */}
                                <h1>2. General Terms</h1>
                                <p className="MsoNormal">
                                    We reserve the right to revise and amend the Terms (including
                                    any documents referred to and linked to below) at any time. You
                                    should visit this page periodically to review the Terms and
                                    Conditions. Amendments will be binding and effective immediately
                                    upon publication on this Website. If you object to any such
                                    changes, you must immediately stop using the Service. Your
                                    continued use of the Website following such publication will
                                    indicate your agreement to be bound by the Terms as amended. Any
                                    bets not settled prior to the changed Terms taking effect will
                                    be subject to the pre-existing Terms.
                                </p>
                                {/* <p className="MsoNormal" align="left">
									If at any time you are in any doubt about how to place bets or
									otherwise use the Service, you should refer back to these Terms
									or contact our customer service department (Customer Service
									Department) at info@Kuberbets.co
								</p>
								<p className="MsoNormal" align="left">
									<span>&nbsp;</span>
									<span>&nbsp;</span>
								</p> */}
                                <h1>3. Your Obligations</h1>
                                <p>
                                    You acknowledge that at all times when accessing the Website and
                                    using the Service:
                                </p>
                                <p className="MsoNormal" align="left">
                                    3.1.<span> &nbsp;&nbsp;&nbsp;</span>You are is over 18, or the
                                    legal age at which gambling, or gaming activities are allowed
                                    under the law or jurisdiction that applies to you. We reserve
                                    the right to request proof of age documents from you at any
                                    time.
                                </p>
                                {/* <p className="MsoNormal">
									<span>
										i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									You are over 18 years of age (or over the age of majority as
									stipulated in the laws of the jurisdiction applicable to you)
									and can enter into a binding legal agreement with us.
								</p>
								<p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									You are in a country where it is lawful to place bets on the
									Service (if in doubt, you should seek local legal advice). It is
									your responsibility to ensure that your use of the service is
									legal.
								</p>
								<p className="MsoNormal">
									<span>
										iii)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									You are not a resident of the following countries:
								</p>
								<p className="MsoNormal">
									<span>
										a)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									the United States of America and its territories.
								</p>
								<p className="MsoNormal">
									<span>
										b)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									the French Republic and its territories.
								</p>
								<p className="MsoNormal">
									<span>
										c)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Netherlands (including Curacao and other countries and
									territories that form part of the Kingdom of Netherlands).
								</p>
								<p className="MsoNormal">
									<span>
										d)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Iran.
								</p>
								<p className="MsoNormal">
									<span>
										e)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									North Korea.
								</p>
								<p className="MsoNormal">
									<span>
										f)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Singapore.
								</p>
								<p className="MsoNormal">
									<span>
										g)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Albania.
								</p>
								<p className="MsoNormal">
									<span>
										h)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Barbados.
								</p>
								<p className="MsoNormal">
									<span>
										i)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Botswana.
								</p>
								<p className="MsoNormal">
									<span>
										j)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Cambodia.
								</p>
								<p className="MsoNormal">
									<span>
										k)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Jamaica.
								</p>
								<p className="MsoNormal">
									<span>
										l)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Haiti.
								</p>
								<p className="MsoNormal">
									<span>
										m)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Mauritius.
								</p>
								<p className="MsoNormal">
									<span>
										n)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Myanmar (Burma).
								</p>
								<p className="MsoNormal">
									<span>
										o)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Nicaragua.
								</p>
								<p className="MsoNormal">
									<span>
										p)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Pakistan.
								</p>
								<p className="MsoNormal">
									<span>
										q)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Panama.
								</p>
								<p className="MsoNormal">
									<span>
										r)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									South Sudan.
								</p>
								<p className="MsoNormal">
									<span>
										s)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Syria.
								</p>
								<p className="MsoNormal">
									<span>
										t)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Yemen.
								</p>
								<p className="MsoNormal">
									<span>
										u)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Zimbabwe.
								</p>
								<p className="MsoNormal">
									<span>
										v)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									and any other country which may prohibit the offering on online
									gambling to its residents or to any person within such country
								</p> */}
                                <p className="MsoNormal">
                                    <span>
                                        3.2.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You are of legal capacity and can enter into a binding legal
                                    agreement with us. You must not access the Website or utilize
                                    the Service if you are not of legal capacity.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.3.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You are a resident in a jurisdiction that allows gambling. You
                                    are not a resident of any country in which access to online
                                    gambling to its residents or to any person within such country
                                    is prohibited. It is your sole responsibility to ensure that
                                    your use of the service is legal.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.4.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You may not use a VPN, proxy or similar services or devices that
                                    mask or manipulate the identification of your real location.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.5.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You are the authorized user of the payment method you use.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.6.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You must make all payments to us in good faith and not attempt
                                    to reverse a payment made or take any action which will cause
                                    such payment to be reversed by a third party.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.7.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    When placing bets you may lose some or all of your money
                                    deposited to the Service in accordance with these Terms and you
                                    will be fully responsible for that loss.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.8.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    When placing bets you must not use any information obtained in
                                    breach of any legislation in force in the country in which you
                                    were when the bet was placed.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.9.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You are not acting on behalf of another party or for any
                                    commercial purposes, but solely on your own behalf as a private
                                    individual in a personal capacity.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.10.<span>&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    You must not either attempt to manipulate any market or element
                                    within the Service in bad faith nor in a manner that adversely
                                    affects the integrity of the Service or us.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.11.<span>&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    You must generally act in good faith in relation to us of the
                                    Service at all times and for all bets made using the Service.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        3.12.<span>&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    You, or, if applicable, your employees, employers, agents, or
                                    family members, are not registered as an Affiliate in our
                                    Affiliate program.
                                </p>
                                <h1>4. Registration</h1>
                                <p className="MsoNormal" align="left">
                                    You agree that at all times when using the Service:
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.1.<span> &nbsp;&nbsp;&nbsp;</span>We reserve the right to
                                    refuse to accept a registration application from any applicant
                                    at our sole discretion and without any obligation to communicate
                                    a specific reason.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.2.<span> &nbsp;&nbsp;&nbsp;</span>Before using the Service,
                                    you must personally complete the registration form and read and
                                    accept these Terms. In order to start betting on the Service or
                                    withdraw your winnings, we may require you to become a verified
                                    Customer which includes passing certain checks. You may be
                                    required to provide a valid proof of identification and any
                                    other document as it may be deemed necessary. This includes but
                                    is not limited to, a picture ID (copy of passport, driver's
                                    licence or national ID card) and a recent utility bill listing
                                    your name and address as proof of residence. We reserve the
                                    right to suspend wagering or restrict Account options on any
                                    Account until the required information is received. This
                                    procedure is done in accordance with the applicable gaming
                                    regulation and the anti-money laundering legal requirements.
                                    Additionally, you will need to fund your Service Account using
                                    the payment methods set out on the payment section of our
                                    Website.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.3.<span> &nbsp;&nbsp;&nbsp;</span>
                                    You have to provide accurate contact information, inclusive of a
                                    valid email address (“Registered Email Address”), and update
                                    such information in the future to keep it accurate. It is your
                                    responsibility to keep your contact details up to date on your
                                    Account. Failure to do so may result in you failing to receive
                                    important Account related notifications and information from us,
                                    including changes we make to these Terms. We identify and
                                    communicate with our Customersvia their Registered Email
                                    Address. It is the responsibility of the Customer to maintain an
                                    active and unique email account, to provide us with the correct
                                    email address and to advise Company of any changes in their
                                    email address. Each Customer is wholly responsible for
                                    maintaining the security of his Registered Email Address to
                                    prevent the use of his Registered Email Address by any third
                                    party. Company shall not be responsible for any damages or
                                    losses deemed or alleged to have resulted from communications
                                    between Company and the Customer using the Registered Email
                                    Address. Any Customer not having an email address reachable by
                                    Company will have his Account suspended until such an address is
                                    provided to us. We will immediately suspend your Account upon
                                    written notice to you to this effect if you intentionally
                                    provide false or inaccurate personal information. We may also
                                    take legal action against you for doing so in certain
                                    circumstances and/or contact the relevant authorities who may
                                    also take action against you.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.4.<span> &nbsp;&nbsp;&nbsp;</span>
                                    You are only allowed to register one Account with the Service.
                                    Accounts are subject to immediate closure if it is found that
                                    you have multiple Accounts registered with us. This includes the
                                    use of representatives, relatives, associates, affiliates,
                                    related parties, connected persons and/or third parties
                                    operating on your behalf.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.5.<span> &nbsp;&nbsp;&nbsp;</span>
                                    In order to ensure your financial worthiness and to confirm your
                                    identity, we may ask you to provide us with additional personal
                                    information, such as your name and surname, or use any
                                    third-party information providers we consider necessary. Should
                                    any additional personal information be obtained via third-party
                                    sources, we will inform you about the data obtained.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.6.<span> &nbsp;&nbsp;&nbsp;</span>
                                    You must keep your password for the Service confidential.
                                    Provided that the Account information requested has been
                                    correctly supplied, we are entitled to assume that bets,
                                    deposits and withdrawals have been made by you. We advise you to
                                    change your password on a regular basis and never disclose it to
                                    any third party. It is your responsibility to protect your
                                    password and any failure to do so shall be at your sole risk and
                                    expense. You may log out of the Service at the end of each
                                    session. If you believe any of your Account information is being
                                    misused by a third party, or your Account has been hacked into,
                                    or your password has been discovered by a third party, you must
                                    notify us immediately. You must notify us if your Registered
                                    Email Address has been hacked into, we may, however, require you
                                    to provide additional information/ documentation so that we can
                                    verify your identity. We will immediately suspend your Account
                                    once we are aware of such an incident. In the meantime you are
                                    responsible for all activity on your Account including third
                                    party access, regardless of whether or not their access was
                                    authorised by you.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.7.<span> &nbsp;&nbsp;&nbsp;</span>
                                    You must not at any time transmit any content or other
                                    information on the Service to another Customer or any other
                                    party by way of a screen capture (or other similar method), nor
                                    display any such information or content in a frame or in any
                                    other manner that is different from how it would appear if such
                                    Customer or third party had typed the URL for the Service into
                                    the browser line.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.8.<span> &nbsp;&nbsp;&nbsp;</span>
                                    When registering, you will receive possibility to use all
                                    currencies available on the website. Those will be the
                                    currencies of your deposits, withdrawals and bets placed and
                                    matched into the Service as set out in these Terms. Some payment
                                    methods do not process in all currencies. In such cases a
                                    processing currency will be displayed, along with a conversion
                                    calculator available on the page.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.9.<span> &nbsp;&nbsp;&nbsp;</span>
                                    We are under no obligation to open an Account for you and our
                                    website sign-up page is merely an invitation to treat. It is
                                    entirely within our sole discretion whether or not to proceed
                                    with the opening of an Account for you and, should we refuse to
                                    open an Account for you, we are under no obligation to provide
                                    you with a reason for the refusal.
                                </p>
                                <p className="MsoNormal" align="left">
                                    4.10.<span> &nbsp;&nbsp;&nbsp;</span>
                                    Upon receipt of your application, we may be in touch to request
                                    further information and/ or documentation from you in order for
                                    us to comply with our regulatory and legal obligations.
                                </p>

                                {/* <p className="MsoNormal"> 
									<span>
										i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									In order to protect the integrity of the Service and for other
									operational reasons we reserve the right to refuse to accept a
									registration application from any applicant at our sole
									discretion and without any obligation to communicate a specific
									reason.
								</p>
								<p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									Before using the Service, you must personally complete the
									registration form and read and accept these Terms. In order to
									start betting on the Service, we will require you to become a
									verified Customer which includes passing certain checks. You may
									be required to provide a valid proof of identification and any
									other document as it may be deemed necessary.
								</p>
								<p className="MsoNormal">
									<span>
										iii)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									This includes but is not limited to, a picture ID (copy of
									passport, driver's license or national ID card) and a recent
									utility bill listing your name and address as proof of residence
									to the minimum. We reserve the right to suspend wagering or
									restrict Account options on any Account until the required
									information is received. This procedure is a statutory
									requirement and is done in accordance with the applicable gaming
									regulation and the anti-money laundering legal requirements.
									Additionally, you will need to fund your Player’s Account using
									the payment methods set out on the payment section of our
									Website.
								</p>
								<p className="MsoNormal">
									<span>
										2.2.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									You must provide complete and accurate information about
									yourself, inclusive of a valid name, surname, address, and email
									address, and update such information in the future to keep it
									complete and accurate.
								</p>
								<p className="MsoNormal">
									<span>
										2.3.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									It is your responsibility to keep your contact details up to
									date on your Account. Failure to do so may result in you failing
									to receive important Account related notifications and
									information from us, including changes we make to these Terms.
								</p>
								<p className="MsoNormal">
									<span>
										2.4.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									We identify and communicate with our Customers via their
									Registered Email Address. It is the responsibility of the
									Customer to maintain an active and unique email account, to
									provide us with the correct email address and to advise
									Kuberbets.co of any changes in your email address.
								</p>
								<p className="MsoNormal">
									<span>
										2.5.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									Each Customer is wholly responsible for maintaining the security
									of his Registered Email Address to prevent the use of his
									Registered Email Address by any third party.
								</p>
								<p className="MsoNormal">
									<span>
										2.6.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									Kuberbets.co shall not be responsible for any damages or losses
									deemed or alleged to have resulted from communications between
									Kuberbets.co and the Customer using the Registered Email Address.
								</p>
								<p className="MsoNormal">
									<span>
										2.7.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									Any Customer not having an email address reachable by
									Kuberbets.co will have his Account suspended until such an
									address is provided to us.
								</p>
								<p className="MsoNormal">
									<span>
										2.8.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									We will immediately suspend your Account upon written notice to
									you to this effect if you intentionally provide false or
									inaccurate personal information. We may also take legal action
									against you for doing so in certain circumstances and/or contact
									the relevant authorities who may also take action against you.
								</p>
								<p className="MsoNormal">
									<span>
										2.9.<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									You are only allowed to register one Account with the Service.
									Accounts are subject to immediate closure if it is found that
									you have multiple Accounts registered with us.
								</p>
								<p className="MsoNormal">
									<span>
										2.10.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									This includes the use of representatives, relatives, associates,
									affiliates, related parties, connected persons and/ or third
									parties operating on your behalf.
								</p>
								<p className="MsoNormal">
									<span>
										2.11.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									In order to ensure your financial worthiness and to confirm your
									identity, we may use any third-party information providers we
									consider necessary.
								</p>
								<p className="MsoNormal">
									<span>
										2.12.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									You must keep your password for the Service confidential.
								</p>
								<p className="MsoNormal">
									<span>
										2.13.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Provided that the Account information requested has been
									correctly supplied, we are entitled to assume that bets,
									deposits, and withdrawals have been made by you.
								</p>
								<p className="MsoNormal">
									<span>
										2.14.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									We advise you to change your password on a regular basis and
									never disclose it to any third party.
								</p>
								<p className="MsoNormal">
									<span>
										2.15.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Passwords must contain at least one letter, one number and one
									special character and must be at least eight characters long. It
									is your responsibility to protect your password and any failure
									to do so shall be at your sole risk and expense.
								</p>
								<p className="MsoNormal">
									<span>
										2.16.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									You must log out of the Service at the end of each session.
								</p>
								<p className="MsoNormal">
									<span>
										2.17.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									If you believe any of your Account information is being misused
									by a third party, or your Account has been hacked into, or your
									password has been discovered by a third party, you must notify
									us immediately by email using your Registered Email Address to
									support@Kuberbets.co.
								</p>
								<p className="MsoNormal">
									<span>
										2.18.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									You must notify us if your Registered Email Address has been
									hacked into, we may, however, require you to provide additional
									information/documentation so that we can verify your identity.
									We will immediately suspend your Account once we are aware of
									such an incident. In the meantime, you are responsible for all
									activity on your Account including third party access,
									regardless of whether their access was authorized by you.
								</p>
								<p className="MsoNormal">
									<span>
										2.19.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									You must not at any time transmit any content or other
									information on the Service to another Customer or any other
									party by way of a screen capture (or other similar method), nor
									display any such information or content in a frame or in any
									other manner that is different from how it would appear if such
									Customer or third party had typed the URL for the Service into
									the browser line.
								</p>
								<p className="MsoNormal">
									<span>
										2.20.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									When registering, you will be required to choose the currency in
									which you will operate your Account. This will be the currency
									of your deposits, withdrawals and bets placed and matched into
									the Service as set out in these Terms. Some payment methods do
									not process in all currencies. In such cases a processing
									currency will be displayed, along with a conversion calculator
									available on the page.
								</p>
								<p className="MsoNormal">
									<span>
										2.21.<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									We are under no obligation to open an Account for you and our
									website sign-up page is merely an invitation to treat. It is
									entirely within our sole discretion whether to proceed with the
									opening of an Account for you and, should we refuse to open an
									Account for you, we are under no obligation to provide you with
									a reason for the refusal.
								</p>
								<p className="MsoNormal">
									Upon receipt of your application, we may be in touch to request
									further information and/ or documentation from you in order for
									us to comply with our regulatory and legal obligations.
								</p>*/}
                                <h1>5. Restricted Use</h1>
                                <p className="MsoNormal" align="left">
                                    5.1.<span> &nbsp;&nbsp;&nbsp;&nbsp;</span> You must not use the
                                    Service:
                                </p>
                                <p className="MsoNormal" align="left">
                                    <span>
                                        5.1.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    If you are under the age of 18 years (or below the age of
                                    majority as stipulated in the laws of the jurisdiction
                                    applicable to you) or if you are not legally able to enter into
                                    a binding legal agreement with us or you acting as an agent for,
                                    or otherwise on behalf, of a person under 18 years(or below the
                                    age of majority as stipulated in the laws of the jurisdiction
                                    applicable to you);
                                </p>
                                <p className="MsoNormal" align="left">
                                    <span>
                                        5.1.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    If you reside in a country in which access to online gambling to
                                    its residents or to any person within such country is
                                    prohibited.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    If you are a resident of one of the following countries, or
                                    accessing the Website from one of the following countries:
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        b.<span>&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    Country is not one of the following Restricted Countries:
                                    Austria
                                    <br /> France and it’s territories
                                    <br /> Germany Netherlands and it’s territories
                                    <br />
                                    Spain
                                    <br /> Union of Comoros
                                    <br /> United Kingdom
                                    <br /> USA and it’s territories
                                    <br />
                                    All FATF Blacklisted countries,
                                    <br /> any other jurisdictions deemed prohibited by Anjouan
                                    Offshore Financial Authority.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    To collect nicknames, e-mail addresses and/or other information
                                    of other Customers by any means (for example, by sending spam,
                                    other types of unsolicited emails or the unauthorised framing
                                    of, or linking to, the Service);
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    to disrupt or unduly affect or influence the activities of other
                                    Customers or the operation of the Service generally;
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    to promote unsolicited commercial advertisements, affiliate
                                    links, and other forms of solicitation which may be removed from
                                    the Service without notice;
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    in any way which, in our reasonable opinion, could be considered
                                    as an attempt to: (i) cheat the Service or another Customer
                                    using the Service; or (ii) collude with any other Customer using
                                    the Service in order to obtain a dishonest advantage;
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.8.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    to scrape our odds or violate any of our Intellectual Property
                                    Rights; or
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.1.9.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    for any unlawful activity whatsoever.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You cannot sell or transfer your account to third parties, nor
                                    can you acquire a player account from a third party.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    You may not, in any manner, transfer funds between player
                                    accounts.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    We may immediately terminate your Account upon written notice to
                                    you if you use the Service for unauthorised purposes. We may
                                    also take legal action against you for doing so in certain
                                    circumstances.
                                </p>
                                <p className="MsoNormal">
                                    <span>
                                        5.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    Employees of Company, its licensees, distributors, wholesalers,
                                    subsidiaries, advertising, promotional or other agencies, media
                                    partners, contractors, retailers and members of the immediate
                                    families of each are NOT allowed to use the Service for real
                                    money without prior consent from the Company Director or CEO.
                                    Should such activity be discovered, the account(s) will be
                                    immediately terminated and all bonuses/winnings will be
                                    forfeited.
                                </p>

                                {/* <h1>4. Privacy</h1>
								<p className="MsoNormal">
									4.1. Any information provided to us by you will be protected and
									processed in strict accordance with these Terms and our Privacy
									Policy.
								</p>
								<p className="MsoNormal">
									4.2. We will not reveal the identity of any person who places
									bets using the Service unless the information is lawfully
									required by competent authorities such as Regulators, the Police
									(e.g. to investigate fraud, money laundering or sports integrity
									issues), or by Financial Entities such as banks or payment
									suppliers or as permitted from time to time pursuant to the
									Privacy Policy.
								</p>
								<p className="MsoNormal">
									4.3. Upon registration your information is stored in our
									database. This means that your personal information may be
									transferred outside the European Economic Area (EEA) to
									jurisdictions that may not provide the same level of protection
									and security as applied within the EU or EEA. By agreeing to
									these Terms, you agree to the transfer of your personal
									information for the purpose of the provision of the Service
									object of this agreement.
								</p> */}
                                <h1>6. Your Account</h1>
                                <p className="MsoNormal">
                                    6.1. Accounts could use several currencies, in this case all
                                    Account balances and transactions appear in the currency used
                                    for the transaction.
                                </p>
                                <p className="MsoNormal" align="left">
                                    6.2.<span> &nbsp;&nbsp;&nbsp;</span> We do not give credit for
                                    the use of the Service.
                                </p>
                                <p className="MsoNormal">
                                    6.3. We may close or suspend an Account if you are not or we
                                    reasonably believe that you are not complying with these Terms,
                                    or to ensure the integrity or fairness of the Service or if we
                                    have other reasonable grounds to do so. We may not always be
                                    able to give you prior notice. If we close or suspend your
                                    Account due to you not complying with these Terms, we may cancel
                                    and/or void any of your bets and withhold any money in your
                                    account (including the deposit).
                                </p>
                                <p className="MsoNormal">
                                    6.4. We reserve the right to close or suspend any Account
                                    without prior notice and return all funds. Contractual
                                    obligations already matured will however be honoured.
                                </p>
                                <p className="MsoNormal">
                                    6.5. We reserve the right to refuse, restrict, cancel or limit
                                    any wager at any time for whatever reason, including any bet
                                    perceived to be placed in a fraudulent manner in order to
                                    circumvent our betting limits and/ or our system regulations.
                                </p>
                                <p className="MsoNormal">
                                    6.6. If any amount is mistakenly credited to your Account it
                                    remains our property and when we become aware of any such
                                    mistake, we shall notify you and the amount will be withdrawn
                                    from your Account.
                                </p>
                                <p className="MsoNormal">
                                    6.7. If, for any reason, your Account goes overdrawn, you shall
                                    be in debt to us for the amount overdrawn.
                                </p>
                                <p className="MsoNormal" align="center">
                                    6.8.<span> &nbsp;&nbsp;&nbsp;</span> You must inform us as soon
                                    as you become aware of any errors with respect to your Account.
                                </p>
                                <p className="MsoNormal">
                                    6.9. Please remember that betting is purely for entertainment
                                    and pleasure and you should stop as soon as it stops being fun.
                                    Absolutely do not bet anything you can’t afford to lose. If you
                                    feel that you may have lost control of your gambling, we offer a
                                    self-exclusion option. Just send a message to our Customer
                                    Support Department using your Registered Email Address that you
                                    wish to SELF-EXCLUDE and this request will take effect within 24
                                    hours from the moment of its receipt. In this case your account
                                    will be disabled until your further notice, and you won’t be
                                    able to login to it.
                                </p>
                                <p className="MsoNormal">
                                    6.10. You cannot transfer, sell, or pledge Your Account to
                                    another person. This prohibition includes the transfer of any
                                    assets of value of any kind, including but not limited to
                                    ownership of accounts, winnings, deposits, bets, rights and/or
                                    claims in connection with these assets, legal, commercial or
                                    otherwise. The prohibition on said transfers also includes
                                    however is not limited to the encumbrance, pledging, assigning,
                                    usufruct, trading, brokering, hypothecation and/or gifting in
                                    cooperation with a fiduciary or any other third party, company,
                                    natural or legal individual, foundation and/or association in
                                    any way shape or form
                                </p>
                                <p className="MsoNormal">
                                    6.11. Should you wish to close your account with us, please send
                                    an email from your Registered Email Address to our Customer
                                    Support Department via the links on the Website.
                                </p>
                                {/* <p className="MsoNormal">
									5.12. Should you wish to close your account with us, please send
									an email from your Registered Email Address to
									support@Kuberbets.co.
								</p> */}
                                <h1>7. Deposit of Funds</h1>
                                <p className="MsoNormal">
                                    7.1. All deposits should be made from an account or payment
                                    system or credit card that is registered in your own name, and
                                    any deposits made in any other currency will be converted using
                                    the daily exchange rate obtained from oanda.com, or at our own
                                    bank's or our payment processor’s prevailing rate of exchange
                                    following which your Account will be deposited accordingly. Note
                                    that some payment systems may apply additional currency exchange
                                    fees which will be deducted from the sum of your deposit.
                                </p>
                                <p className="MsoNormal">
                                    7.2. Fees and charges may apply to customer deposits and
                                    withdrawals, which can be found on the Website. In most cases we
                                    absorb transaction fees for deposits to your Kuberbets.co
                                    Account. You are responsible for your own bank charges that you
                                    may incur due to depositing funds with us.
                                </p>
                                {/* <p className="MsoNormal">
									6.3 Kuberbets.co is not a financial institution and uses a
									third-party electronic payment processors to process credit and
									debit card deposits; they are not processed directly by us. If
									you deposit funds by either a credit card or a debit card, your
									Account will only be credited if we receive an approval and
									authorization code from the payment issuing institution. If your
									card’s issuer gives no such authorization, your Account will not
									be credited with those funds.
								</p> */}
                                <p className="MsoNormal">
                                    7.3. Company is not a financial institution and uses a third
                                    party electronic payment processors to process credit and debit
                                    card deposits; they are not processed directly by us. If you
                                    deposit funds by either a credit card or a debit card, your
                                    Account will only be credited if we receive an approval and
                                    authorisation code from the payment issuing institution. If your
                                    card issuer gives no such authorisation, your Account will not
                                    be credited with those funds.
                                </p>
                                <p className="MsoNormal" align="left">
                                    7.4.<span> &nbsp;&nbsp;&nbsp;&nbsp;</span>You agree to fully pay
                                    any and all payments and charges due to us or to payment
                                    providers in connection with your use of the Service. You
                                    further agree not to make any charge-backs or renounce or cancel
                                    or otherwise reverse any of your deposits, and in any such event
                                    you will refund and compensate us for such unpaid deposits
                                    including any expenses incurred by us in the process of
                                    collecting your deposit, and you agree that any winnings from
                                    wagers utilising those charged back funds will be forfeited. You
                                    acknowledge and agree that your player account is not a bank
                                    account and is therefore not guaranteed, insured or otherwise
                                    protected by any deposit or banking insurance system or by any
                                    other similar insurance system of any other jurisdiction,
                                    including but not limited to your local jurisdiction.
                                    Furthermore, the player account does not bear interest on any of
                                    the funds held in it.
                                </p>
                                <p className="MsoNormal" align="left">
                                    7.5.<span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    If you decide to accept any of our promotional or bonus offer by
                                    entering a bonus code during deposit, you agree to the Terms of
                                    Bonuses and terms of each specific bonus.
                                </p>
                                <p className="MsoNormal" align="left">
                                    7.6.<span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    Funds originating from criminal and/or illegal and/or
                                    unauthorized activities must not be deposited with us.
                                </p>
                                <p className="MsoNormal" align="left">
                                    7.7.<span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    If you deposit using your credit card, it is recommended that
                                    you retain a copy of Transaction Records and a copy of these
                                    Terms.
                                </p>
                                <p className="MsoNormal" align="left">
                                    7.8.<span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    Internet Gambling may be illegal in the jurisdiction in which
                                    you are located; if so, you are not authorized to use your
                                    payment card to deposit on this site. It is your responsibility
                                    to know the laws concerning online gambling in your country of
                                    domicile.
                                </p>
                                <h1>8. Withdrawal of Funds</h1>
                                <p className="MsoNormal">
                                    8.1. You may withdraw any unutilized and cleared funds held in
                                    your player account by submitting a withdrawal request in
                                    accordance with our withdrawal conditions. The minimum
                                    withdrawal amount per transaction is € 10 (or equivalent in
                                    other currency) with the exception of an account closure in
                                    which case you may withdraw the full balance.
                                </p>
                                <p className="MsoNormal" align="left">
                                    8.2<span> &nbsp;&nbsp;&nbsp; </span> There are no withdrawal
                                    commissions if you roll over (wager) the deposit at least 1
                                    time. Otherwise we are entitled to deduct a 8% fee with minimum
                                    sum of 4 euro (or equivalent in your account currency) in order
                                    to combat money laundering.
                                </p>
                                <p className="MsoNormal">
                                    8.3. We reserve the right to request photo ID, address
                                    confirmation or perform additional verification procedures
                                    (request your selfie, arrange a verification call etc.) for the
                                    purpose of identity verification prior to granting any
                                    withdrawals from your Account. We also reserve our rights to
                                    perform identity verification at any time during the lifetime of
                                    your relationship with us.
                                </p>
                                <p className="MsoNormal">
                                    8.4. All withdrawals must be made to the original debit, credit
                                    card, bank account, method of payment used to make the payment
                                    to your Account. We may, and always at our own discretion, allow
                                    you to withdraw to a payment method from which your original
                                    deposit did not originate. This will always be subject to
                                    additional security checks.
                                </p>
                                <p className="MsoNormal">
                                    8.5<span> &nbsp;&nbsp;&nbsp; </span>Should you wish to withdraw
                                    funds but your account is either inaccessible, dormant, locked
                                    or closed, please contact our Customer Service Department.
                                </p>
                                <p className="MsoNormal">
                                    8.6<span> &nbsp;&nbsp;&nbsp; </span>
                                    In cases when your balance is at least 10 times larger than the
                                    total sum of your deposits, you will be limited to € 5,000 (or
                                    currency equivalent) for withdrawal per month. In other cases
                                    the maximum withdrawal amount per month is € 10,000.
                                </p>
                                <p className="MsoNormal">
                                    8.7<span> &nbsp;&nbsp;&nbsp; </span>
                                    Please note that we cannot guarantee successful processing of
                                    withdrawals or refunds in the event if you breach the Restricted
                                    use policy stated in Clauses 3.3 and 4.
                                </p>
                                <h1>9. Payment Transactions and Processors</h1>
                                <p className="MsoNormal">
                                    9.1. You are fully responsible for paying all monies owed to us.
                                    You must make all payments to us in good faith and not attempt
                                    to reverse a payment made or take any action which will cause
                                    such payment to be reversed by a third party in order to avoid a
                                    liability legitimately incurred. You will reimburse us for any
                                    charge-backs, denial or reversal of payment you make and any
                                    loss suffered by us as a consequence thereof. We reserve the
                                    right to also impose an administration fee of €50, or currency
                                    equivalent per charge-back, denial or reversal of payment you
                                    make.
                                </p>
                                <p className="MsoNormal">
                                    9.2. We reserve the right to use third party electronic payment
                                    processors and or merchant banks to process payments made by you
                                    and you agree to be bound by their terms and conditions
                                    providing they are made aware to you and those terms do not
                                    conflict with these Terms.
                                </p>
                                <p className="MsoNormal">
                                    9.3. All transactions made on our site might be checked to
                                    prevent money laundering or terrorism financing activity.
                                    Suspicious transactions will be reported to the relevant
                                    authority.
                                </p>
                                <h1>10. Errors</h1>
                                <p className="MsoNormal">
                                    10.1. In the event of an error or malfunction of our system or
                                    processes, all bets are rendered void. You are under an
                                    obligation to inform us immediately as soon as you become aware
                                    of any error with the Service. In the event of communication or
                                    system errors or bugs or viruses occurring in connection with
                                    the Service and/or payments made to you as a result of a defect
                                    or error in the Service, we will not be liable to you or to any
                                    third party for any direct or indirect costs, expenses, losses
                                    or claims arising or resulting from such errors, and we reserve
                                    the right to void all games/bets in question and take any other
                                    action to correct such errors.
                                </p>
                                <p className="MsoNormal">
                                    10.2. We make every effort to ensure that we do not make errors
                                    in posting bookmaker lines. However, if as a result of human
                                    error or system problems a bet is accepted at an odd that is:
                                    materially different from those available in the general market
                                    at the time the bet was made; or clearly incorrect given the
                                    chance of the event occurring at the time the bet was made then
                                    we reserve the right to cancel or void that wager, or to cancel
                                    or void a wager made after an event has started.
                                </p>
                                <p className="MsoNormal">
                                    10.3. We have the right to recover from you any amount overpaid
                                    and to adjust your Account to rectify any mistake. An example of
                                    such a mistake ight be where a price is incorrect or where we
                                    enter a result of an event incorrectly. If there are
                                    insufficient funds in your Account, we may demand that you pay
                                    us the relevant outstanding amount relating to any erroneous
                                    bets or wagers. Accordingly, we reserve the right to cancel,
                                    reduce or delete any pending plays, whether placed with funds
                                    resulting from the error or not.
                                </p>
                                {/* <p className="MsoNormal" align="left">
									10.3.<span> &nbsp;&nbsp;&nbsp; </span>We have the right to recover
									from you any amount overpaid and to adjust your Account to
									rectify any mistake. An example of such a mistake might be where
									a price is incorrect or where we enter a result of an event
									incorrectly. If there are insufficient funds in your Account, we
									may demand that you pay us the relevant outstanding amount
									relating to any erroneous bets or wagers. Accordingly, we
									reserve the right to cancel, reduce or delete any pending plays,
									whether placed with funds resulting from the error or not.
								</p> */}

                                <h1>
                                    11. &nbsp;&nbsp;&nbsp;Rules of Play, refunds and cancellations
                                </h1>
                                <p className="MsoNormal" align="left">
                                    11.1<span> &nbsp;&nbsp;&nbsp; </span>The winner of an event will
                                    be determined on the date of the event's settlement, and we will
                                    not recognize protested or overturned decisions for wagering
                                    purposes.
                                </p>
                                <p className="MsoNormal">
                                    11.2. All results posted shall be final after 72 hours and no
                                    queries will be entertained after that period of time. Within 72
                                    hours after results are posted, we will only reset/correct the
                                    results due to human error, system error or mistakes made by the
                                    referring results source.
                                </p>
                                <p className="MsoNormal">
                                    11.3. If a match result is overturned for any reason by the
                                    governing body of the match within the payout period then all
                                    money will be refunded.
                                </p>
                                <p className="MsoNormal">
                                    11.4. If a draw occurs in a game where a draw option is offered
                                    all stakes on a team win or lose will be lost. If a draw option
                                    is not offered then everyone receives a refund in the outcome of
                                    a draw on the match. And if a draw option has not been made
                                    available, then extra time will count, if played.
                                </p>
                                <p className="MsoNormal">
                                    11.5. If a result cannot be validated by us, for instance if the
                                    feed broadcasting the event is interrupted (and cannot be
                                    verified by another source) then at our election, the wagers on
                                    that event will be deemed invalid and wagers refunded.
                                </p>
                                <p className="MsoNormal">
                                    11.6. Minimum and maximum wager amounts on all events will be
                                    determined by us and are subject to change without prior written
                                    notice. We also reserve the right to adjust limits on individual
                                    Accounts as well.
                                </p>
                                <p className="MsoNormal">
                                    11.7. Customers are solely responsible for their own Account
                                    transactions. Once a transaction is complete, it cannot be
                                    changed. We do not take responsibility for missing or duplicate
                                    wagers made by the Customer and will not entertain discrepancy
                                    requests because a play is missing or duplicated. Customers may
                                    review their transactions in the My Account section of the site
                                    after each session to ensure all requested wagers were accepted.
                                </p>
                                <p className="MsoNormal">
                                    11.8. A matchup will have action as long as the two teams are
                                    correct, and regardless of the League header in which it is
                                    placed on our Website.
                                </p>
                                <p className="MsoNormal">
                                    11.9. The start dates and times displayed on the Website for
                                    eSport matches are an indication only and are not guaranteed to
                                    be correct. If a match is suspended or postponed, and not
                                    resumed within 72 hours from the actual scheduled start time,the
                                    match will have no action and wagers will be refunded. The
                                    exception being any wager on whether a team/player advances in a
                                    tournament, or wins the tournament, will have action regardless
                                    of a suspended or postponed match.
                                </p>
                                <p className="MsoNormal">
                                    11.10. If an event is posted by us with an incorrect date, all
                                    wagers have action based on the date announced by the governing
                                    body.
                                </p>
                                <p className="MsoNormal">
                                    11.11. If a team is using stand-ins, the result is still valid
                                    as it was the team's choice to use the stand-ins.
                                </p>
                                <p className="MsoNormal">
                                    11.12. Company reserves the right to remove events, markets and
                                    any other products from the Website.
                                </p>
                                <p className="MsoNormal">
                                    11.13. In-depth explanation of our sports betting rules is on
                                    the separate page: SPORTS BETTING RULES
                                </p>

                                <h1>12. &nbsp;&nbsp;&nbsp;Communications and Notices</h1>
                                <p className="MsoNormal">
                                    12.1.All communications and notices to be given under these
                                    Terms by you to us shall be sent using a Customer Support form
                                    on the Website.
                                </p>
                                <p className="MsoNormal">
                                    12.2. All communications and notices to be given under these
                                    Terms by us to you shall, unless otherwise specified in these
                                    Terms, be either posted on the Website and/or sent to the
                                    Registered Email Address we hold on our system for the relevant
                                    Customer. The method of such communication shall be in our sole
                                    and exclusive discretion.
                                </p>
                                <p className="MsoNormal">
                                    12.3. All communications and notices to be given under these
                                    Terms by either you or us shall be in writing in the English
                                    language and must be given to and from the Registered Email
                                    Address in your Account.
                                </p>
                                <p className="MsoNormal">
                                    12.4. From time to time, we may contact you by email for the
                                    purpose of offering you information about betting, unique
                                    promotional offerings, and other information from Kuberbets.co.
                                    You agree to receive such emails when you agree to these Terms
                                    when registering at the Website. You can choose to opt out of
                                    receiving such promotional offerings from us at any time by
                                    submitting a request to the Customer Support.
                                </p>
                                <h1>13. &nbsp;&nbsp;&nbsp;Matters Beyond Our Control</h1>
                                <p className="MsoNormal">
                                    We cannot be held liable for any failure or delay in providing
                                    the Service due to an event of Force Majeure which could
                                    reasonably be considered to be outside our control despite our
                                    execution of reasonable preventative measures such as: an act of
                                    God; trade or labour dispute; power cut; act, failure or
                                    omission of any government or authority; obstruction or failure
                                    of telecommunication services; or any other delay or failure
                                    caused by a third party, and we will not be liable for any
                                    resulting loss or damage that you may suffer. In such an event,
                                    we reserve the right to cancel or suspend the Service without
                                    incurring any liability.
                                </p>
                                <h1>14. &nbsp;&nbsp;&nbsp;&nbsp;Liability</h1>
                                <p className="MsoNormal">
                                    14.1. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT
                                    COMPENSATE YOU FOR ANY REASONABLY FORESEEABLE LOSS OR DAMAGE
                                    (EITHER DIRECT OR INDIRECT) YOU MAY SUFFER IF WE FAIL TO CARRY
                                    OUT OUR OBLIGATIONS UNDER THESE TERMS UNLESS WE BREACH ANY
                                    DUTIES IMPOSED ON US BY LAW (INCLUDING IF WE CAUSE DEATH OR
                                    PERSONAL INJURY BY OUR NEGLIGENCE) IN WHICH CASEWE SHALL NOT BE
                                    LIABLE TO YOU IF THAT FAILURE IS ATTRIBUTED TO: (I) YOUR OWN
                                    FAULT; (II) A THIRD PARTY UNCONNECTED WITH OUR PERFORMANCE OF
                                    THESE TERMS (FOR INSTANCE PROBLEMS DUE TO COMMUNICATIONS NETWORK
                                    PERFORMANCE, CONGESTION, AND CONNECTIVITY OR THE PERFORMANCE OF
                                    YOUR COMPUTER EQUIPMENT); OR (III) ANY OTHER EVENTS WHICH
                                    NEITHER WE NOR OUR SUPPLIERS COULD HAVE FORESEEN OR FORESTALLED
                                    EVEN IF WE OR THEY HAD TAKEN REASONABLE CARE. AS THIS SERVICE IS
                                    FOR CONSUMER USE ONLY WE WILL NOT BE LIABLE FOR ANY BUSINESS
                                    LOSSES OF ANY KIND.
                                </p>
                                {/* <p className="MsoNormal">
									<span>
										i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									Your own fault;
								</p>
								<p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									A third party unconnected with our performance of these terms
									(for instance problems due to communications network
									performance, congestion, and connectivity or the performance of
									your computer equipment); or
								</p>
								<p className="MsoNormal">
									<span>
										iii)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									Any other events which neither we nor our suppliers could have
									foreseen or forestalled even if we or they had taken reasonable
									care. As this service is for consumer use only, we will not be
									liable for any business losses ofany kind.
								</p> */}

                                <p className="MsoNormal">
                                    14.2. IN THE EVENT THAT WE ARE HELD LIABLE FOR ANY EVENT UNDER
                                    THESE TERMS, OUR TOTAL AGGREGATE LIABILITY TO YOU UNDER OR IN
                                    CONNECTION WITH THESE TERMS SHALL NOT EXCEED (A) THE VALUE OF
                                    THE BETS AND OR WAGERS YOU PLACED VIA YOUR ACCOUNT IN RESPECT OF
                                    THE RELEVANT BET/WAGER OR PRODUCT THAT GAVE RISE TO THE RELEVANT
                                    LIABILITY, OR (B) EUR €500 IN AGGREGATE, WHICHEVER IS LOWER.
                                </p>
                                <p className="MsoNormal">
                                    14.3. WE STRONGLY RECOMMEND THAT YOU (I) TAKE CARE TO VERIFY THE
                                    SUITABILITY AND COMPATIBILITY OF THE SERVICE WITH YOUR OWN
                                    COMPUTER EQUIPMENT PRIOR TO USE; AND (II) TAKE REASONABLE
                                    PRECAUTIONS TO PROTECT YOURSELF AGAINST HARMFUL PROGRAMS OR
                                    DEVICES INCLUDING THROUGH INSTALLATION OF ANTI-VIRUS SOFTWARE.
                                </p>

                                <h1>15. &nbsp;&nbsp;&nbsp;Gambling by Those Underage</h1>
                                <p className="MsoNormal">
                                    15.1. If we suspect that you are or receive notification that
                                    you are currently under 18 years or were under 18 years (or
                                    below the age of majority as stipulated in the laws of the
                                    jurisdiction applicable to you) when you placed any bets through
                                    the Service your Account will be suspended (locked) to prevent
                                    you placing any further bets or making any withdrawals from your
                                    Account. We will then investigate the matter, including whether
                                    you have been betting as an agent for, or otherwise on behalf,
                                    of a person under 18 years (or below the age of majority as
                                    stipulated in the laws of the jurisdiction applicable to you).
                                    If having found that you: (a) are currently; (b) were under 18
                                    years or below the majority age which applies to you at the
                                    relevant time; or (c) have been betting as an agent for or at
                                    the behest of a person under 18 years or below the majority age
                                    which applies:
                                    <ul>
                                        <li>
                                            all winnings currently or due to be credited to your
                                            Account will be retained;
                                        </li>
                                        <li>
                                            all winnings gained from betting through the Service
                                            whilst under age must be paid to us on demand (if you
                                            fail to comply with this provision we will seek to
                                            recover all costs associated with recovery of such
                                            sums); and/or
                                        </li>
                                        <li>
                                            any monies deposited in your Account which are not
                                            winnings will be returned to you OR retained until you
                                            turn 18 years old at our sole discretion. We reserve the
                                            right to deduct payment transaction fees from the amount
                                            to return, including transaction fees for deposits to
                                            your Kuberbets.co account which we covered.
                                        </li>
                                    </ul>
                                </p>
                                <p className="MsoNormal" align="left">
                                    15.2. This condition also applies to you if you are over the age
                                    of 18 years but you are placing your bets within a jurisdiction
                                    which specifies a higher age than 18 years for legal betting and
                                    you are below that legal minimum age in that jurisdiction.
                                </p>
                                <p className="MsoNormal">
                                    15.3 In the event we suspect you are in breach of the provisions
                                    of this Clause or are attempting to rely on them for a
                                    fraudulent purpose, we reserve the right to take any action
                                    necessary in order to investigate the matter, including
                                    informing the relevant law enforcement agencies.
                                </p>
                                {/* <p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									were under 18 years or below the majority age which applies to
									you at the relevant time; iii) or have been betting as an agent
									for or at the behest of a person under 18 years or below the
									majority age which applies:
								</p>
								<p className="MsoNormal">
									<span>
										a)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									all winnings currently or due to be credited to your Account
									will be retained;
								</p>
								<p className="MsoNormal">
									<span>
										b)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									all winnings gained from betting through the Service whilst
									underage must be paid to us on demand (if you fail to comply
									with this provision, we will seek to recover all costs
									associated with recovery of such sums); and/or
								</p>
								<p className="MsoNormal">
									<span>
										c)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									any monies deposited in your Kuberbets.co Account which are not
									winnings will be returned to you. This condition also applies to
									you if you are over the age of 18 years, but you are placing
									your bets within a jurisdiction which specifies a higher age
									than 18 years for legal betting and you are below that legal
									minimum age in that jurisdiction.
								</p>
								<p className="MsoNormal">
									14.3 In the event we suspect you are in breach of the provisions
									of this Clause 14 or are attempting to rely on them for a
									fraudulent purpose, we reserve the right to take any action
									necessary in order to investigate the matter, including
									informing the relevant law enforcement agencies.{" "}
									<span>&nbsp;</span>
								</p> */}
                                <h1>16. &nbsp;&nbsp;&nbsp;&nbsp;Fraud</h1>
                                <p className="MsoNormal">
                                    We will seek criminal and contractual sanctions against any
                                    Customer involved in fraud, dishonesty or criminal acts. We will
                                    withhold payment to any Customer where any of these are
                                    suspected. The Customer shall indemnify and shall be liable to
                                    pay to us on demand all costs, charges or losses sustained or
                                    incurred by us (including any direct, indirect or consequential
                                    losses, loss of profit, loss of business and loss of reputation)
                                    arising directly or indirectly from the Customer’s fraud,
                                    dishonesty or criminal act.
                                </p>
                                <h1>17. &nbsp;&nbsp;&nbsp;Intellectual Property</h1>
                                <p className="MsoNormal">
                                    17.1. Any unauthorised use of our name and logo may result in
                                    legal action being taken against you.
                                </p>
                                <p className="MsoNormal">
                                    17.2. As between us and you, we are the sole owners of the
                                    rights in and to the Service, our technology, software and
                                    business systems (the "Systems") as well as our odds. you must
                                    not use your personal profile for your own commercial gain (such
                                    as selling your status update to an advertiser); and when
                                    selecting a nickname for your Account we reserve the right to
                                    remove or reclaim it if we believe it appropriate.
                                </p>
                                <p className="MsoNormal">
                                    17.3. You may not use our URL, trademarks, trade names and/or
                                    trade dress, logos ("Marks") and/or our odds in connection with
                                    any product or service that is not ours, that in any manner is
                                    likely to cause confusion among Customers or in the public or
                                    that in any manner disparages us.
                                </p>
                                {/* <p className="MsoNormal">
									<span>
										i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									you must not use your personal profile for your own commercial
									gain (such as selling your status update to an advertiser); and
								</p>
								<p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									when selecting a nickname for your Account we reserve the right
									to remove or reclaim it if we believe it inappropriate.
								</p> */}
                                <p className="MsoNormal" align="left">
                                    17.4 Except as expressly provided in these Terms, we and our
                                    licensors do not grant you any express or implied rights,
                                    license, title or interest in or to the Systems or the Marks and
                                    all such rights, license, title and interest specifically
                                    retained by us and our licensors. You agree not to use any
                                    automatic or manual device to monitor or copy web pages or
                                    content within the Service. Any unauthorized use or reproduction
                                    may result in legal action being taken against you.
                                </p>
                                {/* <p className="MsoNormal" align="left">
									<span>
										16.5<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Except as expressly provided in these Terms, we and our
									licensors do not grant you any express or implied rights,
									license, title or interest in or to the Systems or the Marks and
									all such rights, license, title and interest specifically
									retained by us and our licensors. You agree not to use any
									automatic or manual device to monitor or copy web pages or
									content within the Service. Any unauthorized use or reproduction
									may result in legal action being taken against you.
								</p> */}
                                <h1>18. &nbsp;&nbsp;&nbsp;&nbsp;Your Licence</h1>
                                <p className="MsoNormal">
                                    18.1. Subject to these Terms and your compliance with them, we
                                    grant to you a non-exclusive, limited, non transferable and non
                                    sub-licensable license to access and use the Service for your
                                    personal non-commercial purposes only. Our license to you
                                    terminates if our agreement with you under these Terms ends.
                                </p>
                                <p className="MsoNormal">
                                    18.2. Save in respect of your own content, you may not under any
                                    circumstances modify, publish, transmit, transfer, sell,
                                    reproduce, upload, post, distribute, perform, display, create
                                    derivative works from, or in any other manner exploit, the
                                    Service and/or any of the content thereon or the software
                                    contained therein, except as we expressly permit in these Terms
                                    or otherwise on the Website. No information or content on the
                                    Service or made available to you in connection with the Service
                                    may be modified or altered, merged with other data or published
                                    in any form including for example screen or database scraping
                                    and any other activity intended to collect, store, reorganise or
                                    manipulate such information or content.
                                </p>
                                <p className="MsoNormal">
                                    18.3. Any non-compliance by you with this Clause may also be a
                                    violation of our or third parties' intellectual property and
                                    other proprietary rights which may subject you to civil
                                    liability and/or criminal prosecution.
                                </p>
                                <h1>19. &nbsp;&nbsp;&nbsp;Your Conduct and Safety</h1>
                                <p className="MsoNormal">
                                    19.1. For your protection and protection of all our Customers,
                                    the posting of any content on the Service, as well as conduct in
                                    connection therewith and/or the Service, which is in any way
                                    unlawful, inappropriate or undesirable is strictly prohibited
                                    (“Prohibited Behaviour”).
                                </p>
                                <p className="MsoNormal">
                                    19.2. If you engage in Prohibited Behaviour, or we determine in
                                    our sole discretion that you are engaging in Prohibited
                                    Behaviour, your Account and/or your access to or use of the
                                    Service may be terminated immediately without notice to you.
                                    Legal action may be taken against you by another Customer, other
                                    third party, enforcement authorities and/or us with respect to
                                    you having engaged in Prohibited Behaviour.
                                </p>
                                <p className="MsoNormal" align="left">
                                    19.3.Prohibited Behaviour includes, but is not limited to,
                                    accessing or using the Service to: promote or share information
                                    that you know is false, misleading or unlawful; conduct any
                                    unlawful or illegal activity, such as, but not limited to, any
                                    activity that furthers or promotes any criminal activity or
                                    enterprise, violates another Customer's or any other third
                                    party’s privacy or other rights or that creates or spreads
                                    computer viruses; harm minors in any way; transmit or make
                                    available any content that is unlawful, harmful, threatening,
                                    abusive, tortuous, defamatory, vulgar, obscene, lewd, violent,
                                    hateful, or racially or ethnically or otherwise objectionable;
                                    transmit or make available any content that the user does not
                                    have a right to make available under any law or contractual or
                                    fiduciary relationship, including without limitation, any
                                    content that infringes a third party’s copyright, trademark or
                                    other intellectual property and proprietary rights; transmit or
                                    make available any content or material that contains any softwar
                                    e virus or other computer or programming code (including HTML)
                                    designed to interrupt, destroy or alter the functionality of the
                                    Service, its presentation or any other website, computer
                                    software or hardware; interfere with, disrupt or reverse
                                    engineer the Service in any manner, including, without
                                    limitation, intercepting, emulating or redirecting the
                                    communication protocols used by us, creating or using cheats,
                                    mods or hacks or any other software designed to modify the
                                    Service, or using any software that intercepts or collects
                                    information from or through the Service; retrieve or index any
                                    information from the Service using any robot, spider or other
                                    automated mechanism; participate in any activity or action that,
                                    in the sole and entire unfettered discretion of us results or
                                    may result in another Customer being defrauded or scammed;
                                    transmit or make available any unsolicited or unauthorised
                                    advertising or mass mailing such as, but not limited to, junk
                                    mail, instant messaging, "spim", "spam", chain letters, pyramid
                                    schemes or other forms of solicitations; create Accounts on the
                                    Website by automated means or under false or fraudulent
                                    pretences; impersonate another Customer or any other third
                                    party, or any other act or thing done that we reasonably
                                    consider to be contrary to our business principles. The above
                                    list of Prohibited Behaviour is not exhaustive and may be
                                    modified by us at any time or from time to time. We reserve the
                                    right to investigate and to take all such actions as we in our
                                    sole discretion deem appropriate or necessary under the
                                    circumstances, including without limitation deleting the
                                    Customer’s posting(s) from the Service and/or terminating their
                                    Account, and take any action against any Customer or third party
                                    who directly or indirectly in, orknowingly permits any third
                                    party to directly or indirectly engage in Prohibited Behaviour,
                                    with or without notice to such Customer or third party.
                                </p>
                                {/* <p className="MsoNormal">
									<span>
										i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									promote or share information that you know is false, misleading
									or unlawful.
								</p>
								<p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									conduct any unlawful or illegal activity, such as, but not
									limited to, any activity that furthers or promotes any criminal
									activity or enterprise, provides instructional information about
									making or buying weapons, violates another Customer's or any
									other third party’s privacy or other rights or that creates or
									spreads computer viruses.
								</p>
								<p className="MsoNormal">
									<span>
										iii)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									harm minors in any way.
								</p>
								<p className="MsoNormal">
									<span>
										iv)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									transmit or make available any content that is unlawful,
									harmful, threatening, abusive, tortuous, defamatory, vulgar,
									obscene, lewd, violent, hateful, or racially or ethnically or
									otherwiseobjectionable.
								</p>
								<p className="MsoNormal">
									<span>
										v)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									transmit or make available any content that the user does not
									have a right to make available under any law or contractual or
									fiduciary relationship, including without limitation, any
									content that infringes a third party’s copyright, trademark or
									other intellectual property and proprietary rights.
								</p>
								<p className="MsoNormal">
									<span>
										vi)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									transmit or make available any content or material that contains
									any software virus or other computer, or programming code
									(including HTML) designed to interrupt, destroy or alter the
									functionality of the Service, its presentation or any other
									website, computer software or hardware.
								</p>
								<p className="MsoNormal">
									<span>
										vii)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									interfere with, disrupt or reverse engineer the Service in any
									manner, including, without limitation, intercepting, emulating
									or redirecting the communication protocols used by us, creating
									or using cheats, mods or hacks or any other software designed to
									modify the Service, or using any software that intercepts or
									collects information from or through the Service.
								</p>
								<p className="MsoNormal">
									<span>
										viii)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									retrieve or index any information from the Service using any
									robot, spider or other automated mechanism.
								</p>
								<p className="MsoNormal">
									<span>
										ix)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									participate in any activity or action that, in the sole and
									entire unfettered discretion of us results or may result in
									another Customer being defrauded or scammed.
								</p>
								<p className="MsoNormal">
									<span>
										x)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									transmit or make available any unsolicited or unauthorized
									advertising or mass mailing such as, but not limited to, junk
									mail, instant messaging, "spim", "spam", chain letters, pyramid
									schemes or other forms of solicitations.
								</p>
								<p className="MsoNormal">
									<span>
										xi)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									create Player’s Accounts by automated means or under false or
									fraudulent pretenses.
								</p>
								<p className="MsoNormal">
									<span>
										xii)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									impersonate another Customer or any other third party, or xiii)
									<span> &nbsp;&nbsp;&nbsp; </span>any other act or thing done
									that we reasonably consider to be contrary to our business
									principles.
								</p>
								<p className="MsoNormal">
									xiv) The above list of Prohibited Behavior is not exhaustive and
									may be modified by us at any time or from time to time. If you
									become aware of the misuse of the Service by another Customer or
									any other person, please contact us through the "Contact Us"
									section of the Website. We reserve the right to investigate and
									to take all such actions as we in our sole discretion deems
									appropriate or necessary under the circumstances, including
									without limitation, deleting the Customer’s posting(s) from the
									Service and/or terminating their Account, and take any action
									against any Customer or third party who directly or indirectly
									in, or knowingly permits any third party to directly or
									indirectly engage in, Prohibited Behavior, with or without
									notice to such Customer or third party.
								</p> */}
                                <h1>20. &nbsp;&nbsp;&nbsp;Links to Other Websites</h1>
                                <p className="MsoNormal">
                                    The Service may contain links to third party websites that are
                                    not maintained by, or related to, us, and over which we have no
                                    control. Links to such websites are provided solely as a
                                    convenience to Customers, and are in no way investigated,
                                    monitored or checked for accuracy or completeness by us. Links
                                    to such websites do not imply any endorsement by us of, and/or
                                    any affiliation with, the linked websites or their content or
                                    their owner(s). We have no control over or responsibility for
                                    the availability nor their accuracy, completeness, accessibility
                                    and usefulness. Accordingly when accessing such websites we
                                    recommend that you should take the usual precautions when
                                    visiting a new website including reviewing their privacy policy
                                    and terms of use.
                                </p>
                                {/* <p className="MsoNormal">
									19.2 Links to such websites are provided solely as a convenience
									to Customers, and are in no way investigated, monitored, or
									checked for accuracy or completeness by us. Links to such
									websites do not imply any endorsement by us of, and/or any
									affiliation with, the linked websites or their content or their
									owner(s). We have no control over or responsibility for the
									availability nor their accuracy, completeness, accessibility,
									and usefulness. Accordingly, when accessing such websites, we
									recommend that you should take the usual precautions when
									visiting a new website including reviewing their privacy policy
									and terms of use.
								</p> */}
                                <h1>21. &nbsp;&nbsp;&nbsp;&nbsp;Complaints</h1>
                                <p className="MsoNormal">
                                    21.1. If you have any concerns or questions regarding these
                                    Terms you should contact our Customer Service Department via the
                                    links on the Website and use your Registered Email Address in
                                    all communication with us.
                                </p>
                                <p className="MsoNormal">
                                    21.2. NOTWITHSTANDING THE FOREGOING, WE TAKE NO LIABILITY
                                    WHATSOEVER TO YOU OR TO ANY THIRD PARTY WHEN RESPONDING TO ANY
                                    COMPLAINT THAT WE RECEIVED OR TOOK ACTION IN CONNECTION
                                    THEREWITH.
                                </p>
                                <p className="MsoNormal">
                                    21.3. If a Customer is not satisfied with how a bet has been
                                    settled then the Customer should provide details of their
                                    grievance to our Customer Service Department. We shall use our
                                    reasonable endeavours to respond to queries of this nature
                                    within a few days (and in any event we intend to respond to all
                                    such queries within 28 days of receipt).
                                </p>
                                <p className="MsoNormal">
                                    21.4. Disputes must be lodged within three (3) days from the
                                    date the wager in question has been decided. No claims will be
                                    honoured after this period. The Customer is solely responsible
                                    for their Account transactions.
                                </p>
                                <p className="MsoNormal">
                                    21.5. In the event of a dispute arising between you and us our
                                    Customer Service Department will attempt to reach an agreed
                                    solution. Should our Customer Service Department be unable to
                                    reach an agreed solution with you, the matter will be escalated
                                    to our management.
                                </p>
                                <p className="MsoNormal">
                                    21.6. Should all efforts to resolve a dispute to the Customer's
                                    satisfaction have failed, the Customer has the right to have the
                                    dispute settled via arbitration.
                                </p>
                                {/* <p className="MsoNormal">
									20.7 The Customer has the right to lodge a complaint with one of
									our licensing bodies should all efforts to resolve a dispute to
									the Customer's satisfaction have failed.
								</p>
								<p className="MsoNormal">
									20.8 Customers being provided with the service from Kuberbets.co
									should, contact Curaçao Internet Gaming Association and/or the
									Department of Judicial Affairs of Curaçao.
								</p> */}
                                <h1>22. &nbsp;&nbsp;&nbsp; Assignment</h1>
                                <p className="MsoNormal">
                                    Neither these Terms nor any of the rights or obligations
                                    hereunder may be assigned by you without the prior written
                                    consent of us, which consent will not be unreasonably withheld.
                                    We may, without your consent, assign all or any portion of our
                                    rights and obligations hereunder to any third party provided
                                    such third party is able to provide a service of substantially
                                    similar quality to the Service by posting written notice to this
                                    effect on the Service.
                                </p>
                                {/* <p className="MsoNormal">
									<span>
										i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
									</span>
									you must not provide any false personal information on the
									Service or create an Account for anyone other than yourself.
								</p>
								<p className="MsoNormal">
									<span>
										ii)<span>&nbsp;&nbsp;&nbsp;</span>
									</span>
									you must not use your personal profile for your own commercial
									gain (such as selling your status update to an advertiser); and
								</p>
								<p className="MsoNormal">
									<span>
										iii)<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									when selecting a nickname for your Account we reserve the right
									to remove or reclaim it if we believe it inappropriate.
								</p> */}
                                {/* <h1>22. &nbsp;&nbsp;&nbsp;&nbsp;Assignment</h1>
								<p className="MsoNormal">
									22.1&nbsp;&nbsp;&nbsp; Neither these Terms nor any of the rights
									or obligations hereunder may be assigned by you without the
									prior written consent of us, which consent will not be
									unreasonably withheld. We may, without your consent, assign all
									or any portion of our rights and obligations hereunder to any
									third party provided such third party is able to provide a
									service of substantially similar quality to the Service by
									posting written notice to this effect on the Service.
								</p> */}
                                <h1>23. &nbsp;&nbsp;&nbsp;&nbsp;Severability</h1>
                                <p className="MsoNormal">
                                    In the event that any provision of these Terms is deemed by any
                                    competent authority to be unenforceable or invalid, the relevant
                                    provision shall be modified to allow it to be enforced in line
                                    with the intention of the original text to the fullest extent
                                    permitted by applicable law. The validity and enforceability of
                                    the remaining provisions of these Terms shall not be affected.
                                </p>
                                <h1>24. &nbsp;&nbsp;&nbsp;Breach of These Terms</h1>
                                <p className="MsoNormal">
                                    Without limiting our other remedies, we may suspend or terminate
                                    your Account and refuse to continue to provide you with the
                                    Service, in either case without giving you prior notice, if, in
                                    our reasonable opinion, you breach any material term of these
                                    Terms. Notice of any such action taken will, however, be
                                    promptly provided to you.
                                </p>
                                {/* <p className="MsoNormal" align="left">
									<strong>
										<span>
											25.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
										</span>
									</strong>
									<strong>
										<span>General Provisions </span>
									</strong>
								</p>
								<p className="MsoNormal" align="left">
									25.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The laws of Curacao govern
									the Service operated by Three Aces Curaçao N.V.
								</p> */}
                                <h1>25. &nbsp;&nbsp;&nbsp;General Provisions</h1>
                                <p className="MsoNormal">
                                    25.1
                                    <span>
                                        {" "}
                                        <strong>Term of agreement</strong>
                                    </span>
                                    These Terms shall remain in full force and effect while you
                                    access or use the Service or are a Customer or visitor of the
                                    Website. These Terms will survive the termination of your
                                    Account for any reason.
                                </p>
                                <p className="MsoNormal">
                                    25.2
                                    <span>
                                        {" "}
                                        <strong>Gender</strong>
                                    </span>
                                    .Words importing the singular number shall include the plural
                                    and vice versa, words importing the masculine gender shall
                                    include the feminine and neuter genders and vice versa and words
                                    importing persons shall include individuals, partnerships,
                                    associations, trusts, unincorporated organisations and
                                    corporations.
                                </p>
                                <p className="MsoNormal">
                                    25.3
                                    <span>
                                        {" "}
                                        <strong>Waiver</strong>
                                    </span>
                                    . No waiver by us, whether by conduct or otherwise, of a breach
                                    or threatened breach by you of any term or condition of these
                                    Terms shall be effective against, or binding upon, us unless
                                    made in writing and duly signed by us, and, unless otherwise
                                    provided in the written waiver, shall be limited to the specific
                                    breach waived. The failure of us to enforce at any time any term
                                    or condition of these Terms shall not be construed to be a
                                    waiver of such provision or of the right of us to enforce such
                                    provision at any other time.
                                </p>
                                {/* <p className="MsoNormal">
									26.4
									<span>
										{" "}
										<strong>Headings</strong>
									</span>
									. The division of these Terms into paragraphs and sub-paragraphs
									and the insertion of headings are for convenience of reference
									only and shall not affect or be utilized in the construction or
									interpretation of these Terms agreement. The terms "these
									Terms", "hereof", "hereunder" and similar expressions refer to
									these Terms and not to any particular paragraph or sub-paragraph
									or other portion hereof and include any agreement supplemental
									hereto. Unless the subject matter or context is inconsistent
									therewith, references herein to paragraphs and sub-paragraphs
									are to paragraphs and sub-paragraphs of these Terms.
								</p> */}
                                <p className="MsoNormal">
                                    25.4
                                    <span>
                                        {" "}
                                        <strong>Acknowledgement</strong>
                                    </span>
                                    .By hereafter accessing or using the Service, you acknowledge
                                    having read, understood and agreed to each and every paragraph
                                    of these Terms. As a result, you hereby irrevocably waive any
                                    future argument, claim, demand or proceeding to the contrary of
                                    anything contained in these Terms.
                                </p>
                                <p className="MsoNormal">
                                    25.5
                                    <span>
                                        {" "}
                                        <strong>Language</strong>
                                    </span>
                                    . In the event of there being a discrepancy between the English
                                    language version of these rules and any other language version,
                                    the English language version will be deemed to be correct.
                                </p>
                                <p className="MsoNormal">
                                    25.6
                                    <span>
                                        {" "}
                                        <strong>Governing Law</strong>
                                    </span>
                                    . These Terms are governed exclusively by the law in force in
                                    the state of Anjouan in the Union of Comoros.
                                </p>
                                <p className="MsoNormal">
                                    25.7
                                    <span>
                                        {" "}
                                        <strong>Entire agreement</strong>
                                    </span>
                                    . These Terms constitute the entire agreement between you and us
                                    with respect to your access to and use of the Service, and
                                    supersedes all other prior agreements and communications,
                                    whether oral or written with respect to the subject matter
                                    hereof.
                                </p>
                                {/* <h1>27. &nbsp;&nbsp;&nbsp; Betting Rules</h1>
								<p className="MsoNormal">
									27.1 Kuberbets.co is operated by Three Aces Curaçao N.V., a
									limited liability company registered in Curaçao with company
									registration number 155000, with registered address at
									Heelsumstraat 51 Curaçao.
								</p>
								<p className="MsoNormal" align="left">
									27.2<span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Any dispute
									related to the sports betting product shall be emailed to:
									support@Kuberbets.co.
								</p>
								<h1>28. &nbsp;&nbsp;&nbsp; Casino Rules</h1>
								<p className="MsoNormal">
									28.1 Any dispute related to the casino product shall be emailed
									to: support@Kuberbets.co. Complete casino rules can be accessed
									from within the casino games.
								</p>
								<p className="MsoNormal" align="left">
									<strong>
										<span>
											29<span>&nbsp;&nbsp;&nbsp; </span>
										</span>
									</strong>
									<strong>
										<span>
											This operator has put policies in place and scripts on their
											platform that secure the following:{" "}
										</span>
									</strong>
								</p>
								<p className="MsoNormal">
									<span>
										29.1<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Upon registration, players identify themselves with name, date
									of birth, residential address, contact email and mobile-number
									(optional);
								</p>
								<p className="MsoNormal">
									<span>
										29.2<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									Upon registration, players “check box” the following questions:
								</p>
								<p className="MsoNormal">
									<span>
										29.2.1<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									I am 18 years or older
								</p>
								<p className="MsoNormal">
									<span>
										29.2.2<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									I am playing for myself and with my own money
								</p>
								<p className="MsoNormal">
									<span>
										29.2.3<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									This is my first/only registration Kuberbets.co
								</p>
								<p className="MsoNormal">
									<span>
										29.2.4<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									I have not self-excluded from any gambling website in the past
									12 months
								</p>
								<p className="MsoNormal">
									<span>
										29.2.5<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									I have read and accept the terms and conditions
								</p>
								<p className="MsoNormal">
									<span>
										29.2.6<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									I declare that all information provided by me to Kuberbets.co is
									correct and can be verified and I
								</p>
								<p className="MsoNormal">
									understand that providing any form of incorrect information will
									void all winnings of my plays on
								</p>
								<p className="MsoNormal">Kuberbets.co</p>
								<p className="MsoNormal">
									<span>
										29.2.7<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									I am aware and acknowledge that Kuberbets.co sets certain
									limitations to the maximum prizes that can be won per
									day/week/month and/or can be withdrawn per day/week/month
								</p>
								<p className="MsoNormal">
									<span>
										29.3<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									A digital copy/key is generated as proof of above acceptance
								</p>
								<p className="MsoNormal">
									<span>
										29.3.1<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									After initial registration, a confirmation email for
									registration needs to be sent to the registrant with a numbered
									and date stamped version of the T&amp;C attached to it;
								</p>
								<p className="MsoNormal">
									<span>
										29.3.2<span>&nbsp;&nbsp;&nbsp; </span>
									</span>
									the registrant needs to confirm receipt of the email and the
									T&amp;C;
								</p>
								<p>
									<span>
										no deposit can be made until this confirmation of receipt has
										been sent by the registrant T&amp;C need to be accessible from
										Home Page and available in download (PDF) format, including
										date and version number at the time of download
									</span>
								</p>  */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {Document === Legal_Information_Enums.Terms_and_Conditions_NetEnt && (
                <div className="mb-static-content">
                    <div className="mb-static-content__rightside">
                        <h1>Terms And Conditions NetEnt</h1>
                        <div className="mb-static-content__content">
                            <div className="content">
                                <ol>
                                    <li>
                                        <strong>
                                            <span className="markedContent">
                                                <span>Absolute</span> <span>Restriction</span>
                                            </span>
                                        </strong>
                                        <strong>
                                            <span className="markedContent">
                                                <span>NetEnt will not permit</span>{" "}
                                                <span>NetEnt</span> <span>Casino</span>{" "}
                                                <span>Games</span>{" "}
                                                <span>
                                                    to be supplied to any entity that operates in
                                                </span>{" "}
                                                <span>
                                                    any of the below jurisdictions (irrespective of
                                                    whether or not NetEnt Casino Games are
                                                </span>{" "}
                                                <span>being supplied</span>{" "}
                                                <span>by the entity in that jurisdiction</span>
                                                <span>) without the appropriate licenses.</span>
                                            </span>
                                        </strong>
                                        <span className="markedContent">
                                            <span>
                                                Belgium, Bulgaria, Colombia, Croatia, Czech
                                                Republic, Denmark, Estonia, France, Italy, Latvia,
                                            </span>{" "}
                                            <span>
                                                Lithuania, Mexico, Portugal, Romania, Spain, Sweden,
                                                Switzerland, United Kingdom, United
                                            </span>{" "}
                                            <span>S</span>
                                            <span>tates of America.</span>
                                        </span>
                                    </li>
                                    <li>
                                        <strong>
                                            <span className="markedContent">
                                                <span>Blacklisted</span> <span>Territories</span>
                                            </span>
                                        </strong>
                                        <strong>
                                            <span className="markedContent">
                                                <span>
                                                    All NetEnt Casino Games may not be offered in
                                                    the following territories:
                                                </span>
                                            </span>
                                        </strong>
                                        <span className="markedContent">
                                            <span>Afghanistan,</span> <span>Albania,</span>{" "}
                                            <span>Algeria,</span> <span>Angola,</span>{" "}
                                            <span>Australia,</span> <span>Bahamas,</span>{" "}
                                            <span>Botswana,</span> <span>Belgium,</span>{" "}
                                            <span>Bulgaria</span>
                                            <span>,</span>{" "}
                                            <span>
                                                Colombia, Croatia, Czech Republic, Denmark, Estonia,
                                            </span>{" "}
                                            <span>Ecuador,</span> <span>Ethiopia,</span>{" "}
                                            <span>France,</span> <span>Ghana,</span>{" "}
                                            <span>Guyana,</span> <span>Hong</span>{" "}
                                            <span>Kong,</span> <span>Italy,</span>{" "}
                                            <span>Iran,</span> <span>Iraq,</span>{" "}
                                            <span>Israel,</span> <span>Kuwait,</span>{" "}
                                            <span>Latvia,</span> <span>Lithuania,</span>{" "}
                                            <span>Mexico,</span> <span>Namibia,</span>{" "}
                                            <span>
                                                Nicaragua, North Korea, Pakistan, Panama,
                                                Philippines,
                                            </span>{" "}
                                            <span>Portugal,</span>{" "}
                                            <span>Romania, Singapore, Spain,</span>{" "}
                                            <span>Sweden, Switzerland, Sudan, Syria, Taiw</span>
                                            <span>
                                                an, Trinidad and Tobago, Tunisia, Uganda,
                                            </span>{" "}
                                            <span>United</span>{" "}
                                            <span>Kingdom, United States of America</span>
                                            <span>, Yemen,</span> <span>Zimbabwe.</span>
                                        </span>
                                    </li>
                                    <li>
                                        <strong>
                                            <span className="markedContent">
                                                <span>Blacklisted Branded Games</span>{" "}
                                                <span>Territorie</span>
                                                <span>s</span>
                                            </span>
                                        </strong>
                                        <strong>
                                            <span className="markedContent">
                                                <span>
                                                    The followed NetEnt Braded Games have some
                                                    further restrictions in addition to the
                                                </span>{" "}
                                                <span>Blacklisted</span>{" "}
                                                <span>Territories set out above:</span>
                                            </span>
                                        </strong>
                                        <ol>
                                            <li>
                                                <strong>
                                                    <span className="markedContent">
                                                        <span>In</span> <span>addition</span>{" "}
                                                        <span>to</span> <span>the</span>{" "}
                                                        <span>jurisdictions</span> <span>set</span>{" "}
                                                        <span>out</span> <span>in</span>{" "}
                                                        <span>paragraph</span>{" "}
                                                        <span>2, Planet</span> <span>of</span>{" "}
                                                        <span>the</span> <span>Apes</span>{" "}
                                                        <span>Video</span> <span>Slot</span>{" "}
                                                        <span>must </span>
                                                        <span>
                                                            not be offered in the following
                                                            territories:
                                                        </span>
                                                    </span>
                                                </strong>
                                                <span className="markedContent">
                                                    <span>
                                                        Azerbaijan, China, India, Malaysia, Qatar,
                                                        Russia, Thailand, Turkey, Ukraine.
                                                    </span>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>
                                                    <span className="markedContent">
                                                        <span>In</span>{" "}
                                                        <span>
                                                            addition to the jurisdictions set out in
                                                            paragraph 2, Vikings Video Slot must not
                                                            be{" "}
                                                        </span>
                                                        <span>offered in the following</span>{" "}
                                                        <span>jurisdictions:</span>
                                                    </span>
                                                </strong>
                                                <span className="markedContent">
                                                    <span>
                                                        Azerbaijan, Cambodia, Canada, China, France,
                                                        India, Indonesia, Laos, Malaysia, Myanmar,
                                                    </span>{" "}
                                                    <span>
                                                        Papua New Guinea, Qatar, Russia, South Kore
                                                    </span>
                                                    <span>
                                                        a, Thailand, Turkey, Ukraine, United States
                                                        of
                                                    </span>{" "}
                                                    <span>America.</span>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>
                                                    <span className="markedContent">
                                                        <span>
                                                            In addition to the jurisdictions set out
                                                            in paragraph 2, Narcos Video Slot must
                                                            not be{" "}
                                                        </span>
                                                        <span>
                                                            offered in the following territories:
                                                        </span>
                                                    </span>
                                                </strong>
                                                <span className="markedContent">
                                                    <span>Indonesia, South Korea</span>
                                                    <span>.</span>
                                                </span>
                                            </li>
                                            <li>
                                                <strong>
                                                    <span className="markedContent">
                                                        <span>
                                                            In addition to the jurisdictions set out
                                                            in paragr
                                                        </span>
                                                        <span>
                                                            aph 2, Street Fighter Video Slot
                                                        </span>{" "}
                                                        <span>must not </span>
                                                        <span>
                                                            be offered in the following
                                                        </span>{" "}
                                                        <span>territories</span>
                                                    </span>
                                                    :
                                                </strong>
                                                <span className="markedContent">
                                                    <span>
                                                        Anguilla, Antigua &amp; Barbuda, Argentina,
                                                        Aruba, Barbados, Bahamas, Belize, Bermuda,
                                                        Bolivia,
                                                    </span>{" "}
                                                    <span>
                                                        Bonaire, Brazil, British Virgin Islands,
                                                        Canada, Cayman Islands, China,
                                                    </span>{" "}
                                                    <span>Chile, Clipperton Island,</span>{" "}
                                                    <span>
                                                        Columbia, Costa Rica, Cuba, Curacao,
                                                        Dominica, Dominican Republic, El Salvador,
                                                        Greenland,
                                                    </span>{" "}
                                                    <span>Grenada, Guadeloupe,</span>{" "}
                                                    <span>Guatemala, Guyana, Haiti,</span>{" "}
                                                    <span>Honduras,</span>{" "}
                                                    <span>Jamaica, Japan, Martinique,</span>{" "}
                                                    <span>Mexico,</span> <span>Montserrat, </span>
                                                    <span>Navassa</span> <span>Island,</span>{" "}
                                                    <span>Paraguay,</span> <span>Peru,</span>{" "}
                                                    <span>Pu</span>
                                                    <span>erto</span> <span>Rico,</span>{" "}
                                                    <span>Saba,</span> <span>Saint</span>{" "}
                                                    <span>Barthelemy,</span> <span>Saint</span>{" "}
                                                    <span>Eustatius,</span> <span>Saint</span>{" "}
                                                    <span>Kitts</span> <span>and</span>{" "}
                                                    <span>Nevis,</span> <span>Saint</span>{" "}
                                                    <span>Lucia,</span> <span>Saint</span>{" "}
                                                    <span>Maarten,</span> <span>Saint</span>{" "}
                                                    <span>Martin,</span> <span>Saint</span>{" "}
                                                    <span>Pierre</span> <span>and</span>{" "}
                                                    <span>Miquelon,</span> <span>Saint</span>{" "}
                                                    <span>Vincent</span> <span>and</span>{" "}
                                                    <span>the </span>
                                                    <span>Grenadines,</span>{" "}
                                                    <span>South Korea,</span> <span>Suriname,</span>{" "}
                                                    <span>Turks</span> <span>and</span>{" "}
                                                    <span>Caicos</span> <span>Islands,</span>{" "}
                                                    <span>United</span> <span>States of</span>{" "}
                                                    <span>
                                                        America, Uruguay, US Virgin Islands,
                                                    </span>{" "}
                                                    <span>Venezuela</span>
                                                </span>
                                                .
                                            </li>
                                            <li>
                                                <strong>
                                                    <span className="markedContent">
                                                        <span>
                                                            In addition to the jurisdictions set out
                                                            in paragraph 2, Fashion TV Video Slot
                                                            must not be{" "}
                                                        </span>
                                                        <span>offered in the following</span>{" "}
                                                        <span>territories:</span>
                                                    </span>
                                                </strong>
                                                <span className="markedContent">
                                                    <span>Cuba, Jordan, Turkey, Saudi Arabia.</span>
                                                </span>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>
                                            <span className="markedContent">
                                                <span>Universal Monsters (Dracula,</span>{" "}
                                                <span>Creature from the Black Lagoon</span>
                                                <span>, Phantoms Curse</span> <span>and The </span>
                                                <span>
                                                    Invisible Man) may only be played in the
                                                    following territories:
                                                </span>
                                            </span>
                                        </strong>
                                        <span className="markedContent">
                                            <span>Andorra,</span> <span>Austria,</span>{" "}
                                            <span>Armenia,</span> <span>Azerbaijan,</span>{" "}
                                            <span>Belarus,</span> <span>Bosnia</span>{" "}
                                            <span>and</span> <span>Herzegovina,</span>{" "}
                                            <span>Cyprus,</span> <span>Finland,</span>{" "}
                                            <span>Georgia,</span> <span>Germany, Greece,</span>{" "}
                                            <span>Hungary,</span> <span>Iceland,</span>{" "}
                                            <span>Ireland,</span> <span>Liechtenstein,</span>{" "}
                                            <span>Luxembourg, Malta,</span> <span>Moldova,</span>{" "}
                                            <span>Monaco,&nbsp; Montenegro,</span>{" "}
                                            <span>Netherlands, North Macedonia,</span>{" "}
                                            <span>Norway,</span> <span>Poland,</span>{" "}
                                            <span>Russia, San Marino,</span> <span>Serbia,</span>{" "}
                                            <span>Slovakia</span>
                                            <span>,</span> <span>Slovenia</span>
                                            <span>,</span> <span>Turkey and Ukraine</span>
                                            <span>.</span>
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Legal;
