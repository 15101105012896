import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import OtpInput from "otp-input-react";
import { useLocation } from 'react-router-dom';
import { ReactComponent as Clock } from "../../Assets/Images/clock.svg"
import { User_APIs } from '../../API/User_APIs';
import { User_Context } from '../../Contexts/User';
import { encryptData } from '../../Utilities/EncryptData';
import { decryptData } from '../../Utilities/DecryptData';
import Loader from '../Loader/Loader';
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import "react-toastify/dist/ReactToastify.css";
import "./Register.css"

function RegisterOtp() {
    const [otp, setOtp] = useState("");
    const [timeLeft, setTimeLeft] = useState(60);
    const [timerActive, setTimerActive] = useState(true);
    const location = useLocation()
    const [loading, setLoading] = useState(false);
    const { User, Set_User } = useContext(User_Context);
    const cookies = new Cookies();
    const navigate = useNavigate()
    const customProps = location.state;

    useEffect(() => {
        let timer;
        if (timerActive && timeLeft > 0) {
            timer = setTimeout(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [timeLeft, timerActive]);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimerActive(false);
        }
    }, [timeLeft]);


    const handleResend = () => {
        setLoading(true)
        setTimeLeft(60);
        setTimerActive(true);
        let data = {
            city: customProps.city,
            subAdminId: customProps.subAdminId,
            deviceType: "web",
            mobile: customProps.mobile,
            name: customProps.name,
            password: customProps.password,
            state: customProps.state,
        };
        User_APIs.Check_User_Existence(data)
            .then((response) => {
                setLoading(false);
                toast.success("Otp Resent Successfully")
            })
            .catch((error) => {
                setLoading(false)
                toast.error(error.response.data.message)
            })
    }

    const handleVerifyOtp = () => {
        setLoading(true);
        if (!otp) {
            toast.info("Please enter otp")
            setLoading(false)
        } else {
            let data = {
                otp: parseInt(otp),
                mobile: customProps.mobile,
            };
            User_APIs.Register_User(data)
                .then((response) => {
                    setLoading(false)
                    response.data.data = decryptData(response.data.data);
                    response.data.data = response.data.data.payload;
                    Set_User(response.data);
                    cookies.set("userData", encryptData(response.data.data));
                    cookies.set("jwt-authorization", encryptData(response.data.token));
                    toast.success("Otp Verfied Successfully")
                    navigate("/")
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error(error.response.data.message)
                })
        }
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    return (
        <>
            {loading ? (<Loader />) : (<>
            <div className="register-otp-section">
                <div className='register-otp'>
                    <span>+91 {customProps.mobile} </span>
                </div>
                <div className='register-otp-span'>
                    <span>Please enter the 4-digit verification code sent to </span>
                </div>
                <div className="register-otpinput">
                    <OtpInput
                        onChange={setOtp}
                        value={otp}
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        autoFocus
                    ></OtpInput>
                </div>
                <div className='register-otp-clock'>
                    <Clock className='reg-clock' />
                    <span>Send code again in {formatTime(timeLeft)} </span>
                    {!timerActive && (
                        <button className='register-main-btn' onClick={handleResend}>Resend</button>
                    )}
                </div>
                <div className='reg-confirm'>
                    <button onClick={handleVerifyOtp} className='register-main-btn'>Confirm</button>
                </div>
                </div>
            </>)}

        </>
    )
}

export default RegisterOtp