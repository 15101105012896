import React, { useState } from 'react';
import { createContext } from 'react';

export const ExchangeContexts = createContext(null);

const ExchangeContext = ({children}) => { // Correct destructuring here

    const [activeMatchPlaceBetSection, setActiveMatchPlaceBetSection] = useState({});
    const [isUpcoming,setIsUpcoming]=useState(false);
    return (
        <ExchangeContexts.Provider 
            value={{ activeMatchPlaceBetSection, setActiveMatchPlaceBetSection,isUpcoming,setIsUpcoming }}>
            {children}
        </ExchangeContexts.Provider>
    );
};

export default ExchangeContext;
