import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { QTechUrlLobbyTypes } from "../../Configuration/Enums/QTechLobbyUrlValues";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";
import Thumbnail_Fallback_Image from "../../Assets/Images/Thumbnail_Fallback_Image.webp";
import { WACS_Payment_Modes } from "../../Configuration/Enums/WACS_Payment_Modes";
import { WACS_Endpoint } from "../../Configuration/Settings";
import { User_Context } from "../../Contexts/User";

// Styles
import "../GameLobbyBanners/GameLobbyBanners.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./GameIndianCard.css";
import { SessionHelper } from "../../Utilities/SessionHelper";

function GameIndianCard({ data, gameList }) {
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const navigate = useNavigate();
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const { User } = useContext(User_Context);

    return (
        <>
            {isTabOrMobile ? (
                <div className="swiper-container">
                    <Swiper
                        id="game-swiper"
                        slidesPerView={3}
                        slidesPerColumn={2}
                        className="myGameSwiper"
                        freeMode={true}
                        loop={false}
                        autoplay={{ delay: 2500 }}
                        slidesPerGroup={1}
                        navigation={true}
                        pagination={true}
                        modules={[FreeMode, Navigation, Pagination, Autoplay]}
                    >
                        {gameList.map((data, index) => {
                            if ((index + 1) % 2 === 0) return null;
                            return (
                                <div>
                                    <SwiperSlide key={index} style={{ width: "220px" }}>
                                        <div
                                            className="game-lobby-row"
                                            onClick={
                                                activeCasinoProvider === "QTECH"
                                                    ? () =>
                                                          navigate("/qtech-casino-game", {
                                                              state: {
                                                                  gameId: gameList[index].gameId,
                                                                  tableId: gameList[index].tableId,
                                                              },
                                                          })
                                                    : () =>
                                                          navigate("/casino-game", {
                                                              state: {
                                                                  Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${data.Game_Code}&type=${WACS_Payment_Modes.Charged}`,
                                                              },
                                                          })
                                            }
                                        >
                                            <img
                                                alt={gameList?.[index]?.Name}
                                                className="img-fluid indian-game-image"
                                                src={
                                                    activeCasinoProvider === "QTECH"
                                                        ? gameList[index].images[1]
                                                            ? gameList[index].images[1].url
                                                            : Thumbnail_Fallback_Image
                                                        : gameList[index].Thumbnail
                                                        ? gameList[index].Thumbnail
                                                        : Thumbnail_Fallback_Image
                                                }
                                                loading="lazy"
                                            />
                                        </div>
                                        {gameList?.[index + 1] ? (
                                            <div
                                                key={index + 1}
                                                className="game-lobby-row"
                                                onClick={
                                                    activeCasinoProvider === "QTECH"
                                                        ? () =>
                                                              navigate("/qtech-casino-game", {
                                                                  state: {
                                                                      gameId: gameList[index + 1]
                                                                          .gameId,
                                                                      tableId:
                                                                          gameList[index + 1]
                                                                              .tableId,
                                                                  },
                                                              })
                                                        : () =>
                                                              navigate("/casino-game", {
                                                                  state: {
                                                                      Game_URI: `${WACS_Endpoint}?token=${
                                                                          User.data._id
                                                                      }&pn=${
                                                                          process.env.REACT_APP_PN
                                                                      }&lang=en&game=${
                                                                          gameList[index + 1]
                                                                              .Game_Code
                                                                      }&type=${
                                                                          WACS_Payment_Modes.Charged
                                                                      }`,
                                                                  },
                                                              })
                                                }
                                            >
                                                <img
                                                    alt={gameList?.[index + 1]?.Name}
                                                    className="img-fluid indian-game-image"
                                                    src={
                                                        activeCasinoProvider === "QTECH"
                                                            ? gameList[index + 1].images[1]
                                                                ? gameList[index + 1].images[1].url
                                                                : Thumbnail_Fallback_Image
                                                            : gameList[index + 1].Thumbnail
                                                            ? gameList[index + 1].Thumbnail
                                                            : Thumbnail_Fallback_Image
                                                    }
                                                    loading="lazy"
                                                />
                                            </div>
                                        ) : null}
                                    </SwiperSlide>
                                </div>
                            );
                        })}
                    </Swiper>
                </div>
            ) : (
                <div className="card-swiper-container">
                    <Swiper
                        id="game-swiper"
                        slidesPerView={3}
                        slidesPerColumn={2}
                        className="myGameSwiper"
                        freeMode={true}
                        loop={false}
                        autoplay={{ delay: 2500 }}
                        slidesPerGroup={1}
                        navigation={true}
                        pagination={true}
                        modules={[FreeMode, Navigation, Pagination, Autoplay]}
                    >
                        {gameList.map((data, index) => {
                            if ((index + 1) % 2 === 0) return null;
                            return (
                                <div>
                                    <SwiperSlide key={index} style={{ width: "220px" }}>
                                        <div
                                            className="game-lobby-row"
                                            onClick={
                                                activeCasinoProvider === "QTECH"
                                                    ? () =>
                                                          navigate("/qtech-casino-game", {
                                                              state: {
                                                                  gameId: gameList[index].gameId,
                                                                  tableId: gameList[index].tableId,
                                                              },
                                                          })
                                                    : () =>
                                                          navigate("/casino-game", {
                                                              state: {
                                                                  Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${data.Game_Code}&type=${WACS_Payment_Modes.Charged}`,
                                                              },
                                                          })
                                            }
                                        >
                                            <img
                                                alt={gameList[index].Name}
                                                className="img-fluid indian-game-image"
                                                src={
                                                    activeCasinoProvider === "QTECH"
                                                        ? gameList[index].images[1]
                                                            ? gameList[index].images[1].url
                                                            : Thumbnail_Fallback_Image
                                                        : gameList[index].Thumbnail
                                                        ? gameList[index].Thumbnail
                                                        : Thumbnail_Fallback_Image
                                                }
                                                loading="lazy"
                                            />
                                        </div>
                                        {gameList?.[index + 1] ? (
                                            <div
                                                key={index + 1}
                                                className="game-lobby-row"
                                                onClick={
                                                    activeCasinoProvider === "QTECH"
                                                        ? () =>
                                                              navigate("/qtech-casino-game", {
                                                                  state: {
                                                                      gameId: gameList[index + 1]
                                                                          .gameId,
                                                                      tableId:
                                                                          gameList[index + 1]
                                                                              .tableId,
                                                                  },
                                                              })
                                                        : () =>
                                                              navigate("/casino-game", {
                                                                  state: {
                                                                      Game_URI: `${WACS_Endpoint}?token=${
                                                                          User.data._id
                                                                      }&pn=${
                                                                          process.env.REACT_APP_PN
                                                                      }&lang=en&game=${
                                                                          gameList[index + 1]
                                                                              .Game_Code
                                                                      }&type=${
                                                                          WACS_Payment_Modes.Charged
                                                                      }`,
                                                                  },
                                                              })
                                                }
                                            >
                                                <img
                                                    alt={gameList[index + 1].Name}
                                                    className="img-fluid indian-game-image"
                                                    src={
                                                        activeCasinoProvider === "QTECH"
                                                            ? gameList[index + 1].images[1]
                                                                ? gameList[index + 1].images[1].url
                                                                : Thumbnail_Fallback_Image
                                                            : gameList[index + 1].Thumbnail
                                                            ? gameList[index + 1].Thumbnail
                                                            : Thumbnail_Fallback_Image
                                                    }
                                                    loading="lazy"
                                                />
                                            </div>
                                        ) : null}
                                    </SwiperSlide>
                                </div>
                            );
                        })}
                        {/* {
                gameList.map((data, index) =>
                 <SwiperSlide key={index} style={{ width: "220px" }}>
                <div
                className="game-lobby-row"
                onClick={() =>
                  navigate("/qtech-casino-game", {
                    state: { gameId: data.gameId },
                  })
                }
              >
                <img
                  alt={data.Name}
                  className="img-fluid"
                  src={
                    data.images[1]
                      ? data.images[1].url
                      : Thumbnail_Fallback_Image
                  }
                  loading="lazy"
                />

              </div>
                </SwiperSlide>
                )
               } */}
                    </Swiper>
                </div>
            )}
        </>
    );
}

export default GameIndianCard;
