import React from "react";
// import Gif from "../images/GIF-1.gif";
import "../../css/loader.css";
import Gif from "../../Assets/Images/GIF-1.gif";

const Loader = () => {
    return (
        <div className="loading-container">
            <div>
                <img className="progress-circle" src={Gif} />
            </div>
        </div>
    );
};

export default Loader;
