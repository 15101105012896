import React, { useContext, useRef, useState } from 'react';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from "../../Contexts/Language";
import { Bet_History_Filter } from '../../Configuration/Enums/Bet_History_Filter';
import { Bet_History_Filter_Games } from '../../Configuration/Enums/Bet_History_Filter_Game';
import { Bet_History_Filter_Types } from '../../Configuration/Enums/Bet_History_Filter_Types';
import useOutsideAlerter from '../../Hooks/OutsideClickHandler';

// Components
import GeneralButton from '../Buttons/GeneralButton';
import DateField from '../Textfields/DateField';

// Styles
import './Modals.css'

function BetHistoryFilterModal({ closeFilterPopup, selectedTab, onFilterPress }) {
    const { Language } = useContext(Language_Context)

    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, closeFilterPopup);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedGame, setSelectedGame] = useState();
    const [betStatus, setBetStatus] = useState();

    const onPress = () => {
        let obj = {
            startDate: startDate,
            endDate: endDate,
            selectedGame: selectedGame,
            betStatus: betStatus
        }
        onFilterPress(obj)
    }

    return (
        <div className="popup3 d-flex align-items-center justify-content-center" >
            <div className="popup-content3" ref={wrapperRef}>
                <div className="bet-history-filter-modal-row ">

                    <DateField
                        id={"fromDate"}
                        name={"fromDate"}
                        placeholder={""}
                        title={Translation_Table.start[Language]}
                        onChange={(e) => setStartDate(e.target.value)}
                    />

                    <DateField
                        id={"fromDate"}
                        name={"fromDate"}
                        placeholder={""}
                        title={Translation_Table.end[Language]}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>

                <div className="bet-history-filter-modal-row ">
                    <div class="col-sm-6 col-md-4 col-5">
                        <label className="label-bet" htmlFor="">
                            {Translation_Table.game[Language]}
                        </label>
                        <select className="form-select inpt-bet" onChange={(e) => setSelectedGame(e.currentTarget.value)}>
                            <option value={Bet_History_Filter_Types.All}>
                                {Bet_History_Filter_Types.All}
                            </option>
                            {/* <option value={Bet_History_Filter_Games.Casino}>
                                {Bet_History_Filter_Games.Casino}
                            </option> */}
                            <option value={'SattaMatka'}>
                                {Bet_History_Filter_Games.Satta}
                            </option>
                            {/* <option value={Bet_History_Filter_Games.Exchange}>
                                {Bet_History_Filter_Games.Exchange}
                            </option> */}
                            <option value={Bet_History_Filter_Games.Jetfair}>
                                {Bet_History_Filter_Games.Jetfair}
                            </option>
                            <option value={Bet_History_Filter_Games.Wacs}>
                                {Bet_History_Filter_Games.Wacs}
                            </option>
                            <option value={Bet_History_Filter_Games.Falcon}>
                                {Bet_History_Filter_Games.Falcon}
                            </option>
                        </select>
                    </div>
                    {selectedTab === 1 &&
                        <div class="col-sm-6 col-md-4 col-5">
                            <label className="label-bet" htmlFor="">
                                {Translation_Table.betstatus[Language]}
                            </label>

                            <select className="form-select inpt-bet" onChange={(e) => setBetStatus(e.currentTarget.value)}>
                                <option value={Bet_History_Filter_Types.All}>
                                    {Bet_History_Filter_Types.All}
                                </option>
                                <option value={Bet_History_Filter.Win}>
                                    {Bet_History_Filter.Win}
                                </option>
                                <option value={Bet_History_Filter.Loss}>
                                    {Bet_History_Filter.Loss}
                                </option>
                            </select>
                        </div>

                    }
                </div>
                <div className='bet-history-filter-modal-pop-up-button-container'>
                    <GeneralButton
                        showText={true}
                        text={Translation_Table.apply[Language]}
                        onClick={onPress}
                    />
                    <GeneralButton
                        showText={true}
                        text={Translation_Table.close[Language]}
                        onClick={closeFilterPopup}
                    />
                </div>
            </div>
        </div>
    )
}

export default BetHistoryFilterModal;