import React, { useContext } from "react";
import { User_Context } from "../../Contexts/User";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

// Compponents
import GeneralButton from "../../components/Buttons/GeneralButton";

// styles
import "./BonusWalletTerms.css";

function BonusWalletTerms3() {
    const { User } = useContext(User_Context);
    const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;

    const location = useLocation();

    const refCode = new URLSearchParams(location.search).get("refCode");

    const copyShare = () => {
        if (isTabOrMobile) {
            window.navigator
                .share({
                    text: User.data?.referralCodeUser,
                    url: "https://Kubertbets.com/",
                })
                .then(() => toast.success(`Referral Code: ${User.data?.referralCodeUser} copied`))
                .catch((err) => toast.error(`Unable to copy`));
        } else {
            window.navigator.clipboard.writeText(
                `https://Kuberbets.com/ - ${User.data?.referralCodeUser}`
            );
            toast.success(`Referral Code: ${User.data?.referralCodeUser} copied`);
        }
    };
    return (
        <div className={isTabOrMobile ? "mx-3" : "mx-5 mb-5"}>
            <div className="bonus-wallet-terms-header">
                <p className="bonus-wallet-terms-header-text">
                    {isTabOrMobile ? `REFERRAL CODE ` : `BONUS WALLET REFERRAL CODE`}
                </p>
            </div>

            <div className="bonus-wallet-ref-body">
                <p className="bonus-wallet-terms-text">Your Referral Code</p>

                <p className="bonus-wallet-terms-text-bold">
                    {refCode ? refCode : User.data?.referralCodeUser}
                </p>
            </div>

            <div className="bonus-wallet-terms-button-container">
                <GeneralButton
                    showText={true}
                    text={"SHARE REFERRAL CODE"}
                    onClick={() => copyShare()}
                />
            </div>
        </div>
    );
}

export default BonusWalletTerms3;
