import React, { useContext, useEffect, useState } from 'react'
import "./OpenBetsComponent.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { User_Context } from '../../../../../Contexts/User';
import { Exchange_APIs } from '../../../../../API/Exchange_APIs';
import Loader from '../../../../Loader/Loader';
import { toast } from "react-toastify";
const OpenBetsComponent = () => {
    const { User, Set_User } = useContext(User_Context);
    const [marketBets, setMarketBets] = useState([]);
    const [fancyBets, setFancyBets] = useState([]);
    const [loading, setLoading] = useState(false);

    // fetch all open matched bets data
    const fetchOpenBets = () => {

        // call Open Bets API
        const API_RESPONSE = Exchange_APIs.GET_OPEN_BETS(User.token);
        // check api response
        setLoading(true);
        API_RESPONSE.then((response) => {
            
            setLoading(false);
            if (response.data.data) {
                setMarketBets(response.data.data.marketBets);
                setFancyBets(response.data.data.fancyBets);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("No any Open Bets")
            }

        }).catch((error) => {
           
            setLoading(false);
            toast.error(error.message)
        })
    }

    useEffect(() => {
        fetchOpenBets();
    }, [])



    return <>
        {loading && <Loader />}
        {marketBets.length && fancyBets.length && (
            <div className="no-open-bets-message">No any Open Bets</div>
        )}
        {marketBets.length ? <>
            <div className="open-bets-section">
                <div className="open-bets-header">
                    <div className="open-bets-heading">Market Bets</div>
                </div>

                <div className="data-th">
                    <div className="data-th-container">
                        <div className='data-label'>Runner</div>
                        <div className='data-label'>Rate</div>
                        <div className='data-label'>WinLoss</div>
                        <div className='data-label'>Complete</div>
                    </div>
                </div>
                <div className="open-bets-data-body">
                    {
                        marketBets?.map((bet) => {
                            return <><div className="data-row">
                                <div className="data-td">{bet.runner}</div>
                                <div className="data-td">{bet.rate}</div>
                                <div className="data-td">{bet.winLoss}</div>
                                <div className="data-td">{bet.isComplete ? "true" : "false"}</div>
                            </div>
                            </>
                        })
                    }
                </div>
            </div>
        </>
            : ""}

        {fancyBets.length ? <>
            <div className="open-bets-section">
                <div className="open-bets-header">
                    <div className="open-bets-heading">Fancy Bets</div>
                </div>

                <div className="data-th">
                    <div className="data-th-container">
                        <div className='data-label'>Runner</div>
                        <div className='data-label'>Rate</div>
                        <div className='data-label'>WinLoss</div>
                        <div className='data-label'>Complete</div>
                    </div>
                </div>
                <div className="open-bets-data-body">
                    {
                        fancyBets?.map((bet) => {
                            return <><div className="data-row">
                                <div className="data-td">{bet.runner}</div>
                                <div className="data-td">{bet.rate}</div>
                                <div className="data-td">{bet.winLoss}</div>
                                <div className="data-td">{bet.isComplete ? "true" : "false"}</div>
                            </div>
                            </>
                        })
                    }
                </div>
            </div>
        </> : ""}

    </>
}

export default OpenBetsComponent




{/* <div className="openbets-section">
<div className="openbets-section-container">
   
    <div className="openbets-matchedbets-column-labels">
        <div className="openbets-matchedbets-column-labels wrapper">
            <div className="openbets-matched-inning-label">Matched Bets</div>
            <div className="openbets-matched-odds-and-stake-label">
                <div className="openbets-matched-odds-label">odds</div>
                <div className="openbets-matched-stake-label">stake</div>
            </div>
        </div>
    </div>

    
    <div className="openbets-matched-bets-list">
      
        <div className="openbets-matched-bet-details-card">
            <div className="openbets-matched-bet-details-card-wrapper">
                <div className="openbets-matched-bet-inning-detail">1st Innings 6 Overs Line / 100</div>
                <div className="openbets-matched-odds-and-stake-details">
                    <div className="matched-odds-details">59</div>
                    <div className="matched-stake-details">500.00</div>
                </div>
            </div>
        </div>
       
         <div className="openbets-matched-bet-details-card" style={{backgroundColor:"#c3cbe5"}}>
            <div className="openbets-matched-bet-details-card-wrapper">
                <div className="openbets-matched-bet-inning-detail">1st Innings 6 Overs Line / 100</div>
                <div className="openbets-matched-odds-and-stake-details">
                    <div className="matched-odds-details">59</div>
                    <div className="matched-stake-details">500.00</div>
                </div>
            </div>
        </div>
    
    </div>
  
</div>
</div> */}