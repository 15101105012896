import React, { useState } from 'react'
import "./MarketComponent.css"
import WhoWillWinCard from "../../../ExchangeReusableCards/WhoWillWinCard/WhoWillWinCard"
import FancyBetsSection from "./FancyBets/FancyBetsSection"
import ExchangeDataCard from '../../../ExchangeReusableCards/ExchangeDataCard/ExchangeDataCard'
const MarketComponent = ({marketData,gameCode,gameId}) => {

  const [activeTeamInDetailsChart, setActiveTeamInDetailsChart] = useState("FIRST_TEAM")
  



  return <>

    <section className="sports-market-section">

      <div className="sport-bet-details">

        {
          marketData?.odds_market?.length  && marketData?.odds_market &&
          <div className="match-odds">
            <ExchangeDataCard marketType={"ODDS"} gameId={gameId} gameCode={gameCode}  rowData={marketData?.odds_market} exchangeDataCardTitle={"Match ODDS"} showCashout={false} showIcon={true} />
          </div>
        }

        {
          marketData?.bookmaker?.length  && marketData?.bookmaker &&
          <div className="bookmaker">
            <ExchangeDataCard marketType={"BOOKMAKER"}  gameId={gameId}  gameCode={gameCode}  rowData={marketData?.bookmaker} exchangeDataCardTitle={"Book Maker"} showCashout={false} showIcon={true} />
          </div>
        }

        {
          marketData?.TOSS?.length && marketData?.TOSS &&
          <div className="toss">
            <ExchangeDataCard marketType={"TOSS"}  gameId={gameId} gameCode={gameCode}  rowData={marketData?.TOSS} exchangeDataCardTitle={"Toss"} showCashout={false} showIcon={true} />
          </div>
        }

        {
          marketData["WHO WILL WIN"]?.length && marketData["WHO WILL WIN"] &&
          <div className="suspended-match-details">
            <WhoWillWinCard  gameId={gameId} gameCode={gameCode}  whoWilWin={marketData["WHO WILL WIN"]} showIcon={true} showCashout={false} />
          </div>
        }

       

        <div className="fancy-match-sectiono">
          <FancyBetsSection  gameId={gameId} gameCode={gameCode}  fancy={marketData?.fancy} />
        </div>


      </div>
    </section>

  </>
}

export default MarketComponent
