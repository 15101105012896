import React, { useState, useContext, useEffect } from 'react'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { User_Context } from '../../../Contexts/User';
import { useParams, useNavigate } from 'react-router-dom';
import { API_Endpoint } from '../../../Configuration/Settings';
import { encryptData } from '../../../Utilities/EncryptData';
import { decryptData } from '../../../Utilities/DecryptData';
import axios from 'axios';
import MasterAccountTable from './MasterAccountTable';
import "./account-statement.css"
import GeneralTextfield from '../../Textfields/GeneralTextfield';
import GeneralButton from '../../Buttons/GeneralButton';
import Logout_User from '../../../Utilities/Logout';

function MasterAccountStatement() {
    const [selectedOption, setSelectedOption] = useState("");
    const [marketId, setMarketId] = useState("");
    const [typeError, setTypeError] = useState(false);
    const [marketIdError, setMarketIdError] = useState(false);
    const [marketIdText, setMarketIdText] = useState("");
    const [loading, setLoading] = useState(false)
    const { User, Set_User } = useContext(User_Context)
    const { User_ID } = useParams();

    const [historyData, setHistoryData] = useState([]);
    const [pageNo, setPageNo] = useState(1)

    const [depositTotal, setDepositTotal] = useState(0);
    const [withdrawalTotal, setWithdrawalTotal] = useState(0);

    const [balanceTotal, setBalanceTotal] = useState(0);
    const [bonusBalanceTotal, setBonusBalanceTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [marketIdSearchKey, setMarketIdSearchKey] = useState('');
    const [bonusData, setBonusData] = useState()
    const Navigate = useNavigate();

    const handleDropdownChange = (event) => {
        setItemsPerPage(event.target.value);
        setTypeError(false);
    };

    const handleMarketId = (e) => {
        setMarketId(e.target.value)
    }

    const getWalletHistory = async (page) => {
        setLoading(true);
        let payload = {
            itemsPerPage: Number(itemsPerPage),
            pageNo: page,
            filter: {
                userId: User.data._id,
            }
        }
        if (marketId) {
            payload.filter["description.marketId"] = Number(marketId);
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/wallet-History`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData(payload) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data);
            const dataArray = response.data.data.payload
            console.log(dataArray);
            setHistoryData(dataArray.walletHistory);
            setDepositTotal(dataArray.totalDeposit);
            setWithdrawalTotal(dataArray.totalWithdrawal);
            setBalanceTotal(Math.floor(dataArray.balance));
            setBonusBalanceTotal(dataArray.bonusWalletBalance);
            setTotalPages(dataArray.totalPages);
            setLoading(false);
        }).catch((error) => {
            if (error && error.response && error.response.status === 401) {
				Logout_User(Set_User)
				Navigate("/")
			}
        });
    }
    const handleSubmitClick = () => getWalletHistory(pageNo);

    useEffect(() => {
        getWalletHistory(pageNo);
    }, [pageNo, itemsPerPage]);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className="row mt-2">
                        <div className='col-12 col-xl-2 col-sm-4 master-table'>
                            <label className='label-reason'>Items Per Page</label>
                            <FormControl
                                className="form-banner mt-2"
                                sx={{ m: 1, width: 200 }}
                                size="small"
                            >
                                <InputLabel
                                    className="label-banner"
                                    id="demo-select-small-label"
                                >
                                    Select
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={itemsPerPage}
                                    label="Select"
                                    fullWidth
                                    onChange={handleDropdownChange}
                                    error={typeError}
                                >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="25">25</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="75">75</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {/* <div className='col-12 col-xl-2 col-sm-4 master-table master-btn'>
                            <label className="label-id">Marked ID</label>
                            <GeneralTextfield
                                type="number"
                                onChange={(e) => handleMarketId(e)}
                                value={marketId}
                                error={marketIdError}
                                helperText={marketIdText}
                                fullWidth
                                id="outlined-basic text-id"
                                variant="outlined"
                            />

                        </div>
                        <div className='col-12 col-xl-2 col-sm-4 master-btn d-flex align-items-center'>
                            <GeneralButton
                                showText={true}
                                text={"Submit"}
                                disabled={!marketId?.length}
                                onClick={handleSubmitClick}
                            />
                        </div> */}
                    </div>

                    <div>
                        <MasterAccountTable
                            data={historyData}
                            currentPage={pageNo}
                            totalPages={totalPages}
                            setCurrentPage={(value) => setPageNo(value)}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                </div>
            </div>
        </>

    )
}

export default MasterAccountStatement