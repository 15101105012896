import axios from "axios";
import { API_Handler } from "./API_Handler";
import { encryptData } from "../Utilities/EncryptData";


export const Exchange_APIs = {
  GET_GAMES_LIST: async (Token) => {
    // console.log(Token)
    return await API_Handler.get("/exchange/getGameList", { headers: { Authorization: `Bearer ${Token}` } });
  },
  GET_MATCH_ODDS:async (gameCode,Token)=>{
    return await API_Handler.get(`/exchange/getGameOdds/${gameCode}`, { headers: { Authorization: `Bearer ${Token}` } });
  },
  
  PLACE_MATCH_BET:async (betData,Token)=>{
    return await API_Handler.post("/exchange/placeMatchBet",betData, { headers: { Authorization: `Bearer ${Token}` } })
  },
  PLACE_FANCY_BET:async (betData,Token)=>{
    return await API_Handler.post("/exchange/placeFancyBet",betData, { headers: { Authorization: `Bearer ${Token}` } })
  },

  GET_LIVE_SCORE:async (Token)=>{
    return await API_Handler.get("/exchange/liveScore", { headers: { Authorization: `Bearer ${Token}` } })
  },
  GET_OPEN_BETS: async (Token)=>{
    return await API_Handler.post("/exchange/openBets",{}, { headers: { Authorization: `Bearer ${Token}` } })
  }
};
