import React,{useContext} from "react";
import { ReactComponent as Tick } from "../../images/svg/check-svgrepo.svg";
import { ReactComponent as Pending } from "../../images/svg/clock-svgrepo-com.svg";
import { ReactComponent as Failed } from "../../images/svg/failed.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "../Transaction/TransactionCard.css";
import config from "../../Utilities/Data_Formatting";
import GeneralButton from '../Buttons/GeneralButton'
import { User_Context } from "../../Contexts/User";
import { toast } from "react-toastify";
import { Payment_APIs } from "../../API/Payment_APIs";
import '../../Style/Utility_Classes.css';
import { Button_Types } from "../../Configuration/Enums/Button_Types";

function TransactionCard({ transactions,onChange  }) {
  const { User } = useContext(User_Context);

        const handleCancelClick = async () => {
          try {
            let data = {transactionId: transactions.transactionId};
            const response = await Payment_APIs.CancelWithdrawal(data, User.token)
            if(response.data && response.data.success){
              toast.success(response.data.message);
            }
            else {
              toast.error(response.data.message)
            }
            onChange(true);
            
          } catch (error) {
            toast.error(error.message);
          }
        };
  

  return (
    <>
      <Card>
        <CardContent className="content-with">
          <div className="bethis-card">
            <div className="card-child">
              {transactions.status === "Approved" || transactions.status === "complete" ? (
                <Tick className="profile-bet" />
              ) : transactions.status === "pending" || transactions.status === "Pending" ? (
                <>
                  <Pending className="profile-bet" />
                </>
              ) :
                <Failed className="profile-bet" />
              }
            </div>
            <div className="loss-bet">
              <span className="text-bethis">{transactions.status}</span>
            </div>
          </div>
          <div className="amount-bethis">
            <span> {transactions.commissionAmount ? transactions.amount + transactions.commissionAmount : transactions.amount }</span>
          </div>

          <div className="date-bethis withreqq">
            <span className="text-bethis1">
              {config.dateTimeComplete(transactions?.createdOn)}
            </span>
          </div>
          <div className="withreq-card Display_Flex Justify_Content_Space_Between">
            <span className="text-bethis1 ">Ref No. {transactions._id}</span>
            { transactions.dp_id && (transactions.status === "Pending") && <GeneralButton onClick={handleCancelClick} showIcon={false} showSmall={true} showText={true} text="Cancel" Type={Button_Types.Destructive_Action}></GeneralButton> }
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default TransactionCard;
