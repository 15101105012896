import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

const LiveMatchStateComponent = ({ evId,isIframe}) => {
    let location = useLocation();
    const { eventid } = useParams();
    // Determine the eventId based on the priority
    const eventId = evId || eventid || location.state?.eventId;
   
    // define the iframe url
    let iframeURI = `https://supertv.lotusbook9mm.com/worldcup-score/#/score3/${eventId}`
    return <>
        <div className="iframe-wrapper" style={{marginTop:isIframe?"70px":""}}>
            <iframe src={iframeURI} frameBorder={0} width={100} height={250} />
        </div>

    </>
}

export default LiveMatchStateComponent
