import React, { useContext, useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Casino_APIs } from "../../API/Casino_APIs";
import { User_Context } from "../../Contexts/User";
import { decryptData } from "../../Utilities/DecryptData";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";

// Assets
import AndarBaharImage from "../../Assets/Images/home-page-game-banners/Andar-Bahar.png";
import BaccaratImage from "../../Assets/Images/home-page-game-banners/Baccarat.png";
import LuckyImage from "../../Assets/Images/home-page-game-banners/Lucky-7.png";
import DragonTigerImage from "../../Assets/Images/home-page-game-banners/Dragon-Tiger.png";
import PokerImage from "../../Assets/Images/home-page-game-banners/Poker.png";
import RouletteImage from "../../Assets/Images/home-page-game-banners/roulatte.png";
import BlackJackImage from "../../Assets/Images/home-page-game-banners/BlackJack.png";
import TeenPatti from "../../Assets/Images/home-page-game-banners/Teen-Patti.png";
import SattaMatka from "../../Assets/Images/home-page-game-banners/Satta-Matka.png";

// Styles
import "./GamePageBanners.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { SessionHelper } from "../../Utilities/SessionHelper";
import GamesHeaderMarker from "../GamesHeaderMarker/GamesHeaderMarker";

function GamePageBanners() {
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const navigate = useNavigate();

    const onClickImage = (gameName) => {
        navigate(`/selected-casino`, { state: { name: gameName, activeCasinoProvider } });
    };

    const bannerArray = [
        { image: AndarBaharImage, type: "Andar Bahar" },
        { image: BaccaratImage, type: "Baccarat" },
        { image: LuckyImage, type: "Lucky 7" },
        { image: DragonTigerImage, type: "Dragon Tiger" },
        // { image: SattaMatka, type: 'Satta Matka' }
    ];

    const bannerArray1 = [
        { image: PokerImage, type: "Poker" },
        { image: RouletteImage, type: "Roulette" },
        { image: BlackJackImage, type: "BlackJack" },
        { image: TeenPatti, type: "Teen Patti" },
    ];

    return (
        // <div className='game-page-banners-container'>
        //     {bannerArray.map((item, index) => {
        //         const { image, type } = item ? item : {}
        //         return (
        //             <LazyLoadImage
        //                 key={index}
        //                 onClick={() => onClickImage(type)}
        //                 className="game-page-image"
        //                 src={image}
        //                 alt="Kuberbets"
        //             />
        //         )
        //     })}
        // </div>

        <>
            {/* <div className="topgms">
                <div className='component-heading-block' />
                <span className="spngame">
                    TOP CATEGORIES
                </span>
            </div> */}

            <GamesHeaderMarker markerText={"Top Categories"} showBtn={false} />

            <div className="swiper-container">
                <Swiper
                    id="game-swiper"
                    slidesPerView={3}
                    slidesPerColumn={2}
                    className="myGameSwiper"
                    freeMode={true}
                    loop={false}
                    slidesPerGroup={2}
                    navigation={true}
                    autoplay={{ delay: 3500 }}
                    pagination={true}
                    modules={[FreeMode, Navigation, Pagination, Autoplay]}
                >
                    {bannerArray.flatMap((item, index) => (
                        <SwiperSlide key={index} style={{ width: "220px" }}>
                            <div className="game-lobby-row">
                                <LazyLoadImage
                                    key={index}
                                    onClick={() => onClickImage(item.type)}
                                    className="game-lobby-image"
                                    src={item.image}
                                    alt="Kuberbets"
                                />
                            </div>
                            {bannerArray1[index] && (
                                <div className="game-lobby-row">
                                    <LazyLoadImage
                                        key={index}
                                        onClick={() => onClickImage(bannerArray1[index].type)}
                                        className="game-lobby-image"
                                        src={bannerArray1[index]?.image}
                                        alt="Kuberbets"
                                    />
                                </div>
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
}

export default GamePageBanners;
