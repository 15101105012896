import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logout_User from "../../Utilities/Logout";
import { Casino_APIs } from "../../API/Casino_APIs";
import { decryptData } from "../../Utilities/DecryptData";

// Context
import { User_Context } from "../../Contexts/User";
import GameHighEarningCard from "../../components/GameHighEarningCard/GameHighEarningCard";

// Components
import Loader from "../../components/Loader/Loader";

// Styles
import "../IndianGames/IndianGames.css";
import GamesHeaderMarker from "../../components/GamesHeaderMarker/GamesHeaderMarker";

function HighEarningGames() {
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [gameList, setGameList] = useState([]);

    const getGames = async () => {
        setLoading(true);
        try {
            const response = await Casino_APIs.Qtech_Get_HighEarning_Games({}, User.token);
            const { data, success } = response.data ? response.data : {};
            if (success) {
                const finalDataList = decryptData(data);
                setGameList(finalDataList.payload);
            }
        } catch (error) {
            setLoading(false);
            setGameList([]);
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getGames();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className="high-earning-games-section">
                <GamesHeaderMarker markerText={"High Earning Games"} showBtn={false} />
                <div className="casino-container">
                    <div className="indian-casino-inner-container">
                        <div>
                            <div>
                                {!loading && gameList?.length ? (
                                    <GameHighEarningCard gameList={gameList} />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HighEarningGames;
