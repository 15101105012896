import React, { useContext } from 'react'
import { Card, CardContent, Box, CardActionArea, CardMedia } from '@mui/material'
import GeneralButton from '../Buttons/GeneralButton';
import { User_Context } from '../../Contexts/User';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useNavigate } from 'react-router-dom';
import { Bonus_Wallet_APIs } from '../../API/Bonus_Wallet_API';
import { toast } from "react-toastify";
import Logout_User from '../../Utilities/Logout';

// style
import './Bonus.css';
import { Game_Context } from '../../Contexts/Games';

const Bonus = () => {
  const navigate = useNavigate();
  const { User, Set_User } = useContext(User_Context);
  const { state: gameState} = useContext(Game_Context);
  const {
    bonusBanner
  } = gameState;

  const handleTransferToWallet = () => {
    if (User.data.balance <= 100 && User.data.bonusWalletBalance > 0 && User.data.exposer == 0) {
      bonusWalletTransferRequest()
    } else {
      toast.error('You already have enough balance!')
    }
  };

  const bonusWalletTransferRequest = async () => {
    try {
      let payload = {
        userId: User.data?._id
      }
      const response = await Bonus_Wallet_APIs.createTransferRequest(payload, User.token)
      const { data, success } = response.data ? response.data : {}
      if (success) {
        toast.success(`Transfer Requested Created!`);
        setTimeout(() => {
          window.location.reload();
        }, 2500);

      } else {
        toast.error(`User not found, Please contact Customer Support`);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        Logout_User(Set_User)
        navigate("/")
      }
      if (error && error.response && error.response.status === 401) {
        Logout_User(Set_User)
        navigate("/")
      }
    }
  }

  const cardData = [
    { type: 'terms1', image: require('../../Assets/Images/dummy1.png'), content: 'Activate', bonus: 'Get a 300 Bonus' },
    { type: 'terms2', image: require('../../Assets/Images/dummy2.png'), content: 'Activate', bonus: 'Get a 300 Bonus' },
    { type: 'ref', image: require('../../Assets/Images/dummy3.png'), content: 'Activate', bonus: 'Get a 300 Bonus' },

  ];

  const onNavigate = (type) => {
    if (type === "bonus-welcome-tnc-mobile") {
      navigate('/bonus-welcome-tnc')
    } else if (type === "bonus-refill-tnc-mobile") {
      navigate('/bonus-refill-tnc')
    } else {
      navigate('/bonus-referral-tnc')
    }
  }

  return (
    <Box className="Bonus-container">

      <div className='commom'>
        <Card className="Card-container text-align inner-bonus-container">
          <CardContent className='py-2'>
            <div className='Inner-content'>
              <div className='Inner-content' gutterBottom>
                <span className='Inner-content mt-2'>{User.data.bonusWalletBalance > 0 ? `BONUS WALLET BALANCE` : `TRANSFER YOUR BONUS WALLET AMOUNT TO YOUR MAIN WALLET`}</span>
              </div>
              {User.data.bonusWalletBalance > 0 && <span className='bold Inner-content '>{`${User.data.bonusWalletBalance} AVAILABLE`}</span>}
              <div className='commom mt-3'>
                <GeneralButton
                  showIcon={true}
                  showText={true}
                  text={User.data.bonusWalletBalance === 0 ? `BONUS WALLET BALANCE: 0` : (User.data.bonusWalletBalance > 0 && User.data.balance <= 100 && User.data.exposer === 0) ? `TRANSFER TO WALLET: ${User.data.bonusWalletBalance}` : `BONUS WALLET BALANCE: ${User.data.bonusWalletBalance}`}
                  onClick={() => User.data.bonusWalletBalance > 0 && User.data.balance <= 100 && User.data.exposer === 0 ? handleTransferToWallet() : null}
                  // disabled={!(User.data.bonusWalletBalance > 0 && User.data.balance <= 100 && User.data.exposer === 0)}
                />
              </div>
              <span className='bold'>T&C*</span>
              <p>{`You are eligible to transfer if your balance is less than 100 and Exposer should be 0, Your Balance : ${User.data.balance}`}</p>
            </div>

          </CardContent>

        </Card>
      </div>
      <Grid container >
        {bonusBanner?.map((data, index) => (
          // <div className=' top' key={index}>
          <Grid item key={index} xs={12} sm={12} md={6} lg={6} className='Gird-container top pb-1'>
            <Card className='Card-container inner-bonus-container Width-card' onClick={() => onNavigate(data.iframeUrlMob)}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={data?.imagePath}
                  alt="dummy"
                />
                <CardContent className='color-bg' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className='arrow-content py-1'>
                    <span className='Second-content'>{data?.decryption?.title ?? ''}</span>
                    <p className='Bonus-balance'>{data?.decryption?.subTitle ?? ''}</p>
                  </div>

                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{ width: "15px" }}>
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                    </svg>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Bonus
