import React from 'react'
import { ReactComponent as Playbtn } from "../../../../Assets/new-exchange/play-button.svg";
import { ReactComponent as Info } from "../../../../Assets/new-exchange/info.svg";
const MatchTeamsInfoCard = () => {
    return <>

        <div className='outer-bg'>
            <div className='outer-cont'>
                <Playbtn className='svg-btn' />
                <div className='play-spn'>
                    <span>
                        Adelaide Strikers WBBL v Sydney
                        Thunder WBBL
                    </span>
                    <br />
                    <span className='svg-info'>
                        <Info />
                    </span>
                    <span className='spn-play'>
                        Adelaide Strikers Women: 44-2 (7.2 Ovs) <br />
                        In play - Adelaide Strikers Women need 75 runs
                    </span>
                </div>
            </div>
        </div>

    </>
}

export default MatchTeamsInfoCard
